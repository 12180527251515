import { ChangeDetectorRef, Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { AppServiceService } from '../services/app-service.service';
import { clientService } from '../services/client.service';
import { MessageService } from '../services/message.service';
import { ChatsComponent } from '../components/chats/chats.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { AppCookieService } from '../utils/AppCookieService';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EncryptionService } from '../services/encryption.service';
import { LocalstorageManagerService } from '../services/localstorage-manager.service';
import "amazon-connect-chatjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { AudioService } from '../services/audio.service';
import { LocationService } from '../services/location.service';
import { interval, Subscription, switchMap, takeWhile, tap } from 'rxjs';
declare var $: any;
declare var DATA: any;
declare var connect: any;
interface Message {
  buttons?: any;
  cardData?: { imageUrl: any; title: any; isTrimmed: boolean; isNotEnglish: any; };
  text: string;
  isUser: boolean;
  user: string,
  info: string,
  isTrimmed: boolean;
  isNotEnglish: boolean;
  info_trimmed: any;
  timestamp: any;
  isGoogleMap: any;
  widgetType: any;
  clickedValue?: any;
}


@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})

export class ChatbotComponent {
  messages: Message[] = [];
  userInput: string = '';
  showChatBotScreen: boolean = false;
  showScreenValue: string = 'intro'
  isTyping: boolean = false;
  clientConfigurationId: any = DATA.clientConfigurationId;
  clientConfigurationData: any;
  botLanguages: any = [];
  BotDefaultLanguage: any;
  isGoogleTranslation: boolean = false;
  sessionAttributes: any = {};
  liveChatStartTime: any;
  liveChatEndTime: any;
  buttonFlag = false;
  calendarFlag = false;
  intentNames: any;
  firstLoad = true;
  styleSheet: CSSStyleSheet | undefined;
  theme = 'blue';
  isUserIdle = false;
  userIdleTimeout = 900000;
  userTimeoutFunction: any = null;
  isAccessControl: boolean = false;
  isIntentDetection: boolean = false;
  isMultilingual: boolean = false;
  isObjectDescriptionImage = false;
  isEmailAttachmentImage = false;
  preferredLanguage: string = '';
  isVoice: Boolean = false;
  isAttachment: Boolean = false;
  IsPrescriptionAttachment: Boolean = false;
  isEmoji: Boolean = false;
  isChatTranscript: Boolean = false;
  isTranslateToEnglish: Boolean = false;
  baseChatDelayInterval = 1000;
  chatDelayInterval = 1000;
  introMessage: boolean = false;
  isUserIdleTimeoutExpired = false;
  selectedRating: number = 0;
  hoveredRating: number = 0;
  currentPopover: any;
  isAudioResponse: boolean = false;
  canStoreDataToLocalStorage: boolean = false;
  showDataPrivacyPolicyModel: boolean = false;
  dataType: string = "initial";
  sessionType: string = "initial";
  @ViewChild(ChatsComponent)
  chats!: ChatsComponent;
  @ViewChild('previousChatModal') previousChatModal: any;
  @ViewChild('dataPrivacyModel') dataPrivacyModel: any;
  @ViewChild('chatWindow')
  chatWindowRef!: ElementRef;
  isLiveChatEnabled: boolean = false;
  @ViewChild('customerInfoModal') customerInfoModal: any;
  title: string = '';
  @ViewChild('uploadFileModel') uploadFileModel: any;
  uploadImageModalText = "";
  uploadImageModalTitle = "";
  isEmailAttachment: boolean = false;
  isSendImageToFeaturesAPI: boolean = false;
  uploadImageTriggerText = "";
  isObjectDescriptionAttachment: boolean = false;
  stream: any;
  isLoading = false;
  canChangeStyle = false;
  isFeedbackSubmitted = false;
  razorPayObj: any;
  paymentGatewayTriggerIntent = "";
  razorpay_key: any;
  razorpay_currency: any;
  onlinePayment: boolean = false;
  pdf_id: any;

  clientName: any;
  initialIntent: any = "hello";
  captureForEyeSkinDetection: boolean = false;

  @ViewChild('modalWebcamCapture')
  modalWebcamCapture!: TemplateRef<any>;

  constructor(
    private clientService: clientService,
    private messageService: MessageService,
    private audioService: AudioService,
    private cookies: AppCookieService,
    private modalService: NgbModal,
    private locationService: LocationService,
    private encryptionService: EncryptionService,
    private localstorageManagerService: LocalstorageManagerService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
  }


  async ngOnInit() {

    this.fetchInitialData();
    let storageInfo = localStorage.getItem("dataPrivacyPolicy");

    // logic to check if the data privacy policy is accepted or not
    if (storageInfo) {
      let decryptedData = this.encryptionService.decryptData(storageInfo, "dataPrivacyPolicy");
      if (decryptedData == "accepted") {
        this.showDataPrivacyPolicyModel = false;
        this.canStoreDataToLocalStorage = true;
      } else if (decryptedData == "rejected") {
        this.canStoreDataToLocalStorage = false;
        this.showDataPrivacyPolicyModel = false;
      }
    } else {
      this.canStoreDataToLocalStorage = false;
      this.showDataPrivacyPolicyModel = true;
    }

    this.fnStartUserIdleTimer()
    const position: any = await this.locationService.getCurrentLocation();
    console.log(position);
  }


  reciveChatBotScreenValue(event: boolean) {
    this.showChatBotScreen = event;
  }

  handleListenChangeEvent(event: any) {

    if (event == "payment") {
      this.isTyping = true;
      const itemToPush = {
        isUser: false,
        user: 'me',
        text: 'Please proceed with the payment to complete the booking.',
        // buttons: allButton,
        timestamp: this.fnGetDate(),
        isNotEnglish: true,
        widgetType: 'input_message',
        info: '',
        isTrimmed: false,
        info_trimmed: undefined,
        isGoogleMap: undefined
      }

      this.messages.push(itemToPush);
      this.forceScrollDown();

      // add to local storage
      this.handleStoreChatToLocalStorage(itemToPush)

      setTimeout(() => {
        const itemToPush = {
          isUser: false,
          user: 'me',
          text: '',
          // buttons: allButton,
          timestamp: this.fnGetDate(),
          isNotEnglish: true,
          widgetType: 'payment',
          info: '',
          isTrimmed: false,
          info_trimmed: undefined,
          isGoogleMap: undefined
        };

        this.messages.push(itemToPush);
        this.forceScrollDown();

        // add to local storage
        this.handleStoreChatToLocalStorage(itemToPush);
        this.isTyping = false;
      }, 1000);






      return
    }

    this.showScreenValue = event;
  }

  // ! intial data fetch
  fetchInitialData() {
    this.clientService.getClientData({ ClientConfigurationId: this.clientConfigurationId }).subscribe({
      next: (res) => {
        if (res) {



          this.clientConfigurationData = res
          this.showChatBotScreen = this.clientConfigurationData.IsWelcomeScreenDisabled;

          this.botLanguages = this.clientConfigurationData.BotLanguages === "" ? "" : JSON.parse(this.clientConfigurationData.BotLanguages);
          this.BotDefaultLanguage = this.clientConfigurationData.BotDefaultLanguage;
          this.isGoogleTranslation = this.clientConfigurationData.IsGoogleTranslation
          this.isVoice = this.clientConfigurationData.IsVoice == 1 ? true : false;
          this.isAttachment = this.clientConfigurationData.IsAttachment == 1 ? true : false;
          this.IsPrescriptionAttachment = this.clientConfigurationData.isPrescriptionAttachment == 1 ? true : false;
          this.isEmoji = this.clientConfigurationData.IsEmoji == 1 ? true : false;
          this.isMultilingual = this.clientConfigurationData.IsMultilingual == 1 ? true : false;
          this.isIntentDetection = this.clientConfigurationData.IsIntentDetection == 1 ? true : false;
          this.isAccessControl = this.clientConfigurationData.IsAccessControl == 1 ? true : false;
          this.isChatTranscript = this.clientConfigurationData.IsChatTranscript == 1 ? true : false;
          this.isTranslateToEnglish = this.clientConfigurationData.IsTranslateToEnglish == 1 ? true : false;
          this.title = this.clientConfigurationData.Title;

          //setting Data for global variable
          DATA.website_Url = this.clientConfigurationData.website_Url
          DATA.lexBotAlias = this.clientConfigurationData.lexBotAlias
          DATA.title = this.clientConfigurationData.Title;
          DATA.lexPoolId = this.clientConfigurationData.LexPoolId;
          DATA.lexBotName = this.clientConfigurationData.LexBotName;
          DATA.lexBotAlias = this.clientConfigurationData.LexBotAlias;
          DATA.theme = this.clientConfigurationData.Theme;
          DATA.avatarUrl = this.clientConfigurationData.AvatarUrl;
          DATA.logoUrl = this.clientConfigurationData.LogoUrl;
          DATA.welcomeImageUrl = this.clientConfigurationData.WelcomeImageUrl;
          DATA.LanguageDropDownBackgroundColor = this.clientConfigurationData.LanguageDropDownBackgroundColor;
          DATA.LanguageDropDownextColor = this.clientConfigurationData.LanguageDropDownextColor;

          DATA.position = this.clientConfigurationData.BotPosition;
          DATA.size = this.clientConfigurationData.BotLayoutSize;
          this.clientName = this.clientConfigurationData.ClientName;

          DATA.welcomeMessageHeading = this.clientConfigurationData.WelcomeMessageHeading;
          DATA.welcomeMessageData = this.clientConfigurationData.WelcomeMessageData;
          DATA.termsConditionMessage = this.clientConfigurationData.TermsConditionMessage;
          DATA.quickReplies = JSON.parse(this.clientConfigurationData.QuickReplies);
          DATA.launchMessage = this.clientConfigurationData.LaunchMessage;
          DATA.primaryColor = this.clientConfigurationData.PrimaryColor;
          DATA.secondaryColor = this.clientConfigurationData.SecondaryColor;
          DATA.tertiaryColor = this.clientConfigurationData.TertiaryColor;
          DATA.gradientColor1 = this.clientConfigurationData.GradientColor1;
          DATA.gradientColor2 = this.clientConfigurationData.GradientColor2;
          DATA.source = this.clientConfigurationData.Source;

          //Live chat
          DATA.isStartLiveChat = this.clientConfigurationData.isStartLiveChat;
          DATA.LiveChatTriggerResponse = this.clientConfigurationData.LiveChatTriggerResponse;
          DATA.LiveChatBackendURL = this.clientConfigurationData.LiveChatBackendURL;
          DATA.LiveChatContactFlowId = this.clientConfigurationData.LiveChatContactFlowId;
          DATA.LiveChatInstanceId = this.clientConfigurationData.LiveChatInstanceId;
          DATA.LiveChatRegion = this.clientConfigurationData.LiveChatRegion;
          this.isLiveChatEnabled = this.clientConfigurationData.IsLiveChat;
          this.isSendImageToFeaturesAPI = !this.clientConfigurationData.IsImageRecognition;



          //Context Remembrance
          DATA.IsContextRemembrance = this.clientConfigurationData.IsContextRemembrance == 1 ? true : false;
          this.isObjectDescriptionAttachment = !this.clientConfigurationData.IsImageRecognition;


          this.razorpay_key = this.clientConfigurationData.razorpay_key;
          this.razorpay_currency = this.clientConfigurationData.razorpay_currency;
          this.paymentGatewayTriggerIntent = this.clientConfigurationData.PaymentGatewayTriggerResponse;

          if (this.clientConfigurationData.PrimaryColor && this.clientConfigurationData.PrimaryColor != "" && this.clientConfigurationData.SecondaryColor && this.clientConfigurationData.SecondaryColor != "") {
            this.canChangeStyle = true;
            this.fnSetStyles();
          } else {
            this.fnSetDefaultStyles();
          }

          if (this.IsPrescriptionAttachment == true && res.intentName == null) {
            this.uploadImageModalTitle = "Upload Prescription";
            this.uploadImageModalText = "Click here to select a clear picture of your prescription (Max. size: 10MB)";
            this.isEmailAttachment = true;
            this.isSendImageToFeaturesAPI = true;
            this.uploadImageTriggerText = "Please upload a clear picture of your prescription using the upload button";
          }


          if (this.showDataPrivacyPolicyModel) {
            this.fnOpenModal(this.dataPrivacyModel, {
              centered: true,
              size: 'sm',
              backdrop: "static",
              keyboard: true,
              scrollable: false,
              windowClass: 'rounded-modal'
            });
          }


          if (DATA.IsContextRemembrance == false) {
            let previousChats = this.fnGetPreviousChats();
            if (previousChats && previousChats.length > 3) {
              this.fnOpenPreviousChatModal(this.previousChatModal);
            } else {
              localStorage.removeItem("previousChats-" + DATA.clientConfigurationId);
              localStorage.removeItem("sessionAttributes-" + DATA.clientConfigurationId);
              this.fnGetResponse(this.initialIntent);
            }
          } else {
            this.fnGetResponse(this.initialIntent);

          }

        }


      },
      error: (e) => {
      },
      complete() {

      },
    })

  }

  fnSetStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`body{
      color: `+ DATA.primaryColor + `!important;
    }`);

    this.styleSheet.insertRule(`.modal-title{
      color: `+ DATA.primaryColor + `!important;
    }`);

    this.styleSheet.insertRule(`.continue_btn{
      color: ${DATA.primaryColor} !important;
    border: 1px solid ${DATA.primaryColor} !important;
    }`);

    this.styleSheet.insertRule(`.start_new {
      background-color: `+ DATA.primaryColor + `!important;
      color: `+ '#ffffff' + `!important;
    }`);

    this.styleSheet.insertRule(`.uploaded-button {
      background-color: `+ DATA.primaryColor + `!important;
      color: `+ '#ffffff' + `!important;
    }`);


    this.styleSheet.insertRule(`.upload-button:hover {
      background-color: `+ DATA.primaryColor + `!important;
      color: `+ '#ffffff' + `!important;
    }`);

    this.styleSheet.insertRule(`.rating-container {
      background-color: `+ DATA.primaryColor + `!important;
    }`);

    this.styleSheet.insertRule(`.button-box:hover {
      background-color: `+ DATA.primaryColor + `!important;
      color: `+ '#ffffff' + `!important;
    }`);

    this.styleSheet.insertRule(`.progress-bar {
      background-color: `+ DATA.primaryColor + `!important;
    }`);


  }


  fnSetDefaultStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`body{
      color: `+ '#124164' + `!important;
    }`);

    this.styleSheet.insertRule(`.modal-title{
      color: `+ '#124164' + `!important;
    }`);

    this.styleSheet.insertRule(`.continue_btn{
      color: #124164 !important;
    border: 1px solid #124164 !important;
    }`);

    this.styleSheet.insertRule(`.start_new {
      background-color: `+ '#124164' + `!important;
      color: `+ '#ffffff' + `!important;
    }`);

    this.styleSheet.insertRule(`.uploaded-button {
      background-color: `+ '#124164' + `!important;
      color: `+ '#ffffff' + `!important;
    }`);


    this.styleSheet.insertRule(`.upload-button:hover {
      background-color: `+ '#124164' + `!important;
      color: `+ '#ffffff' + `!important;
    }`);

    this.styleSheet.insertRule(`.rating-container {
      background-color: `+ '#124164' + `!important;
    }`);

    this.styleSheet.insertRule(`.button-box:hover {
      background-color: `+ '#124164' + `!important;
      color: `+ '#ffffff' + `!important;
    }`);

    this.styleSheet.insertRule(`.progress-bar {
      background-color: `+ '#124164' + `!important;
    }`);


  }

  fnModalFileUploadSuccess($event: any) {
    //Object description api support
    if (this.isObjectDescriptionAttachment == true) {
      this.isObjectDescriptionImage = true;
      this.fnUploadImageToFeaturesAPI($event[0], $event[1]);
      return;
    }
    if (this.isEmailAttachment == true) {
      this.isEmailAttachmentImage = true;
      this.fnUploadImageToFeaturesAPI($event[0], $event[1]);
      return;
    }
    this.onClick($event[0], $event[1], $event[2]);
  }

  fnUploadImageToFeaturesAPI(base64Img: any, base64ImgFullUri: any) {

    this.onClick("", "", null, undefined, base64Img, base64ImgFullUri);
  }

  // ! setting up seession attribute
  fnSetSessionAttributes() {
    this.sessionAttributes.ORGANIZER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.sessionAttributes.leadChannel = "tina";
    this.sessionAttributes.registrationChannel = "tina";
    this.sessionAttributes.WEBSITE_URL = DATA.website_Url;
    this.sessionAttributes.CleintId = DATA.clientConfigurationId;
    this.sessionAttributes.pdf_id = this.pdf_id;
  }

  // ! getting response from the API
  fnGetResponse(userQuery: any, base64Img = null, base64Audio = null) {

    if (this.isLiveChat == true && this.isLiveChatEnabled == true) {
      this.session.controller.sendMessage({
        message: userQuery,
        contentType: "text/plain"
      })
      return;
    }
    this.isTyping = true;
    this.fnGetSessionAttributesFromLocalStorage();
    this.fnResetUserIdleTimer();
    if (this.isUserIdleTimeoutExpired == true) {
      this.isUserIdleTimeoutExpired = false;
    }



    let inputText = userQuery;
    let languageDetected = "";
    let isNotEnglish = false;
    let param: any = {
      "userInput": inputText,
      "botAlias": DATA.lexBotAlias,
      "sessionAttributes": this.sessionAttributes,
      "features": {
        "authorization": this.isAccessControl,
        "intent_detection": this.isIntentDetection,
        "translation": this.isMultilingual,
        "sentiment_analysis": DATA.IsSentimentAnalysis,
        "sentiment_bth": DATA.IsSentimentBTH,
        "object_description": this.isObjectDescriptionImage,
        "google_translate": this.isGoogleTranslation,
        "email_attachment": this.isEmailAttachmentImage,
        "preferred_language": this.preferredLanguage ? this.preferredLanguage : "en",
        // "CleintId": DATA.clientConfigurationId
      },

      "image": base64Img
    }
    if (base64Audio && base64Audio != null) {
      //this.isTyping = true;
      param = { ...param, ...{ "audio": base64Audio } }
    }

    this.messageService.getSetMessages(param).subscribe({
      next: (res) => {
        if (res) {
          console.log("res intne", res?.body?.intentName);


          if (res?.body?.dialogAction?.type == "ElicitIntent" &&
            res?.body?.dialogAction?.message?.content
          ) {
            this.isTyping = false;

            const itemToPush = {
              isUser: false,
              user: 'me',
              text: res?.body?.dialogAction?.message?.content,
              timestamp: this.fnGetDate(),
              isNotEnglish: true,
              widgetType: 'input_message',
              info: '',
              isTrimmed: false,
              info_trimmed: undefined,
              isGoogleMap: undefined
            };

            this.messages.push(itemToPush);
            this.forceScrollDown();

            // add to local storage
            this.handleStoreChatToLocalStorage(itemToPush);

          }

          if (res?.body?.statusCode == 500) {
            this.isTyping = false;

            const itemToPush = {
              isUser: true,
              user: 'me',
              text: "Sorry, I am unable to process your request at the moment. Please try again later.",
              timestamp: this.fnGetDate(),
              isNotEnglish: true,
              widgetType: 'input_message',
              info: '',
              isTrimmed: false,
              info_trimmed: undefined,
              isGoogleMap: undefined
            };

            this.messages.push(itemToPush);
            this.forceScrollDown();

            // add to local storage
            this.handleStoreChatToLocalStorage(itemToPush);
            this.fnGetResponse("main menu")
            return;
          }

          if (res.body.AudioResponseBase64 != undefined) {
            // push into message of user query
            const itemToPush = {
              isUser: true,
              user: 'me',
              text: res?.body?.transcription,
              timestamp: this.fnGetDate(),
              isNotEnglish: true,
              widgetType: 'input_message',
              info: '',
              isTrimmed: false,
              info_trimmed: undefined,
              isGoogleMap: undefined
            };

            this.messages.push(itemToPush);
            this.forceScrollDown();

            // add to local storage
            this.handleStoreChatToLocalStorage(itemToPush);

          }
          //this.isTyping = false;
          this.fnOnChatResponse(res.body);

          if (res?.body?.intentName == "EW_Get_Recommendations") {
            
            setTimeout(() => {
              this.isTyping = true;
            }, 1100);

            setTimeout(() => {

              // add button to ask for image capture
              const allButton = [
                {
                  text: "Capture Image",
                  value: "Capture_Image_eye_Skin_Detection",
                  type: "button"
                }
              ];
              const itemToPush1 = {
                isUser: false,
                user: 'me',
                text: '',
                buttons: allButton,
                timestamp: this.fnGetDate(),
                isNotEnglish: true,
                widgetType: "button",
                info: '',
                isTrimmed: false,
                info_trimmed: undefined,
                isGoogleMap: undefined

              }


              this.messages.push(itemToPush1);

              this.forceScrollDown();

              // add to local storage
              this.handleStoreChatToLocalStorage(itemToPush1);



              this.isTyping = false;
            }, 3000);
          }

          if (res.body.AudioResponseBase64 != undefined) {
            this.cdr.detectChanges();
            //this.fnOnChatResponse(res.body.message);
            this.audioService.playAudioFromBase64(res.body.AudioResponseBase64);

          }

        }

      },
      error: (e) => {
        // this.isTyping = false;
      },
      complete() {

      },
    })
  }

  // ! modify chat response
  fnOnChatResponse(data: any) {
    this.cdr.detectChanges();
    let chatMessage: any = {};
    let that = this;
    let genericCard = data.responseCard?.genericAttachments;
    //get Button Options 
    let allButton: any = [];


    // live chat support

    if (this.isLiveChatEnabled == true && data.dialogState == "Failed") {
      that.isTyping = false;
      that.fnOpenCustomerInfoModal(that.customerInfoModal);
    }


    if (data.intentName == that.paymentGatewayTriggerIntent || true) {

      this.onlinePayment = true;
      this.razorPayObj = {
        razorpay_currency: this.razorpay_currency,
        razorpay_key: this.razorpay_key,
        clientName: this.clientName,
        email: data.sessionAttributes?.EmailId,
        name: data.sessionAttributes?.UserName,
        contact: data.sessionAttributes?.PhoneNumber,
        app_id: data.sessionAttributes?.app_id,
        hospital_id: data.sessionAttributes?.hospital_id,
        patient_id: data.sessionAttributes?.patient_id,
        center_id: data.sessionAttributes?.Centre,
        client_id: data.sessionAttributes?.CleintId
      }
    }

    let textData = this.setMessages(data.message);
    if (textData) {
      this.introMessage = true;
    }

    let isGenericCard: boolean = data.responseCard && data.responseCard.genericAttachments ? true : false;

    console.log("data", data.message);
    console.log("textData", textData);


    if (data.message) {
      textData.forEach((element: any, i: any) => {

        /* Add support for video messages - Start */
        if (element.message || (element.attributes && element.attributes.length > 0)) {
          /* Add support for other line breaks - Start */
          element.message = this.replaceAll(element.message, "\\b", "<br/>");
          element.message = this.replaceAll(element.message, "\\n", "<br/>");
          /* Add support for other line breaks - End */
          chatMessage = {
            isUser: false,
            user: 'me',
            info: element.message,
            buttons: [],
            isVideo: true,
            // isFullChat: true,
            videoAttributes: element.attributes,
            timestamp: this.fnGetDate(),
            isNotEnglish: true,
            widgetType: "intro_videos"
            // info_untrimmed: element,
          }

        } else {

          const googleMapsEmbedRegex = /https:\/\/www\.google\.com\/maps\/embed\?pb=/;

          if (element.match(googleMapsEmbedRegex)) {
            chatMessage = {
              isUser: false,
              user: 'me',
              text: element.trim(),
              buttons: [],
              timestamp: this.fnGetDate(),
              isNotEnglish: true,
              widgetType: "google_map"
            }
          } else {
            /* Add support for other line breaks - Start */
            element = this.replaceAll(element, "\\b", "<br/>");
            element = this.replaceAll(element, "\\n", "<br/>");
            /* Add support for other line breaks - End */
            if ((data.sessionAttributes?.UserName === null || data.sessionAttributes?.UserName === "" || data.slots?.UserName === null) &&
              this.messages.length == 0) {
              chatMessage = {
                isUser: false,
                user: 'me',
                text: element,
                buttons: [],
                timestamp: this.fnGetDate(),
                isNotEnglish: true,
                widgetType: "intro_text"
              }
            } else {
              chatMessage = {
                isUser: false,
                user: 'me',
                text: element,
                buttons: [],
                timestamp: this.fnGetDate(),
                isNotEnglish: true,
                widgetType: "input_message"
              }
            }
          }
        }

        if (i == 0) {
          console.log("chatMessage in i = 0", chatMessage, textData);
          chatMessage.isShowAvatar = true;
          that.messages.push(chatMessage);
          this.handleStoreChatToLocalStorage(chatMessage)
          that.forceScrollDown();

          if (i == textData.length - 1 && !isGenericCard) {
            that.isTyping = false;
          } else {
            that.isTyping = true;
          }
        } else {
          that.isTyping = true;
          that.chatDelayInterval = that.baseChatDelayInterval * i;
          that.fnAddDelayedChatMessage.bind(that)(chatMessage, i, textData, isGenericCard);

        }


      });
    }

    if (data.responseCard && data.responseCard.genericAttachments) {
      let genericCard = data.responseCard.genericAttachments;
      if (genericCard) {

        let genericCardCount = genericCard.length;
        let i; let holdarray = [];

        for (i = 0; i < genericCardCount; i++) {
          allButton = allButton.concat(genericCard[i].buttons);
        }


      }


      this.calendarFlag = false;
      this.buttonFlag = false;

      if ((genericCard[0].buttons) && (genericCard[0].buttons.length > 0)) {
        this.isTyping = true;

        if (genericCard[0].imageUrl != undefined && (genericCard[0]?.imageUrl !== null) && (genericCard[0]?.imageUrl.trim() !== '')) {
          that.chatDelayInterval += that.baseChatDelayInterval;
          /* Add support for other line breaks - Start */
          genericCard[0].subTitle = that.replaceAll(genericCard[0].subTitle, "\\b", "<br/>");
          genericCard[0].subTitle = that.replaceAll(genericCard[0].subTitle, "\\n", "<br/>");
          /* Add support for other line breaks - End */
          setTimeout(() => {
            this.messages[this.messages.length - 1].cardData = {
              imageUrl: genericCard[0].imageUrl,
              title: genericCard[0].title,
              isTrimmed: false,
              isNotEnglish: false
              // subTitle: genericCard[0].subTitle
            };
            that.forceScrollDown();
          }, that.chatDelayInterval);

        }
        if ((genericCard[0].subTitle !== null) && (genericCard[0].subTitle !== '')) {
          this.messages[this.messages.length - 1].buttons = [];
          this.buttonFlag = false;
          that.chatDelayInterval += that.baseChatDelayInterval;

          setTimeout(() => {

            that.messages.push({
              isUser: false,
              user: 'me',
              text: genericCard[0].subTitle,
              buttons: [],
              timestamp: this.fnGetDate(),
              isNotEnglish: true,
              widgetType: "input_message",
              info: '',
              isTrimmed: false,
              info_trimmed: undefined,
              isGoogleMap: undefined
            });

            that.forceScrollDown();

          }, that.chatDelayInterval);

        }
        that.chatDelayInterval += that.baseChatDelayInterval;

        setTimeout(() => {
          if (data.slotToElicit === 'Date' || data.slotToElicit === 'Time' || data.slotToElicit === 'Doctor') {
            that.buttonFlag = false;
          }
          else {
            that.buttonFlag = true;
          }

          let widgetType = this.buttonFlag ? "button" : data.slotToElicit;
          const itemToPush = {
            isUser: false,
            user: 'me',
            text: '',
            buttons: allButton,
            timestamp: this.fnGetDate(),
            isNotEnglish: true,
            widgetType: widgetType,
            info: '',
            isTrimmed: false,
            info_trimmed: undefined,
            isGoogleMap: undefined
          }
          that.messages.push(itemToPush);
          this.handleStoreChatToLocalStorage(itemToPush)

          that.isTyping = false;

          that.forceScrollDown();

        }, that.chatDelayInterval);

      } else {
        that.isTyping = false;

      }
    }

    this.sessionAttributes = data.sessionAttributes;

    this.fnPushSessionAttributesToLocalStorage();

    if (data.message.includes("your appointment has been booked on")) {
      that.isTyping = true;
      setTimeout(() => {
        this.fnAddRatingReview();
      }, 1500);
    }

    console.log("this.messages", this.messages);
    console.log("istyping", this.isTyping);
  }

  //  ! Add Rating and Review
  fnAddRatingReview() {
    let messages = ["That’s great, Happy to be of help !", "On a scale of 1-5, how would you rate the overall experience ? <br/> 1 being the lowest , 5 being the highest", "give-feeback"]

    messages.forEach((element: any, i: any) => {
      let wType = "input_message";
      if (i == 2) {
        wType = "input_message_feedback"
      }
      let chatMessage = {
        isUser: false,
        user: 'me',
        text: element,
        buttons: [],
        timestamp: this.fnGetDate(),
        isNotEnglish: true,
        widgetType: wType
      }
      this.isTyping = true;
      this.chatDelayInterval = this.baseChatDelayInterval * i;
      this.fnAddDelayedChatMessage.bind(this)(chatMessage, i, [], false, true);
    })



  }

  // ! Pushing Session Attributes to cookie storage to get it back on continue previous chat
  fnPushSessionAttributesToLocalStorage() {



    const encryptedData = this.encryptionService.encryptData(this.sessionAttributes, DATA.clientConfigurationId);
    localStorage.setItem("sessionAttributes-" + DATA.clientConfigurationId, encryptedData);
  }

  // ! Get Session Attributes from cookie storage
  fnGetSessionAttributesFromLocalStorage() {
    const sessionAttributes = localStorage.getItem("sessionAttributes-" + DATA.clientConfigurationId);

    if (!sessionAttributes) {
      this.fnSetSessionAttributes();
    } else {
      this.sessionAttributes = this.encryptionService.decryptData(sessionAttributes, DATA.clientConfigurationId);
      this.sessionAttributes = this.sessionAttributes;
    }
    this.fnSetSessionAttributes();


  }

  // ! Get current date and time
  fnGetDate() {
    return new Date();
  }

  // ! spliting chat response into array based on ~ 
  setMessages(info: any) {
    let temp1: any[] = [];
    if (info.indexOf('~') === -1) {
      temp1 = [info];
    } else {
      temp1 = info.split('~');
    }
    let isVideoLink = false;
    let videoLinkTag = "";
    let videoLinks: any[] = [];
    if (info.includes('#videoLink=')) {
      videoLinkTag = "#videoLink=";
      isVideoLink = true;
      videoLinks = info.split(videoLinkTag);
    }
    // let videoLinks: any[] = info.split('#videoLink=');
    let videoAttributes: any[];
    let textToBeReplaced = "";
    let videoTitle = "";
    let videoMessage: any = {};
    if (videoLinks.length > 1) {
      videoLinks.shift();
      for (let i = 0; i < temp1.length; i++) {
        //Change only the message which contains the video link, leave others.
        if (temp1[i].includes(videoLinkTag)) {
          videoMessage.message = "";
          if (!videoMessage.message) {
            videoMessage.message = "";
          }
          videoMessage.attributes = [];
          videoLinks.forEach(videoLink => {
            videoAttributes = videoLink.split('videoTitle=');
            videoLink = videoAttributes[0];
            videoTitle = videoAttributes[1];
            textToBeReplaced = videoLinkTag + videoLink + 'videoTitle=' + videoTitle;
            videoMessage.message = videoMessage.message.replace(textToBeReplaced, '');
            videoMessage.attributes.push({ videoTitle: this.replaceAll(videoTitle, "'", ""), videoLink: this.replaceAll(videoLink, "'", "") });
            temp1[i] = videoMessage;
          })
        }

      }
    }
    return temp1;
  }

  // ! Close Modal
  fnClosePreviousChatModal() {
    this.fnCloseModal();
  }

  // ! Close Modal
  fnCloseModal() {

    if (this.stream) {
      this.stream.getTracks().forEach(function (track: any) {
        track.stop();
      });
    }

    this.currentPopover.close();
  }

  fnUpdateStream(stream: any) {
    this.stream = stream;
  }

  escapeRegExp(string: any) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  replaceAll(str: any, find: any, replace: any) {
    let result = "";
    if (str && str.replace) {
      result = str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }
    if (result) {
      return result;
    }
    return "";
  }

  // ! listening the user input and calling the response function
  onSubmit(event: any) {

    console.log("messages", this.messages);

    this.cdr.detectChanges();


    const localStorageData = this.localstorageManagerService.getPreviousChatData("previousChats-" + DATA.clientConfigurationId);

    if(event.value && event.value == "Capture_Image_eye_Skin_Detection") {
      this.captureForEyeSkinDetection = true;
      this.fnOpenSmallModal(this.modalWebcamCapture);
      return;
    }

    if (event.value) {

      // checking for last widget type is button of not 
      if (localStorageData && localStorageData.length > 0) {

        const decryptStorageData = this.encryptionService.decryptData(localStorageData, DATA.clientConfigurationId);

        if (decryptStorageData[decryptStorageData.length - 1].widgetType == "button" ||
          decryptStorageData[decryptStorageData.length - 1].widgetType == "Doctor"
        ) {
          decryptStorageData[decryptStorageData.length - 1].clickedValue = event.text;
          const encryptedData = this.encryptionService.encryptData(decryptStorageData, DATA.clientConfigurationId);
          this.localstorageManagerService.setPreviousChatData("previousChats-" + DATA.clientConfigurationId, encryptedData);
        }
      }


      const itemToPush = {
        isUser: true,
        user: 'me',
        text: event.text,
        info: "",
        info_trimmed: "",
        isGoogleMap: "",
        isTrimmed: false,
        timestamp: this.fnGetDate(),
        isNotEnglish: true,
        widgetType: "input_message"
      }
      this.messages.push(itemToPush);
      this.handleStoreChatToLocalStorage(itemToPush)

      this.forceScrollDown();

      this.fnGetResponse(event.value)

    }
    else if (event.audio) {
      this.cdr.detectChanges();
      this.isTyping = true;
      this.forceScrollDown();
      this.fnGetResponse("", null, event.audio)
    }
    else {

      const itemToPush = {
        isUser: true,
        user: 'me',
        text: event,
        info: "",
        info_trimmed: "",
        isGoogleMap: "",
        isTrimmed: false,
        timestamp: this.fnGetDate(),
        isNotEnglish: true,
        widgetType: "input_message"
      }

      this.messages.push(itemToPush);

      this.handleStoreChatToLocalStorage(itemToPush)
      // this.handleGetChatFromCookies()
      this.forceScrollDown();

      this.fnGetResponse(event)
    }



  }

  // ! Changing the Bot Language
  handleBotLanguageChangeEvent(event: any) {
    this.preferredLanguage = event;
  }

  // ! Storing Item in Local Storage
  handleStoreChatToLocalStorage(chatItem: any) {

    console.log("chatItem", chatItem);

    // checking dataTypeIntial just to store initial data
    if (this.canStoreDataToLocalStorage == false && this.dataType != "initial") {
      return;
    }

    this.dataType = "chat";
    let PreviousChat = [];
    const previousChatsData = this.fnGetPreviousChats();

    if (previousChatsData) {
      PreviousChat = previousChatsData;
    }

    PreviousChat.push(chatItem);

    const encryptedData = this.encryptionService.encryptData(PreviousChat, DATA.clientConfigurationId);
    this.localstorageManagerService.setPreviousChatData("previousChats-" + DATA.clientConfigurationId, encryptedData);
  }


  captureForEyeSkinDetectionResponse(event: any) {

    // remove model 
    this.fnCloseModal();

    // make false the captureForEyeSkinDetection
    this.captureForEyeSkinDetection = false;


    const itemToPush1 = {
      isUser: true,
      user: 'me',
      text: event?.base64Image,
      info: "",
      info_trimmed: "",
      isGoogleMap: "",
      isTrimmed: false,
      timestamp: this.fnGetDate(),
      isNotEnglish: true,
      widgetType: "image_message"

    }

    this.messages.push(itemToPush1);

    this.handleStoreChatToLocalStorage(itemToPush1)

    this.forceScrollDown();

    this.isTyping = true;

    setTimeout(() => {
      const itemToPush = {
        isUser: false,
        user: 'me',
        text: event?.skin_tone_result,
        info: "",
        info_trimmed: "",
        isGoogleMap: "",
        isTrimmed: false,
        timestamp: this.fnGetDate(),
        isNotEnglish: true,
        widgetType: "input_message"

      }

      this.messages.push(itemToPush);

      this.handleStoreChatToLocalStorage(itemToPush)

      this.forceScrollDown();
    }, 1500);

    this.isTyping = true;

    setTimeout(() => {
      const itemToPush = {
        isUser: false,
        user: 'me',
        text: "Recommended Products for your skin",
        info: "",
        info_trimmed: "",
        isGoogleMap: "",
        isTrimmed: false,
        timestamp: this.fnGetDate(),
        isNotEnglish: true,
        widgetType: "input_message"

      }

      this.messages.push(itemToPush);

      this.handleStoreChatToLocalStorage(itemToPush)

      this.forceScrollDown();
    }, 3000);

    this.isTyping = true;


    setTimeout(() => {
      this.isTyping = false;
      const itemToPush = {
        isUser: false,
        user: 'me',
        text: '',
        products: event?.product_recommendations,
        info: "",
        info_trimmed: "",
        isGoogleMap: "",
        isTrimmed: false,
        timestamp: this.fnGetDate(),
        isNotEnglish: true,
        widgetType: "Item_Crousel"

      }

      this.messages.push(itemToPush);

      this.handleStoreChatToLocalStorage(itemToPush)

      this.forceScrollDown();
    }, 4500);


  }


  // ! Get Previous Chats from Local Storage and Decrypt and return
  fnGetPreviousChats() {

    const previousChatDataFromLocalStorage = this.localstorageManagerService.getPreviousChatData("previousChats-" + DATA.clientConfigurationId);

    if (!previousChatDataFromLocalStorage) {
      return [];
    }

    const decryptedData = this.encryptionService.decryptData(previousChatDataFromLocalStorage, DATA.clientConfigurationId);

    return decryptedData;
  }

  // ! Start New Chat
  fnStartNewChat() {
    this.messages = [];
    localStorage.removeItem("previousChats-" + DATA.clientConfigurationId);
    localStorage.removeItem("sessionAttributes-" + DATA.clientConfigurationId);
    this.fnGetResponse(this.initialIntent);
  }

  // ! Clear Previous Chat from Cookies
  fnClearPreviousChats() {
    this.localstorageManagerService.removePreviousChatData("previousChats-" + DATA.clientConfigurationId);
  }

  handleAddImageToChat(event: any) {
    const itemToPush = {
      isUser: true,
      user: 'me',
      text: event,
      info: "",
      info_trimmed: "",
      isGoogleMap: "",
      isTrimmed: false,
      timestamp: this.fnGetDate(),
      isNotEnglish: true,
      widgetType: "image_message"
    }

    this.messages.push(itemToPush);

    this.handleStoreChatToLocalStorage(itemToPush)

    this.forceScrollDown();
  }

  lastSentText = "";
  chatInitiatedByUser = false;

  onClick(textToDisplay: any, textToSend: any, index: any, isRefresh = false, base64Img = null, base64ImgFullUrl = null) {
    if (base64Img) {
      this.fnGetResponse(textToSend, base64Img);
      return;
    }

    //Handle button clicks with a tags
    if (textToSend && textToSend.includes("<a")) {
      let div = document.createElement('div');
      div.innerHTML = textToSend.trim();
      let tags = div.getElementsByTagName("a");
      tags[0].click();
      return;
    }
    this.lastSentText = textToSend;
    let cardData = null;
    if (base64ImgFullUrl) {
      cardData = {
        imageUrl: base64ImgFullUrl
      }
    }

    if (isRefresh == true) {
      this.messages = [];
    }

    this.chatInitiatedByUser = true;
    if (textToDisplay !== '') {
      this.firstLoad = false;
    }
    if ((textToSend.indexOf('http://') !== -1) || (textToSend.indexOf('https://') !== -1)) {
      window.open(textToSend, '_blank');
      return;
    } else {
      if (textToDisplay !== '' || (cardData && cardData.imageUrl)) {
        this.messages.push({
          isUser: true,
          user: 'you',
          info: textToDisplay,
          text: '',
          isTrimmed: false,
          isNotEnglish: false,
          info_trimmed: undefined,
          timestamp: undefined,
          isGoogleMap: undefined,
          widgetType: "input_message"
        });
        //Support for local storage
        this.handleStoreChatToLocalStorage({
          isUser: true,
          user: 'you',
          info: textToDisplay !== '' ? textToDisplay : "Image uploaded",
          cardData: null,
          buttons: [],
          isTrimmed: false,
          timestamp: this.fnGetDate(),
          widgetType: "input_message"
        })
        //Support for Transcript
        this.transcripts.push({
          inputTranscript: textToDisplay,
          botResponse: "",
          timestamp: this.fnGetDate()
        })
      } else {
        //Support for Transcript
        this.transcripts.push({
          inputTranscript: "Hello",
          botResponse: "",
          timestamp: this.fnGetDate().toISOString()
        })
      }
      if (index !== null) {
        this.messages[index].buttons = [];
        this.buttonFlag = false;
      }
      this.fnGetResponse(textToSend, base64Img);

    }
  }
  // **********models start ******
  // ! Open Modal
  fnOpenModal(content: any, _options: any = null) {

    if (this.showChatBotScreen == false) {
      return;
    }

    let options: any = {
      centered: true,
      size: 'xl',
      backdrop: "static",
      keyboard: true,
      scrollable: false
    }
    if (_options != null) {
      options = _options;
    }
    this.currentPopover = this.modalService.open(content, options);
  }

  fnOpenCustomerInfoModal(content: any) {
    this.fnOpenModal(content, {
      centered: true,
      size: 'sm',
      backdrop: "static",
      keyboard: true,
      scrollable: false
    });
  }

  fnCloseCustomerInfoModal() {
    this.fnCloseModal();
  }
  // !open file upload model
  fnOpenFileUploadModel() {
    const content = this.uploadFileModel;
    this.fnOpenModal(content, {
      centered: true,
      size: 'xs',
      backdrop: "static",
      keyboard: true,
      scrollable: false
    });
  }

  fnCloseUploadFileModal() {
    this.currentPopover.close();
  }

  // ! Open Previous Chat Modal
  fnOpenPreviousChatModal(content: any) {
    this.fnOpenModal(content, {
      centered: true,
      size: 'sm',
      backdrop: "static",
      keyboard: true,
      scrollable: false,
      windowClass: 'rounded-modal'
    });
  }

  // ! Set Session Attributes from Local Storage
  fnContinuePreviousChat() {
    this.messages = this.fnGetPreviousChats();
    this.forceScrollDown();
  }

  fnOpenSmallModal(content: any) {

    // close the uploadFileModel model
    this.currentPopover.close();

    let options: any = {
      centered: true,
      size: 'sm',
      backdrop: false,
      keyboard: true,
      scrollable: false
    }
    this.currentPopover = this.modalService.open(content, options);
  }

  // **********models close******

  DataPrivacyPolicy(value: boolean) {
    this.canStoreDataToLocalStorage = value;

    if (value) {
      localStorage.setItem("dataPrivacyPolicy", this.encryptionService.encryptData("accepted", "dataPrivacyPolicy"));
      localStorage.setItem("sessionAttributes-" + DATA.clientConfigurationId, this.encryptionService.encryptData(this.sessionAttributes, DATA.clientConfigurationId));
      localStorage.setItem("previousChats-" + DATA.clientConfigurationId, this.encryptionService.encryptData(this.messages, DATA.clientConfigurationId));
    } else {
      localStorage.setItem("dataPrivacyPolicy", this.encryptionService.encryptData("rejected", "dataPrivacyPolicy"));
    }

    this.fnCloseModal();
  }

  imageToBeUploadedBlob = null;
  fnImageUploadedToServer(response: any) {

    this.isTyping = true;

    // alert(serverResponse);
    let serverResponse: any = {};
    if (response) {
      this.imageToBeUploadedBlob = response.imageBlob;
      serverResponse = JSON.parse(response.responseText);
    }
    if (serverResponse.data && serverResponse.data.message) {
      this.stream.getTracks().forEach(function (track: any) {
        track.stop();
      });
      this.fnCloseModal();
    }


    if (response.responseText) {
      ``
      const res = JSON.parse(response.responseText);
      const itemToPush = {
        isUser: false,
        user: 'me',
        text: res?.data?.message,
        info: "",
        info_trimmed: "",
        isGoogleMap: "",
        isTrimmed: false,
        timestamp: this.fnGetDate(),
        isNotEnglish: true,
        widgetType: "input_message"
      }

      this.messages.push(itemToPush);

      this.handleStoreChatToLocalStorage(itemToPush)
      this.isTyping = false;
      this.forceScrollDown();
    }
  }

  startTyping() {
    this.isTyping = true;
    this.forceScrollDown();

  }


  /*******Live Chat Code Start */
  session: any;
  isLiveChat = false;
  customerName = "";
  isAgentTyping = false;
  isInitialisingLiveChat = false;
  transcriptEmail = "";
  transcripts: any[] = [];
  agentInfoId: any = "";
  agentRatingButton: boolean = false;

  fnInitLiveChat() {

    // checking for live chat enabled or not
    if (this.isLiveChat == true) {
      this.fnEndChat();
      const dataToPush = {
        isUser: true,
        user: 'me',
        text: "Live chat ended",
        info: "",
        info_trimmed: "",
        isGoogleMap: "",
        isTrimmed: false,
        timestamp: this.fnGetDate(),
        isNotEnglish: true,
        widgetType: "input_message"
      }
      this.messages.push(dataToPush);
      this.handleStoreChatToLocalStorage(dataToPush)
      return;
    }

    this.isLiveChat = true;
    this.forceScrollDown();
    this.isInitialisingLiveChat = true;

    let that = this;
    let customerName = that.customerName;
    var contactFlowId = DATA.LiveChatContactFlowId;
    var instanceId = DATA.LiveChatInstanceId;
    var apiGatewayEndpoint = DATA.LiveChatBackendURL;
    connect.ChatSession.setGlobalConfig({
      region: DATA.LiveChatRegion
    });

    let initiateChatRequest = {
      Attributes: {
        customerName: customerName
      },
      Username: customerName,
      ParticipantDetails: {
        DisplayName: customerName
      },
      ContactFlowId: contactFlowId,
      InstanceId: instanceId
    };

    this.http.post(apiGatewayEndpoint, initiateChatRequest)
      .toPromise().then(function (result: any) {
        that.session = connect.ChatSession.create({
          chatDetails: result.data.startChatResult,
          type: "CUSTOMER"
        });
        that.session.connect()?.then(async (response: any) => {

          let agentTranscripts = that.fnGetChatTranscriptForAgent();
          for (let i = 0; i < agentTranscripts.length; i++) {
            await that.session.controller.sendMessage({
              message: agentTranscripts[i],
              contentType: "text/plain"
            })
          }

          const itemToPush = {
            isUser: false,
            user: 'me',
            text: "Thanks " + customerName + ", you are now connected. Please wait for an agent to respond",
            info: "",
            info_trimmed: "",
            isGoogleMap: "",
            isTrimmed: false,
            timestamp: that.fnGetDate(),
            isNotEnglish: true,
            widgetType: "input_message"
          }
          that.messages.push(itemToPush);
          that.forceScrollDown();
          that.handleStoreChatToLocalStorage(itemToPush)
          that.isInitialisingLiveChat = false;
          return response;
        }, (error: any) => {
          return Promise.reject(error);
        });

        that.session.onConnectionEstablished((data: any) => {
        })

        that.session.onMessage((message: any) => {
          if (message && message.data.ContentType == "application/vnd.amazonaws.connect.event.participant.joined" && message.data.ParticipantRole == "AGENT") {
            let params = {
              clientId: DATA.clientConfigurationId,
              startCnvTime: message.data.AbsoluteTime,
              agentName: message.data.DisplayName,
              customerName: customerName
            }
            that.saveAgentInfo(params);
          }

          if (message && message.data && message.data.ParticipantRole == "AGENT" && message.data.Content) {
            that.isAgentTyping = false;
            const itemToPush = {
              isUser: false,
              user: 'me',
              text: message.data.Content,
              info: "",
              info_trimmed: "",
              isGoogleMap: "",
              isTrimmed: false,
              timestamp: that.fnGetDate(),
              isNotEnglish: true,
              widgetType: "input_message"
            }
            that.messages.push(itemToPush);

            that.forceScrollDown();
            //Support for local storage
            that.handleStoreChatToLocalStorage(itemToPush)
          }
          that.forceScrollDown();
          if (message && message.data && message.data.ContentType) {
            if (message.data.ContentType == "application/vnd.amazonaws.connect.event.chat.ended" && message.data.Type == "EVENT") {
              that.liveChatEndTime = message.data.AbsoluteTime;
              let params = {
                agentInfoId: that.agentInfoId,
                endCnvTime: message.data.AbsoluteTime,
              }
              that.updateAgentInfo(params);
              that.isAgentTyping = false;
              const itemToPush = {
                isUser: false,
                user: 'me',
                text: "Welcome back " + customerName + ", Hope you had a great conversation with our live agent It will be great if you can please provide a feedback for the conversation with our agent",
                info: "",
                info_trimmed: "",
                isGoogleMap: "",
                isTrimmed: false,
                timestamp: that.fnGetDate(),
                isNotEnglish: true,
                widgetType: "input_message"
              }
              that.messages.push(itemToPush);
              that.forceScrollDown();
              //Support for local storage
              that.handleStoreChatToLocalStorage(itemToPush)

              that.fnEndChat();
              that.agentRatingButton = true;

            }

          }

        });
        that.forceScrollDown();
        that.session.onTyping((typingEvent: any) => {
          if (typingEvent.data.ParticipantRole === "AGENT") {
            that.isAgentTyping = true;
          }
        });

        that.session.onConnectionBroken((data: any) => {
          console.log("Connection broken");
        });
      })
  }
  fnEndChat() {
    this.isLiveChat = false;
    this.session.controller.disconnectParticipant();
  }

  saveAgentInfo(agentparams: any) {
    let that = this;
    let url = "https://lkgx3whm57.execute-api.us-east-1.amazonaws.com/Prod/createagentinfo"
    that.http.post(url, agentparams)
      .toPromise().then(function (result: any) {
        let datttt = JSON.stringify(result);
        that.agentInfoId = datttt;
      })
  }
  updateAgentInfo(agentparams: any) {
    let url = "https://7du8yh5prh.execute-api.us-east-1.amazonaws.com/production/updateAgentInfo"
    this.http.post(url, agentparams)
      .toPromise().then(function (result: any) {

      })
  }

  /** Live Chat code end */

  handleFeedBackSubmit(event: any) {
    this.isFeedbackSubmitted = true;
  }

  downloadingPdf = false;
  generatePdf() {
    this.downloadingPdf = true;
    const data = document.getElementById('conversationContent');
    const originalHeight = data!.style.height;
    const originalOverflow = data!.style.overflow;

    // Temporarily expand the content to its full height
    data!.style.height = 'auto';
    data!.style.overflow = 'visible';

    html2canvas(data!, { scale: 2 }).then(canvas => {  // Reduced scale for smaller file size
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const pdf = new jsPDF('p', 'mm', 'a4');
      let position = 0;

      // Add the image with compression
      pdf.addImage(canvas.toDataURL('image/jpeg', 0.8), 'JPEG', 0, position, imgWidth, imgHeight); // Changed to JPEG with compression
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(canvas.toDataURL('image/jpeg', 0.8), 'JPEG', 0, position, imgWidth, imgHeight); // Changed to JPEG with compression
        heightLeft -= pageHeight;
      }

      pdf.save('conversation.pdf');

      // Restore the original height and overflow properties
      data!.style.height = originalHeight;
      data!.style.overflow = originalOverflow;

      setTimeout(() => {
        this.forceScrollDown();
      }, 200);

      this.downloadingPdf = false;
    });
  }



  // ! Force to Scroll down on each new message
  public forceScrollDown(): void {
    if (this.chatWindowRef !== undefined) {
      setTimeout(() => {

        this.chatWindowRef.nativeElement.scrollTop = this.chatWindowRef?.nativeElement.scrollHeight;

      }, 100);
    }

    this.cdr.detectChanges();
  }

  // ! Adding delay in Chat Response
  private fnAddDelayedChatMessage(chatMessage: any, i: any, textData: any, isGenericCard: any, israting = false) {
    console.log("chatMessage", chatMessage);
    let that = this;
    setTimeout(() => {
      that.messages.push(chatMessage);
      //Support for cookies
      if (!israting) {
        this.handleStoreChatToLocalStorage(chatMessage)
      }

      if (i == textData.length - 1 && !isGenericCard) {
        that.isTyping = false;
      }
      that.forceScrollDown();
    }, that.chatDelayInterval);
  }

  // ! Start User Idle Timer (to Notice User Inactivity)
  private fnStartUserIdleTimer() {
    let that = this;
    this.userTimeoutFunction = setTimeout(() => {
      that.isUserIdleTimeoutExpired = true;
    }, that.userIdleTimeout);
  }

  // ! Reset User Idle Timer
  private fnResetUserIdleTimer() {
    if (this.userTimeoutFunction) {
      clearTimeout(this.userTimeoutFunction);
    }
    this.fnStartUserIdleTimer();
  }

  public fnGetChatTranscriptForAgent() {
    let agentTranscripts = ["****Chat Start****"];

    for (let i = 0; i < this.transcripts.length; i++) {
      agentTranscripts.push("[**User: " + this.transcripts[i].inputTranscript + " **]"
        + "      [**Bot: " + this.transcripts[i].botResponse + " **]");
    }

    agentTranscripts.push("****Chat Ended****");
    return agentTranscripts;

  }
  fileName: string | null = null;
  base64Data: string | null = null;
  isPdfLoading = false;
  private subscription: Subscription = new Subscription;
  statusMessage: string | null = null;

  @ViewChild('fileInput') fileInput: any;


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer?.files.length) {
      this.handleFile(event.dataTransfer.files[0]);
    }
  }

  onFileSelected(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.handleFile(file);
    }
  }

  handleFile(file: File) {
    this.fileName = file.name;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.base64Data = this.convertToBase64(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  convertToBase64(pdf: string): string {
    return pdf.split(',')[1]; // Remove the Data URL prefix
  }

  removePDF() {
    this.fileName = null;
    this.base64Data = null;
  }


  checkStatus(intent_name: any): void {
    let param = {
      userInput: intent_name,
      botAlias: 'pdfchatagent',
      sessionAttributes: {
        ORGANIZER_TIMEZONE: 'Asia/Calcutta',
        leadChannel: 'tina',
        pdf_id: this.pdf_id,
        is_pdf_chat: 'false',
        registrationChannel: 'tina',
        CleintId: 'a0a41f56-be29-40dc-b782-3214acf40ae6'
      },
      features: {
        authorization: false,
        intent_detection: false,
        translation: false,
        sentiment_analysis: false,
        sentiment_bth: false,
        object_description: false,
        google_translate: false,
        email_attachment: false,
        preferred_language: 'en'
      },
      image: null
    };

    this.subscription = interval(2000)
      .pipe(
        switchMap(() => {
          return this.messageService.getSetMessages(param);
        }),
        tap(response => {
          console.log('API response:', response);
        }),
        takeWhile(response => {
          const isCompleted = response?.body?.message !== 'completed';
          console.log('Current status:', response?.body?.message, 'Continue polling:', isCompleted);
          return isCompleted;
        }, true)
      )
      .subscribe(
        response => {
          if (response?.body?.message === 'completed') {
            this.statusMessage = 'Status completed!';
            this.subscription.unsubscribe();
            this.triggerIntent('query');
          } else {
            this.statusMessage = 'Status: Processing...';
          }
        },
        error => {
          console.error('Error occurred:', error); // Log the error
          this.statusMessage = 'Error occurred: ' + error.message;
        }

      );
  }


  triggerIntent(intentName: any): void {

    let param = {
      "userInput": intentName,
      "botAlias": "pdfchatagent",
      "sessionAttributes": this.sessionAttributes,
      "features": {
        "authorization": false,
        "intent_detection": false,
        "translation": false,
        "sentiment_analysis": false,
        "sentiment_bth": false,
        "object_description": false,
        "google_translate": false,
        "email_attachment": false,
        "preferred_language": "en"
      },
      "image": null
    }

    this.http.post("https://jv1ie3n7cg.execute-api.us-east-1.amazonaws.com/dev/feature-api-extended",
      param
    )
      .subscribe(
        data => {
          this.statusMessage = 'Data received: ' + JSON.stringify(data);

          const itemToPush = {
            isUser: false,
            user: 'me',
            text: "PDF processing is complete. You can now ask questions about the document.",
            info: "",
            info_trimmed: "",
            isGoogleMap: "",
            isTrimmed: false,
            timestamp: this.fnGetDate(),
            isNotEnglish: true,
            widgetType: "input_message"
          }

          this.messages.push(itemToPush);
          this.forceScrollDown();
          this.handleStoreChatToLocalStorage(itemToPush)
        },
        error => {
          this.statusMessage = 'Error fetching data: ' + error.message;
        },

        () => {
          this.isTyping = false;
        }

      );
  }

  submitPDF() {
    if (this.base64Data) {
      console.log('Submitting PDF:', this.base64Data);
      this.isPdfLoading = true;
      const url = "https://v43lof3n6vycvl5ogtxkvwmjni0cmgds.lambda-url.us-east-1.on.aws/"
      let that = this;


      this.fnCloseModal();
      this.isTyping = true;
      const headers = new HttpHeaders({
        'Content-Type': 'text/plain'
      });

      const param = {
        "pdf_id": "",
        "is_pdf_chat": "false",
        "fileName": this.fileName,
        "client_id": "a0a41f56-be29-40dc-b782-3214acf40ae6",
        "file": this.base64Data,
      };


      this.removePDF();

      this.http.post('https://v43lof3n6vycvl5ogtxkvwmjni0cmgds.lambda-url.us-east-1.on.aws', param, { headers })
        .subscribe((res: any) => {
          console.log(res);
          if (res) {
            this.pdf_id = res.pdf_id;
            this.sessionAttributes = {
              ...this.sessionAttributes,
              pdf_id: this.pdf_id
            }

            this.isPdfLoading = false;


            const itemToPush = {
              isUser: false,
              user: 'me',
              text: "I'm processing your PDF. Please wait as this will give me a better understanding to answer your queries.",
              info: "",
              info_trimmed: "",
              isGoogleMap: "",
              isTrimmed: false,
              timestamp: that.fnGetDate(),
              isNotEnglish: true,
              widgetType: "input_message"
            }

            this.messages.push(itemToPush);
            this.forceScrollDown();
            this.handleStoreChatToLocalStorage(itemToPush)

            this.checkStatus("notify_pdf_processing_complete");
          }
        }, error => {
          console.error(error);
          this.isPdfLoading = false;
        }
        );
    }
  }

  closeChat() {
    console.log('Closing chat');
    // Send a message to the parent window to close the chat
    window.parent.postMessage('closeChatBot', '*');
    // window.opener.postMessage('Message to the parent', "*");
  }
}
