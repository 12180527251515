<div id="app">
    <canvas #canvas id="canvas" width="{{width}}" height="{{height}}"></canvas>
    <div class="d-flex align-items-center justify-content-center">
      <div >
        <video style="object-fit: fill; border-radius: 10px;" [style.visibility]="captureSource == '' ? 'visible' : 'hidden'"
          [style.height]="captureSource == '' ? '100%' : 0" [style.width]="captureSource == '' ? '100%': 0" #video
          id="video" autoplay></video>
        <img 
        
        style="    height: 187px;
        width: 282px;
        object-fit: cover;
        border-radius: 10px;"
        *ngIf="captureSource" [src]="captureSource" height="{{height}}" />
      </div>
    </div>
    <div class="d-flex justify-content-center"
    [class.button_grp]="captureSource != ''"
    >
      <button type="button" class="btn btn-outline shadow take-sanp mt-2" 
      style="display: flex; align-items: center; gap: 8px;"
      *ngIf="captureSource == ''" (click)="capture()">
        <img src="assets/images/camera.png" 
        style="height: 20px; width: 20px; object-fit: contain;"
        alt="camera" class="camera-icon" />
        Capture</button>
      <button type="button" *ngIf="captureSource != ''" class="btn btn-outline shadow retake" (click)="captureSource=''">Retake</button>
      <button 
      [disabled]="isLoading"
      type="button"
      class="btn btn-outline shadow upload-button5" *ngIf="captureSource" (click)="uploadImage()">
        
    {{isLoading ? 'Uploading...' : 'Upload'}}
    </button>
      
    </div>
  </div>
  