import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { UserInputComponent } from './components/user-input/user-input.component';
import { ChatsComponent } from './components/chats/chats.component';
import { FrontScreenComponent } from './components/screens/front-screen/front-screen.component';
import { FormsModule } from '@angular/forms';
import { TinaIntroComponent } from './components/screens/tina-intro/tina-intro.component';
import { MainMenuScreenComponent } from './components/screens/main-menu-screen/main-menu-screen.component';
import { AboutUsComponent } from './components/screens/about-us/about-us.component';
import { OurTeamComponent } from './components/screens/our-team/our-team.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SolutionScreenComponent } from './components/screens/solution-screen/solution-screen.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { HttpClientModule } from '@angular/common/http';
import { IntroWidgetComponent } from './components/intro-widget/intro-widget.component';
import { VideoCarouselComponent } from './components/video-carousel/video-carousel.component';
import { SafePipe } from './safe.pipe';
import { ButtonWidgetComponent } from './components/button-widget/button-widget.component';
import { SafeHTMLPipe } from './safe-html.pipe';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { DateWidgetComponent } from './components/date-widget/date-widget.component';
import { TimeWidgetComponent } from './components/time-widget/time-widget.component';
import { DoctorsWidgetComponent } from './components/doctors-widget/doctors-widget.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RatingFeedbackWidgetComponent } from './components/rating-feedback-widget/rating-feedback-widget.component';
import { WebcamCaptureComponent } from './components/webcam-capture/webcam-capture.component';
import {WebcamModule} from 'ngx-webcam';
import { FileuploadComponent } from './components/fileupload/fileupload.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ItemCrouselWidgetComponent } from './components/item-crousel-widget/item-crousel-widget.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ChatbotComponent,
    UserInputComponent,
    ChatsComponent,
    FrontScreenComponent,
    TinaIntroComponent,
    MainMenuScreenComponent,
    AboutUsComponent,
    OurTeamComponent,
    SolutionScreenComponent,
    IntroWidgetComponent,
    VideoCarouselComponent,
    SafePipe,
    ButtonWidgetComponent,
    SafeHTMLPipe,
    StarRatingComponent,
    DateWidgetComponent,
    TimeWidgetComponent,
    DoctorsWidgetComponent,
    RatingFeedbackWidgetComponent,
    WebcamCaptureComponent,
    FileuploadComponent,
    ClickOutsideDirective,
    ItemCrouselWidgetComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    PickerModule,
    CarouselModule,
    WebcamModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
