<div #scrollContainer>
    <div class="chat-buttons-action-container" #scrollContainer>
      <div class="chat-message-type2" style="margin-top: -5px">Solutions</div>
  
      <div class="chat-message-left-type2">
        Please choose the type of solution you want for your business.
      </div>
  
      <div style="margin: 16px 0 3px">
        <div>
          <!-- "About Us" button -->
          <button
            class="bot-v2-button"
            (click)="onButtonClickBot('Main Menu')"
            (mouseenter)="onButtonHoverBot('Main Menu')"
            (mouseleave)="onButtonLeaveBot('Main Menu')"
            [class.clicked]="activeButton === 'Main Menu'"
            [class.hovered]="hoveredButton === 'Main Menu'"
          >
            Main Menu
          </button>
  
          <!-- "Contact Us" button -->
          <button
            class="bot-v2-button"
            (click)="onButtonClickBot('By Industry')"
            (mouseenter)="onButtonHoverBot('By Industry')"
            (mouseleave)="onButtonLeaveBot('By Industry')"
            [class.clicked]="activeButton === 'By Industry'"
            [class.hovered]="hoveredButton === 'By Industry'"
          >
            By Industry
          </button>
  
          <!-- "Services" button -->
          <button
            class="bot-v2-button"
            (click)="onButtonClickBot('By Function')"
            (mouseenter)="onButtonHoverBot('By Function')"
            (mouseleave)="onButtonLeaveBot('By Function')"
            [class.clicked]="activeButton === 'By Function'"
            [class.hovered]="hoveredButton === 'By Function'"
          >
            By Function
          </button>
  
          <!-- "Our Procedures" button -->
        </div>
      </div>
  
      <!-- when user click on by industry -->
      <div>
        <div class="chat-message-type2">By Industry</div>
  
        <div class="chat-message-left-type2">
          Below are the <b>industry wise AI-powered solution</b> for your
          business.
        </div>
  
        <div class="ai-solution-box">
          <div style="width: 100%">
            <div class="ai-solution-box1">
              <div class="black-shadow">
                <div class="ai-solution-box-heading">Healthcare</div>
                <div class="ai-solution-box-subheading">
                  <span style="font-size: 18px"> 30% </span>
                  <span> Save staff Efforts and time </span>
                </div>
              </div>
            </div>
  
            <div
              style="
                padding: 8px 0px 5px;
                background: #ffffff;
                border-radius: 5px;
                margin-top: -6px;
                box-shadow: 0px 4px 4px 0px #00000040;
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0 10px;
                "
              >
                <div class="know-more">Know More</div>
                <div
                  *ngIf="show_plus"
                  (click)="togglePlusButton()"
                  style="cursor: pointer"
                >
                  <img src="../../assets/images/botv2/plus.png" alt="" />
                </div>
                <div
                  *ngIf="!show_plus"
                  (click)="togglePlusButton()"
                  style="font-weight: 800; cursor: pointer; color: #156087"
                >
                  -
                </div>
              </div>
  
              <div
                *ngIf="!show_plus"
                style="background-color: #f5f5f5; padding: 0 10px"
              >
                <div>
                  <hr style="margin: 1px; padding: 0" />
                  <div class="schedule-demo">Benefits</div>
                </div>
                <div>
                  <hr style="margin: 1px; padding: 0" />
                  <div class="schedule-demo">Features</div>
                </div>
                <div>
                  <hr style="margin: 1px; padding: 0" />
                  <div class="schedule-demo">ROI Calculator</div>
                </div>
                <div>
                  <hr style="margin: 1px; padding: 0" />
                  <div class="schedule-demo">Client Reviews</div>
                </div>
              </div>
  
              <div style="padding: 0 10px">
                <hr style="margin: 1px; padding: 0" />
                <div class="schedule-demo-1">Schedule Demo</div>
              </div>
            </div>
          </div>
  
          <div style="width: 100%">
            <div class="ai-solution-box2">
              <div class="black-shadow">
                <div class="ai-solution-box-heading">Healthcare</div>
                <div class="ai-solution-box-subheading">
                  <span style="font-size: 18px"> 30% </span>
                  <span> Save staff Efforts and time </span>
                </div>
              </div>
            </div>
  
            <div
              style="
                padding: 8px 0px 5px;
                background: #ffffff;
                border-radius: 5px;
                margin-top: -6px;
                box-shadow: 0px 4px 4px 0px #00000040;
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0 10px;
                "
              >
                <div class="know-more">Know More</div>
                <div
                  *ngIf="show_plus2"
                  (click)="togglePlusButton2()"
                  style="cursor: pointer"
                >
                  <img src="../../assets/images/botv2/plus.png" alt="" />
                </div>
                <div
                  *ngIf="!show_plus2"
                  (click)="togglePlusButton2()"
                  style="font-weight: 800; cursor: pointer; color: #156087"
                >
                  -
                </div>
              </div>
  
              <div
                *ngIf="!show_plus2"
                style="background-color: #f5f5f5; padding: 0 10px"
              >
                <div>
                  <hr style="margin: 1px; padding: 0" />
                  <div class="schedule-demo">Benefits</div>
                </div>
                <div>
                  <hr style="margin: 1px; padding: 0" />
                  <div class="schedule-demo">Features</div>
                </div>
                <div>
                  <hr style="margin: 1px; padding: 0" />
                  <div class="schedule-demo">ROI Calculator</div>
                </div>
                <div>
                  <hr style="margin: 1px; padding: 0" />
                  <div class="schedule-demo">Client Reviews</div>
                </div>
              </div>
  
              <div style="padding: 0 10px">
                <hr style="margin: 1px; padding: 0" />
                <div class="schedule-demo-1">Schedule Demo</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- when use click on Benefits -->
      <div>
        <div class="chat-message-type2">Healthcare - Benefits</div>
  
        <div class="chat-message-left-type2">
          Below are the benefits of AI Powered Healthcare Assistant
        </div>
  
        <div class="chat-message-left-type2" style="position: relative">
          <ul style="margin-left: -26px; margin-bottom: -1px; margin-right: 10px">
            <li>
              Be the <b>1st mover</b> to use Artificial intelligence in Healthcare
              sector
            </li>
            <li>Engage your <b>patients 24/7</b></li>
            <li>Save staff effort by <b>20-30%</b></li>
            <li>Reduce patient engagement cost by <B>30-50%</B></li>
          </ul>
  
          <div class="thumb-up">
            <img src="../../assets/images/botv2/thumb-up.png" alt="" />
          </div>
          <div class="thumb-down">
            <img src="../../assets/images/botv2/thumb-down.png" alt="" />
          </div>
        </div>
  
        <div class="chat-message-left-type2">
          To find out more about healthcare sector,
          <b>click on the below buttons</b>
        </div>
  
        <div style="margin: 16px 0 3px">
          <div>
            <!-- "About Us" button -->
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Back')"
              (mouseenter)="onButtonHoverBot('Back')"
              (mouseleave)="onButtonLeaveBot('Back')"
              [class.clicked]="activeButton === 'Back'"
              [class.hovered]="hoveredButton === 'Back'"
            >
              Back
            </button>
  
            <!-- "Contact Us" button -->
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Features')"
              (mouseenter)="onButtonHoverBot('Features')"
              (mouseleave)="onButtonLeaveBot('Features')"
              [class.clicked]="activeButton === 'Features'"
              [class.hovered]="hoveredButton === 'Features'"
            >
              Features
            </button>
  
            <!-- "Services" button -->
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('ROI Calculator')"
              (mouseenter)="onButtonHoverBot('ROI Calculator')"
              (mouseleave)="onButtonLeaveBot('ROI Calculator')"
              [class.clicked]="activeButton === 'ROI Calculator'"
              [class.hovered]="hoveredButton === 'ROI Calculator'"
            >
              ROI Calculator
            </button>
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Client Reviews')"
              (mouseenter)="onButtonHoverBot('Client Reviews')"
              (mouseleave)="onButtonLeaveBot('Client Reviews')"
              [class.clicked]="activeButton === 'Client Reviews'"
              [class.hovered]="hoveredButton === 'Client Reviews'"
            >
              Client Reviews
            </button>
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Schedule Demo')"
              (mouseenter)="onButtonHoverBot('Schedule Demo')"
              (mouseleave)="onButtonLeaveBot('Schedule Demo')"
              [class.clicked]="activeButton === 'Schedule Demo'"
              [class.hovered]="hoveredButton === 'Schedule Demo'"
            >
              Schedule Demo
            </button>
  
            <!-- <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Schedule Demo')"
              (mouseenter)="onButtonHoverBot('Schedule Demo')"
              (mouseleave)="onButtonLeaveBot('Schedule Demo')"
              [class.clicked]="activeButton === 'Schedule Demo'"
              [class.hovered]="hoveredButton === 'Schedule Demo'"
            >
              Schedule Demo
            </button> -->
  
            <!-- "Our Procedures" button -->
          </div>
        </div>
      </div>
  
      <!-- when Client click on healtcatre Reviews -->
      <div>
        <div class="chat-message-type2">Healthcare - Client Reviews</div>
        <div class="chat-message-left-type2">
          Below are the <b>Client Reviews</b> of AI Powered Healthcare Assistant
        </div>
  
        <div class="features-box">
          <div class="features-box-top">
            Tilganga, Institute of Ophthalmology, Nepal
          </div>
          <div class="features-box-middle">
            TINA- AI Ophthalmology Assistant has been a great help to our
            patients, informing them about eye diseases and booking appointment at
            our hospital. AI Ophthalmology Assistant has been a valuable addition
            to our organization."
          </div>
          <div class="features-box-last">
            <a href="www.tilganga.org">www.tilganga.org</a>
  
            <div class="features-box-image">
              <img
                src="../../assets/images/botv2/LPbB7BPKv6jKsx8gn2jWcnAZ8G09ewIoThNOsu7e 2.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="features-box">
          <div class="features-box-top">H.V. Desai Eye Hospital, Maharashtra</div>
          <div class="features-box-middle">
            Team Gravitas AI is committed to quality, reliability and timely
            delivery. We have numerous instances, where the team members have gone
            above and beyond to provide support and to accommodate our business
            needs
          </div>
          <div class="features-box-last">
            <a href="www.hvdeh.org">www.hvdeh.org</a>
  
            <div class="features-box-image">
              <img src="../../assets/images/botv2/HVD-ldentity-1 3.png" alt="" />
            </div>
          </div>
        </div>
        <div class="features-box">
          <div class="features-box-top">
            Leela Eye Hospital, Thiruvanmiyur, Chennai
          </div>
          <div class="features-box-middle">
            The virtual assistant, TINA deployed by Gravitas AI, to improve our
            patient journey has resulted in increase in patient satisfaction and
            freed up the staff's time to focus on more productive activities.
          </div>
          <div class="features-box-last">
            <a href="www.leelaeyeclinic.net">www.leelaeyeclinic.net</a>
  
            <div class="features-box-image">
              <img
                src="../../assets/images/botv2/launch post_Leela eye Care (1) 2.png"
                alt=""
              />
            </div>
          </div>
        </div>
  
        <div style="margin: 16px 0 3px">
          <div>
            <!-- "About Us" button -->
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Back')"
              (mouseenter)="onButtonHoverBot('Back')"
              (mouseleave)="onButtonLeaveBot('Back')"
              [class.clicked]="activeButton === 'Back'"
              [class.hovered]="hoveredButton === 'Back'"
            >
              Back
            </button>
  
            <!-- "Contact Us" button -->
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Load More')"
              (mouseenter)="onButtonHoverBot('Load More')"
              (mouseleave)="onButtonLeaveBot('Load More')"
              [class.clicked]="activeButton === 'Load More'"
              [class.hovered]="hoveredButton === 'Load More'"
            >
              Load More
            </button>
  
            <!-- "Services" button -->
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Schedule Demo')"
              (mouseenter)="onButtonHoverBot('Schedule Demo')"
              (mouseleave)="onButtonLeaveBot('Schedule Demo')"
              [class.clicked]="activeButton === 'Schedule Demo'"
              [class.hovered]="hoveredButton === 'Schedule Demo'"
            >
              Schedule Demo
            </button>
  
            <!-- "Our Procedures" button -->
          </div>
        </div>
      </div>
  
      <!-- when click on load more -->
      <div>
        <div class="chat-message-type2">Healthcare - Features</div>
  
        <div class="chat-message-left-type2">
          Below are the <b>features</b> of AI Powered Healthcare Assistant
        </div>
        <div class="chat-message-left-type2">
          <div>
            <div class="healtcare-feature-box">
              <div>
                <div class="healtcare-feature-box-circle">
                  <img src="../../assets/images/botv2/eye.png" alt="" />
                </div>
              </div>
              <div class="healtcare-feature-box-heading">
                Disease Related Information
              </div>
  
              <div
                *ngIf="features_box_value !== 'Disease Related Information'"
                class="healtcare-feature-box-plus-miuus"
                (click)="handleFeatureBox('Disease Related Information')"
              >
                <div>+</div>
              </div>
              <div
                *ngIf="features_box_value === 'Disease Related Information'"
                class="healtcare-feature-box-minus"
                (click)="handleFeatureBox('')"
              >
                <div>-</div>
              </div>
            </div>
            <div
              *ngIf="features_box_value === 'Disease Related Information'"
              class="healtcare-feature-box-sub-heading"
            >
              TINA can collect leads data and put it in a central dashboard. In
              future she will be able to collect leads from social media as well.
            </div>
            <hr
              style="
                border: 1px solid #efefef;
                margin: 0;
                margin-left: -7px;
                margin-top: 3px;
                margin-bottom: 3px;
              "
            />
          </div>
  
          <div>
            <div class="healtcare-feature-box">
              <div>
                <div class="healtcare-feature-box-circle">
                  <img src="../../assets/images/botv2/calender.png" alt="" />
                </div>
              </div>
              <div class="healtcare-feature-box-heading">Manage Appointments</div>
  
              <div
                *ngIf="features_box_value !== 'Manage Appointments'"
                class="healtcare-feature-box-plus-miuus"
                (click)="handleFeatureBox('Manage Appointments')"
              >
                <div>+</div>
              </div>
              <div
                *ngIf="features_box_value === 'Manage Appointments'"
                class="healtcare-feature-box-minus"
                (click)="handleFeatureBox('')"
              >
                <div>-</div>
              </div>
            </div>
            <div
              *ngIf="features_box_value === 'Manage Appointments'"
              class="healtcare-feature-box-sub-heading"
            >
              TINA can collect leads data and put it in a central dashboard. In
              future she will be able to collect leads from social media as well.
            </div>
            <hr
              style="
                border: 1px solid #efefef;
                margin: 0;
                margin-left: -7px;
                margin-top: 3px;
                margin-bottom: 3px;
              "
            />
          </div>
  
          <div>
            <div class="healtcare-feature-box">
              <div>
                <div class="healtcare-feature-box-circle">
                  <img src="../../assets/images/botv2/chat.png" alt="" />
                </div>
              </div>
              <div class="healtcare-feature-box-heading">Multilingual</div>
  
              <div
                *ngIf="features_box_value !== 'Multilingual'"
                class="healtcare-feature-box-plus-miuus"
                (click)="handleFeatureBox('Multilingual')"
              >
                <div>+</div>
              </div>
              <div
                *ngIf="features_box_value === 'Multilingual'"
                class="healtcare-feature-box-minus"
                (click)="handleFeatureBox('')"
              >
                <div>-</div>
              </div>
            </div>
            <div
              *ngIf="features_box_value === 'Multilingual'"
              class="healtcare-feature-box-sub-heading"
            >
              TINA can collect leads data and put it in a central dashboard. In
              future she will be able to collect leads from social media as well.
            </div>
            <hr
              style="
                border: 1px solid #efefef;
                margin: 0;
                margin-left: -7px;
                margin-top: 3px;
                margin-bottom: 3px;
              "
            />
          </div>
  
          <div>
            <div class="healtcare-feature-box">
              <div>
                <div class="healtcare-feature-box-circle">
                  <img
                    src="../../assets/images/botv2/handshake.png"
                    style="height: 11px"
                    alt=""
                  />
                </div>
              </div>
              <div class="healtcare-feature-box-heading">
                Pre & Post Op support
              </div>
  
              <div
                *ngIf="features_box_value !== 'Pre & Post Op support'"
                class="healtcare-feature-box-plus-miuus"
                (click)="handleFeatureBox('Pre & Post Op support')"
              >
                <div>+</div>
              </div>
              <div
                *ngIf="features_box_value === 'Pre & Post Op support'"
                class="healtcare-feature-box-minus"
                (click)="handleFeatureBox('')"
              >
                <div>-</div>
              </div>
            </div>
            <div
              *ngIf="features_box_value === 'Pre & Post Op support'"
              class="healtcare-feature-box-sub-heading"
            >
              TINA can collect leads data and put it in a central dashboard. In
              future she will be able to collect leads from social media as well.
            </div>
            <hr
              style="
                border: 1px solid #efefef;
                margin: 0;
                margin-left: -7px;
                margin-top: 3px;
                margin-bottom: 3px;
              "
            />
          </div>
  
          <div>
            <div class="healtcare-feature-box">
              <div>
                <div class="healtcare-feature-box-circle">
                  <img
                    src="../../assets/images/botv2/mobile.png"
                    style="height: 11px"
                    alt=""
                  />
                </div>
              </div>
              <div class="healtcare-feature-box-heading">Payment Integration</div>
  
              <div
                *ngIf="features_box_value !== 'Payment Integration'"
                class="healtcare-feature-box-plus-miuus"
                (click)="handleFeatureBox('Payment Integration')"
              >
                <div>+</div>
              </div>
              <div
                *ngIf="features_box_value === 'Payment Integration'"
                class="healtcare-feature-box-minus"
                (click)="handleFeatureBox('')"
              >
                <div>-</div>
              </div>
            </div>
            <div
              *ngIf="features_box_value === 'Payment Integration'"
              class="healtcare-feature-box-sub-heading"
            >
              TINA can collect leads data and put it in a central dashboard. In
              future she will be able to collect leads from social media as well.
            </div>
            <hr
              style="
                border: 1px solid #efefef;
                margin: 0;
                margin-left: -7px;
                margin-top: 3px;
                margin-bottom: 3px;
              "
            />
          </div>
  
          <div>
            <div class="healtcare-feature-box">
              <div>
                <div class="healtcare-feature-box-circle">
                  <img
                    src="../../assets/images/botv2/whatsapp.png"
                    style="height: 12px"
                    alt=""
                  />
                </div>
              </div>
              <div class="healtcare-feature-box-heading">Whatsapp Assistant</div>
  
              <div
                *ngIf="features_box_value !== 'Whatsapp Assistant'"
                class="healtcare-feature-box-plus-miuus"
                (click)="handleFeatureBox('Whatsapp Assistant')"
              >
                <div>+</div>
              </div>
              <div
                *ngIf="features_box_value === 'Whatsapp Assistant'"
                class="healtcare-feature-box-minus"
                (click)="handleFeatureBox('')"
              >
                <div>-</div>
              </div>
            </div>
            <div
              *ngIf="features_box_value === 'Whatsapp Assistant'"
              class="healtcare-feature-box-sub-heading"
            >
              TINA can collect leads data and put it in a central dashboard. In
              future she will be able to collect leads from social media as well.
            </div>
            <hr
              style="
                border: 1px solid #efefef;
                margin: 0;
                margin-left: -7px;
                margin-top: 3px;
                margin-bottom: 3px;
              "
            />
          </div>
  
          <div>
            <div class="healtcare-feature-box">
              <div>
                <div class="healtcare-feature-box-circle">
                  <img
                    src="../../assets/images/botv2/lead.png"
                    style="height: 11px"
                    alt=""
                  />
                </div>
              </div>
              <div class="healtcare-feature-box-heading">Lead Management</div>
  
              <div
                *ngIf="features_box_value !== 'Lead Management'"
                class="healtcare-feature-box-plus-miuus"
                (click)="handleFeatureBox('Lead Management')"
              >
                <div>+</div>
              </div>
              <div
                *ngIf="features_box_value === 'Lead Management'"
                class="healtcare-feature-box-minus"
                (click)="handleFeatureBox('')"
              >
                <div>-</div>
              </div>
            </div>
            <div
              *ngIf="features_box_value === 'Lead Management'"
              class="healtcare-feature-box-sub-heading"
            >
              TINA can collect leads data and put it in a central dashboard. In
              future she will be able to collect leads from social media as well.
            </div>
          </div>
        </div>
      </div>
  
      <!-- when click on schedule demo -->
      <div>
        <div class="chat-message-type2">Schedule Demo</div>
        <div class="chat-message-left-type2">
          Please Confirm your email address :
          <br />
          <b>akshitagarg555@gmail.com</b>
        </div>
  
        <div style="margin: 16px 0 3px">
          <div>
            <!-- "Confirm" button -->
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('comfirm')"
              (mouseenter)="onButtonHoverBot('comfirm')"
              (mouseleave)="onButtonLeaveBot('comfirm')"
              [class.clicked]="activeButton === 'comfirm'"
              [class.hovered]="hoveredButton === 'comfirm'"
            >
              Confirm
            </button>
            <!-- edit button -->
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Edit')"
              (mouseenter)="onButtonHoverBot('Edit')"
              (mouseleave)="onButtonLeaveBot('Edit')"
              [class.clicked]="activeButton === 'Edit'"
              [class.hovered]="hoveredButton === 'Edit'"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
  
      <!-- when click on confirm -->
      <div>
        <div class="chat-message-type2">Confirm</div>
  
        <div class="chat-message-left-type2">
          Please Share your Contact number:
        </div>
  
        <div class="chat-contanct-number3">
          <div class="chat-contanct-number">
            <div class="chat-contanct-number1">
              <div>
                <select name="phone" id="phone">
                  <option value="+91">+91</option>
                  <option value="+91">+45</option>
                  <option value="+91">+10</option>
                  <option value="+91">+55</option>
                </select>
              </div>
              <!-- <div>
                <img src="../../assets/images/botv2/down_arrow_2.png" alt="" />
              </div> -->
            </div>
            <div class="chat-contanct-number2">9876534567</div>
          </div>
  
          <div style="margin-top: 23px">
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Confirm')"
              (mouseenter)="onButtonHoverBot('Confirm')"
              (mouseleave)="onButtonLeaveBot('Confirm')"
              [class.clicked]="activeButton === 'Confirm'"
              [class.hovered]="hoveredButton === 'Confirm'"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
  
      <!-- when click on confirm on phone number -->
      <div>
        <div class="chat-message-type2">Confirm</div>
        <div class="chat-message-left-type2">Thanks for Sharing the details.</div>
        <div class="chat-message-left-type2">
          Select the sector for which you want to schedule demo
        </div>
  
        <div>
          <div class="grid-container">
            <div class="grid-item">
              <div style="height: 25px">
                <img src="../../assets/images/botv2/breif-case.png" alt="" />
              </div>
              <div>Healthcare</div>
            </div>
            <div class="grid-item">
              <div style="height: 25px">
                <img src="../../assets/images/botv2/education.png" alt="" />
              </div>
              <div>Education</div>
            </div>
            <div class="grid-item">
              <div style="height: 25px">
                <img src="../../assets/images/botv2/home.png" alt="" />
              </div>
              <div>BFSI</div>
            </div>
            <div class="grid-item">
              <div style="height: 25px">
                <img src="../../assets/images/botv2/realstate.png" alt="" />
              </div>
              <div>Real Estate</div>
            </div>
            <div class="grid-item">
              <div style="height: 25px">
                <img src="../../assets/images/botv2/hospitality.png" alt="" />
              </div>
              <div>Hospitality</div>
            </div>
            <div class="grid-item">
              <div style="height: 25px">
                <img src="../../assets/images/botv2/customersupport.png" alt="" />
              </div>
              <div>Customer Support</div>
            </div>
            <div class="grid-item">
              <div style="height: 25px">
                <img src="../../assets/images/botv2/ecommerce.png" alt="" />
              </div>
              <div>E-commerce</div>
            </div>
            <div class="grid-item">
              <div style="height: 25px">
                <img src="../../assets/images/botv2/enterprises.png" alt="" />
              </div>
              <div>Enterprise</div>
            </div>
            <div class="grid-item">
              <div style="height: 25px">
                <img src="../../assets/images/botv2/telicome.png" alt="" />
              </div>
              <div>Telecom</div>
            </div>
          </div>
  
          <div style="margin: 16px 0 3px">
            <div>
              <!-- "About Us" button -->
              <button
                class="bot-v2-button"
                (click)="onButtonClickBot('Load More')"
                (mouseenter)="onButtonHoverBot('Load More')"
                (mouseleave)="onButtonLeaveBot('Load More')"
                [class.clicked]="activeButton === 'Load More'"
                [class.hovered]="hoveredButton === 'Load More'"
              >
                Load More
              </button>
  
              <button
                class="bot-v2-button"
                (click)="onButtonClickBot('Solution')"
                (mouseenter)="onButtonHoverBot('Solution')"
                (mouseleave)="onButtonLeaveBot('Solution')"
                [class.clicked]="activeButton === 'Solution'"
                [class.hovered]="hoveredButton === 'Solution'"
              >
                By Solution
              </button>
  
              <!-- "Contact Us" button -->
  
              <!-- "Services" button -->
              <button
                class="bot-v2-button"
                (click)="onButtonClickBot('Main Menu')"
                (mouseenter)="onButtonHoverBot('Main Menu')"
                (mouseleave)="onButtonLeaveBot('Main Menu')"
                [class.clicked]="activeButton === 'Main Menu'"
                [class.hovered]="hoveredButton === 'Main Menu'"
              >
                Main Menu
              </button>
  
              <!-- "Our Procedures" button -->
            </div>
          </div>
        </div>
      </div>
  
      <!-- when click on education -->
      <div>
        <div class="chat-message-type2">Education</div>
        <div class="chat-message-left-type2">
          Select further categories in education sector
        </div>
        <div class="chat-message-left-type2">
          <div style="cursor: pointer">University Assistant</div>
          <hr style="margin: 3px 0px; padding: 0px; width: 80%" />
          <div style="cursor: pointer">School Assistant</div>
          <hr style="margin: 3px 0px; padding: 0px; width: 80%" />
          <div style="cursor: pointer">Pre-School Assistant</div>
          <hr style="margin: 3px 0px; padding: 0px; width: 80%" />
        </div>
      </div>
  
      <!-- when click on University assistant and book schedule for demo -->
      <div>
        <div class="chat-message-type2">University Assistant</div>
        <div class="chat-message-left-type2">
          Please Choose the date from the calendar below:
        </div>
  
        <!-- available time for use -->
        <div style="margin: 16px 0 3px">
          <div>
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('5-12-2023')"
              (mouseenter)="onButtonHoverBot('5-12-2023')"
              (mouseleave)="onButtonLeaveBot('5-12-2023')"
              [class.clicked]="activeButton === '5-12-2023'"
              [class.hovered]="hoveredButton === '5-12-2023'"
            >
              5-12-2023
            </button>
  
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('6-12-2023')"
              (mouseenter)="onButtonHoverBot('6-12-2023')"
              (mouseleave)="onButtonLeaveBot('6-12-2023')"
              [class.clicked]="activeButton === '6-12-2023'"
              [class.hovered]="hoveredButton === '6-12-2023'"
            >
              6-12-2023
            </button>
  
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('7-12-2023')"
              (mouseenter)="onButtonHoverBot('7-12-2023')"
              (mouseleave)="onButtonLeaveBot('7-12-2023')"
              [class.clicked]="activeButton === '7-12-2023'"
              [class.hovered]="hoveredButton === '7-12-2023'"
            >
              7-12-2023
            </button>
  
            <!-- "Our Procedures" button -->
          </div>
        </div>
  
        <!-- if user select a date -->
        <div class="chat-message-type2">11-12-2023</div>
  
        <div class="chat-message-left-type2">
          Please Choose the Time slot from the calendar below:
        </div>
  
        <!-- two buttton name moring and eveening -->
        <div style="margin: 16px 0 3px">
          <div>
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Moring')"
              (mouseenter)="onButtonHoverBot('Moring')"
              (mouseleave)="onButtonLeaveBot('Moring')"
              [class.clicked]="activeButton === 'Moring'"
              [class.hovered]="hoveredButton === 'Moring'"
            >
              Moring
            </button>
  
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('Evening')"
              (mouseenter)="onButtonHoverBot('Evening')"
              (mouseleave)="onButtonLeaveBot('Evening')"
              [class.clicked]="activeButton === 'Evening'"
              [class.hovered]="hoveredButton === 'Evening'"
            >
              Evening
            </button>
  
            <!-- "Our Procedures" button -->
          </div>
        </div>
  
        <div class="chat-message-type2">Morning</div>
        <!-- available time for use moring or evening -->
  
        <div style="margin: 56px 0 3px">
          <div>
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('2:00PM')"
              (mouseenter)="onButtonHoverBot('2:00PM')"
              (mouseleave)="onButtonLeaveBot('2:00PM')"
              [class.clicked]="activeButton === '2:00PM'"
              [class.hovered]="hoveredButton === '2:00PM'"
            >
              2:00PM
            </button>
  
            <button
              class="bot-v2-button"
              (click)="onButtonClickBot('6:00PM')"
              (mouseenter)="onButtonHoverBot('6:00PM')"
              (mouseleave)="onButtonLeaveBot('6:00PM')"
              [class.clicked]="activeButton === '6:00PM'"
              [class.hovered]="hoveredButton === '6:00PM'"
            >
              6:00PM
            </button>
  
            <!-- "Our Procedures" button -->
          </div>
        </div>
      </div>
  
      <!-- date and time picker -->
      <div>
        <div class="chat-message-type2">2:00PM</div>
      </div>
      <div style="position: relative;">
        <div class="date-picker-cal-time">
          <input
            [owlDateTime]="dt1"
            [owlDateTimeTrigger]="dt1"
            placeholder="Select Date and Time"
            [(ngModel)]="selectedDateTime"
          />
          <owl-date-time #dt1></owl-date-time>
    
          <div style="position: absolute; top: 60px; left: 20px">
            <img src="../../assets/images/botv2/calender-time.png" alt="" />
          </div>
          <div style="position: absolute; top: 63px; right: 75px; height: 23px">
            <img src="../../assets/images/botv2/dropdown.png" alt="" />
          </div>
        </div>
      </div>
    
  
      <div *ngIf="selectedDateTime">
        <div class="chat-message-type2">
          {{ selectedDateTime | date : "EEE MMM dd yyyy HH:mm" }}
        </div>
      </div>
  
      <!-- when use click on confim -->
      <div>
        <div class="chat-message-type2">Confirm</div>
        <div class="chat-message-left-type2">
          <b>Congratulations!</b> Your demo has been scheduled. You will receive a
          confirmation SMS.
        </div>
        <div class="chat-message-left-type2" style="line-height: 17px">
          <div>
            <b>Demo Details :</b>
          </div>
          <hr style="margin: 4px 0px; padding: 0px; width: 80%" />
  
          <div>
            <b>Category :</b> University Assistant
            <br />
            <b>Time:</b> 2:00PM or 6:00PM
            <br />
            <b>Date:</b> 2-11-2023 or 4-11-2023
          </div>
  
          <hr style="margin: 4px 0px; padding: 0px; width: 80%" />
          <div class="queries-information">
            For any queries or support please contact at: <b>91 92892 31604 </b>
          </div>
        </div>
      </div>
  
      <!-- when use click on contact us -->
      <div>
        <div class="chat-message-type2">Contact Us</div>
        <div class="chat-message-left-type2">
          Below are the Contact details of Gravitas AI:
        </div>
  
        <div class="chat-message-left-type2">
          <div>
            <div>
              <div style="display: flex; gap: 45px">
                <div><b>Email</b>: hello@gravitas.ai</div>
                <div>
                  <img src="../../assets/images/botv2/envelop.png" alt="" />
                </div>
              </div>
            </div>
  
            <hr style="margin: 3px 0px; padding: 0px; width: 80%" />
  
            <div
              style="
                display: flex;
                /* justify-content: space-between; */
                gap: 15px;
              "
            >
              <div><b>Contact:-</b></div>
              <div>
                <div style="display: flex; gap: 30px">
                  <div>+91 774228250</div>
                  <div>
                    <img src="../../assets/images/botv2/phone.png" alt="" />
                  </div>
                </div>
                <div style="display: flex; gap: 30px">
                  <div>+91 774228250</div>
                  <div>
                    <img src="../../assets/images/botv2/phone.png" alt="" />
                  </div>
                </div>
                <div style="display: flex; gap: 30px">
                  <div>+91 774228250</div>
                  <div>
                    <img src="../../assets/images/botv2/phone.png" alt="" />
                  </div>
                </div>
                <div style="display: flex; gap: 30px">
                  <div>+91 774228250</div>
                  <div>
                    <img src="../../assets/images/botv2/phone.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- when click on healthcare benifits -->
      <div>
        <div class="chat-message-type2">Healthcare - Benefits</div>
  
        <div class="chat-message-left-type2">
          Below are the benefits of AI Powered <b>Healthcare Assistant</b>
        </div>
        <div class="chat-message-left-type2">
          <!-- create a list -->
          <ul style="margin-left: -23px; margin-bottom: -4px">
            <li>
              Be the <b>1st mover</b> to use Artificial intelligence in Healthcare
              sector
            </li>
            <li>Engage your <b>patients 24/7</b></li>
          </ul>
        </div>
      </div>
  
      <!-- when click on okay thanks -->
      <div>
        <div class="chat-message-type2">Okay, Thanks</div>
        <div class="chat-message-left-type2">
          That’s great, Happy to be of help !
        </div>
        <div class="chat-message-left-type2">
          <b>On a scale of 1-5, how would you rate the overall experience ?</b>
          <br />
          1 being the lowest , 5 being the highest
        </div>
      </div>
      <!-- star rating -->
      <div style="display: inline-block">
        <div class="rate">
          <input type="radio" id="star5" name="rate" value="5" />
          <label for="star5" title="text">5 stars</label>
          <input type="radio" id="star4" name="rate" value="4" />
          <label for="star4" title="text">4 stars</label>
          <input type="radio" id="star3" name="rate" value="3" />
          <label for="star3" title="text">3 stars</label>
          <input type="radio" id="star2" name="rate" value="2" />
          <label for="star2" title="text">2 stars</label>
          <input type="radio" id="star1" name="rate" value="1" />
          <label for="star1" title="text">1 star</label>
        </div>
      </div>
  
      <!-- emoji rating section -->
      <div style="display: flex; gap: 15px">
        <div style="display: flex; flex-direction: column; cursor: pointer">
          <img
            src="../../assets/images/botv2/poor.png"
            alt=""
            style="object-fit: scale-down; height: 25px"
          />
          <p style="color: #d63232">very poor</p>
        </div>
        <div style="display: flex; flex-direction: column; cursor: pointer">
          <img
            src="../../assets/images/botv2/mid-poor.png"
            alt=""
            style="object-fit: scale-down; height: 25px"
          />
          <p style="color: #ea991e">poor</p>
        </div>
        <div style="display: flex; flex-direction: column; cursor: pointer">
          <img
            src="../../assets/images/botv2/medium.png"
            alt=""
            style="object-fit: scale-down; height: 25px"
          />
          <p style="color: #ddca20">Medium</p>
        </div>
        <div style="display: flex; flex-direction: column; cursor: pointer">
          <img
            src="../../assets/images/botv2/good.png"
            alt=""
            style="object-fit: scale-down; height: 25px"
          />
          <p style="color: #9dc91d">Good</p>
        </div>
        <div style="display: flex; flex-direction: column; cursor: pointer">
          <img
            src="../../assets/images/botv2/excelent.png"
            alt=""
            style="object-fit: scale-down; height: 25px"
          />
          <p style="color: #27b02e">Excellent</p>
        </div>
      </div>
  
      <div>
        <div class="chat-message-left-type2">
          <b>Thanks for rating 5!</b>
          <p style="margin-top: 3px">"Your feedback matters. Please review."</p>
  
          <div class="google-feedback">
            <input
              type="text"
              placeholder="Type your Message here"
              style="border: none"
            />
            <!-- <div class="google-feedback-img">
              <img src="../../assets/images/botv2/google.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>