<div class="header-container">
  <div style="flex: 2; display: flex">
    <div class="head-logo">
      <img src="../assets/images/botv2/Tina.png" alt="" style="height: 60px" />
    </div>

    <div class="bot-name">

      TINA
      <div class="sub-heading">TM</div>
    </div>
  </div>
  <div style="flex: 1; z-index: 10;">
    <div class="select-lang-box">
      <div>
        <img src="../../../assets/images/botv2/lang_icon.png" class="select-image" alt="">
      </div>

      <!-- <div>
        <select class="select-button"
        [(ngModel)]="BotDefaultLanguage"
        (change)="handleChange()"
        *ngIf="translationActive">
          <option
          class="option"
          *ngFor="let language of botLanguages" [value]="language.value">{{language.name}}</option>
        </select>
      </div> -->

      <div class="custom-select"
      appClickOutside (clickOutside)="handleClickOutside()"
      >
        <div class="select-button" (click)="toggleDropdown()">
          {{ selectedLanguage.name }}
        </div>
        <div class="dropdown" *ngIf="dropdownOpen"
        
        >
          <div
          *ngFor="let language of botLanguages" (click)="selectLanguage(language)">
           <div
           class="option-box option"
           [ngClass]="{'active': selectedLanguage.value === language.value}"
           >
            <label
            class="option-label"
            >{{ language.name }}</label>
            <input type="radio" name="language" [value]="language.value" [checked]="language.value === BotDefaultLanguage" />
          </div>
          </div>
        </div>
      </div>
      


      

      <!-- <div *ngIf="!translationActive" class="defaultLang">
          English
      </div> -->
    </div>
  </div>
  <div
  class="frame-close-button"
  (click)="closeFrame()"
  >
    x
  </div>
</div>