<div class="chatbot-container">
  <div class="container-top">
    <div>
      <div class="chat-top">
        <img src="../assets/images/botv2/gravitas_ai_logo.png" alt="admin avatar" />
      </div>
    </div>

    <div>
      <div class="bot-heading">
        <p>I am TINA !</p>
      </div>
      <div class="bot-subheading">Your AI-Powered Tireless Teammate!</div>
    </div>
  </div>

  <div class="container-bottom">
    <div class="mask-group">
      <div>
        <img src="../assets/images/botv2/Mask_group.png" class="mask-group-image" alt="" />
      </div>
    </div>

    <div class="about-container">

      <div class="about-tina">
        <div>
          <p class="tina_description">
            Hello, I am TINA, Your personal AI Powered Assistant. I am here
            to make your journey a whole lot easier. I can help help you
            with any type of queries. Welcome to our website.
          </p>
        </div>
      </div>

      <div class="bot-terms">
        <label>
          <input type="checkbox" [(ngModel)]="acceptTerms" class="checkbox-input" />I accept the
          <label class="term-condition"> Terms and conditions</label>
        </label>
      </div>

      <div class="get-start">
        <button class="get-start-button" (click)="onGetStartedClick()">Get Started</button>
      </div>
    </div>
  </div>
</div>