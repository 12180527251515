import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-front-screen',
  templateUrl: './front-screen.component.html',
  styleUrls: ['./front-screen.component.scss']
})
export class FrontScreenComponent {

  acceptTerms: boolean = false;
  showChatBotScreenValue: boolean = false;
  @Output() showChatBotScreenValueChange = new EventEmitter<boolean>();

  onGetStartedClick() {
    if (this.acceptTerms) {
      this.showChatBotScreenValue = true;
    }
    this.showChatBotScreenValueChange.emit(this.showChatBotScreenValue);
  }

}
