import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-time-widget',
  templateUrl: './time-widget.component.html',
  styleUrls: ['./time-widget.component.scss']
})
export class TimeWidgetComponent {
  @Input() dates: { text: string, value: number }[] = [];
  @Output() handleSubmitResponse = new EventEmitter();

  onDateSelect(data:any){
    this.handleSubmitResponse.emit(data);
  }
}
