<div>
    <div style="
              border-radius: 15px;
    margin: 8px;
    margin-top: 16px;
    padding: 0px 2px;
    border-top-left-radius: 0px;
    overflow: hidden;
            "
            
            class="intro_msg"
            >
    <div  style="display: flex">
        <div>
            <img src="../assets/images/botv2/tina-about.png" alt="" style="    height: 130px;
                  margin-top: 11px;" />
        </div>
        <div style=" margin: 12px 0px;
        padding-top: 4px;
        margin-right: 9px;
        margin-left: -2px;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 22px;
        margin-right: 20px;
        ">
            <div style="
                    font-family: Montserrat, sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: left;
                    /* color: #525252; */
                  "
                  class="intro_msg_text"

                  [innerHTML]="message | safeHTML"
                  >
               
            </div>
           

            <div *ngIf="false"  class="video_content2">
                Click on the <a href="youtube.com">video</a> to know more
                about our company
            </div>
        </div>
    </div>

    <div *ngIf="false" id="video-container" style="width: 95%">
        <img src="../assets/images/botv2/into-image.png" alt="Video Thumbnail" id="thumbnail" (click)="playVideo()" />
        <div class="black-mask">

        </div>
        <button id="play-button" (click)="playVideo()">    <img src="../../../../assets/images/botv2/triangle.png" alt="play button" class="triangle_prop"/></button>
        <video controls #videoElement style="height: 110px; background-color: black;">
            <source src="../assets/videos/tina.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>
</div>
</div>