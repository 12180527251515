<div
          class="bot-middle-screen"
        >
          <div class="vertical-dash-line">
            <img src="../assets/images/botv2/dash-line.png" alt="" />
          </div>
          <div>
            <div
              class="menu-list"
              (click)="onButtonClickMenuBot('videocall')"
              (mouseenter)="onButtonHoverMenuBot('videocall')"
              (mouseleave)="onButtonLeaveMenuBot('videocall')"
              [class.clickedMenu]="activeMenuButton == 'videocall'"
              [class.hoveredMenu]="hoveredMenuButton == 'videocall'"
            >
              <div
               class="menu-box"
              >
                <div
                 class="menu-item"
                >
                  <img
                    src="../assets/images/botv2/material-symbols_video-call.png"
                    alt=""
                    style="height: 16px; width: 20px;"
                  />
                </div>
              </div>
              <div>
                <div class="menu-bot-heading">Our Services</div>
                <div class="menu-bot-subheading">
                  Check out our Industry based Solution
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="menu-list"
              (click)="onButtonClickMenuBot('calendar')"
              (mouseenter)="onButtonHoverMenuBot('calendar')"
              (mouseleave)="onButtonLeaveMenuBot('calendar')"
              [class.clickedMenu]="activeMenuButton === 'calendar'"
              [class.hoveredMenu]="hoveredMenuButton === 'calendar'"
            >
              <div
                class="menu-box"
                
              >
                <div
                  class="menu-item"
                >
                  <div class="down_arrow">
                    <img src="../assets/images/botv2/down_arrow.png" alt="" />
                  </div>
                  <img 
                  style="height: 18px;
                  width: 18px;"
                  src="../assets/images/botv2/bxs_calendar.png" alt="" />
                </div>
              </div>
              <div>
                <div class="menu-bot-heading">TINA: Chatbot</div>
                <div class="menu-bot-subheading">
                  Check out our Industry based Solution
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="menu-list"
              (click)="onButtonClickMenuBot('whatsapp')"
              (mouseenter)="onButtonHoverMenuBot('whatsapp')"
              (mouseleave)="onButtonLeaveMenuBot('whatsapp')"
              [class.clickedMenu]="activeMenuButton === 'whatsapp'"
              [class.hoveredMenu]="hoveredMenuButton === 'whatsapp'"
            >
              <div
                class="menu-box"
              >
                <div
                 class="menu-item"
                >
                  <div class="down_arrow">
                    <img src="../assets/images/botv2/down_arrow.png" alt="" />
                  </div>
                  <img
                  style="height: 18px;
                  width: 18px;"
                    src="../assets/images/botv2/formkit_whatsapp.png"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div class="menu-bot-heading">Whatsapp Assistant</div>
                <div class="menu-bot-subheading">
                  Connect with us on WhatApp
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="menu-list"
              (click)="onButtonClickMenuBot('book')"
              (mouseenter)="onButtonHoverMenuBot('book')"
              (mouseleave)="onButtonLeaveMenuBot('book')"
              [class.clickedMenu]="activeMenuButton === 'book'"
              [class.hoveredMenu]="hoveredMenuButton === 'book'"
            >
              <div
                class="menu-box"
              >
                <div
                 class="menu-item"
                >
                  <div class="down_arrow">
                    <img src="../assets/images/botv2/down_arrow.png" alt="" />
                  </div>
                  <img
                  style="height: 18px;
                  width: 18px;"
                    src="../assets/images/botv2/ri_health-book-fill.png"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div class="menu-bot-heading">Smart EMR</div>
                <div class="menu-bot-subheading">
                  Go through our detailed health packages
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="menu-list"
              (click)="onButtonClickMenuBot('faq')"
              (mouseenter)="onButtonHoverMenuBot('faq')"
              (mouseleave)="onButtonLeaveMenuBot('faq')"
              [class.clickedMenu]="activeMenuButton === 'faq'"
              [class.hoveredMenu]="hoveredMenuButton === 'faq'"
            >
              <div
               class="menu-box"
              >
                <div
                 class="menu-item"
                >
                  <div class="down_arrow">
                    <img src="../assets/images/botv2/down_arrow.png" alt="" />
                  </div>
                  <img 
                  style="    height: 18px;
                  width: 18px;"
                  src="../assets/images/botv2/mdi_faq.png" alt="" />
                </div>
              </div>
              <div>
                <div class="menu-bot-heading">FAQS</div>
                <div class="menu-bot-subheading">
                  Get answers for all your queries
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="menu-list"
              (click)="onButtonClickMenuBot('contact')"
              (mouseenter)="onButtonHoverMenuBot('contact')"
              (mouseleave)="onButtonLeaveMenuBot('contact')"
              [class.clickedMenu]="activeMenuButton === 'contact'"
              [class.hoveredMenu]="hoveredMenuButton === 'contact'"
            >
              <div
                class="menu-box"
              >
                <div
                 class="menu-item"
                >
                  <div class="down_arrow">
                    <img src="../assets/images/botv2/down_arrow.png" alt="" />
                  </div>
                  <img 
                  style="    height: 18px;
                  width: 18px;"
                  src="../assets/images/botv2/bxs_contact.png" alt="" />
                </div>
              </div>
              <div>
                <div class="menu-bot-heading">Get Demo</div>
                <div class="menu-bot-subheading"
                style="font-size: 10.5px;"
                >
                  Emergency health support and other services.
                </div>
              </div>
            </div>
          </div>
        </div>