import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppServiceService } from 'src/app/services/app-service.service';
import { json } from 'stream/consumers';

@Component({
  selector: 'app-webcam-capture',
  templateUrl: './webcam-capture.component.html',
  styleUrls: ['./webcam-capture.component.scss']
})
export class WebcamCaptureComponent implements AfterViewInit {

  @ViewChild("canvas", { static: false }) public canvas!: ElementRef;

  @Output() imageUploadedToServer: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("video", { static: false }) public video!: ElementRef;

  @Output() updateStream: EventEmitter<any> = new EventEmitter<any>();

  @Output() handleAddImageToChat: EventEmitter<any> = new EventEmitter();
  @Input() isTyping: any;

  @Output() startTyping: EventEmitter<any> = new EventEmitter();

  @Input() captureForEyeSkinDetection: any;

  @Output() captureForEyeSkinDetectionResponse: EventEmitter<any> = new EventEmitter();



  @Input() canChangeStyle: any;
  @Input() clientConfigurationData: any;
  styleSheet: CSSStyleSheet | undefined;

  DATA: any;

  isLoading = false;

  stream: any;

  width = 180;
  height = 180;
  captureSource = "";

  constructor(private http: HttpClient, private appService: AppServiceService) { }

  ngAfterViewInit() {
    this.fnInitCamera();

    if (this.canChangeStyle) {
      this.DATA = this.clientConfigurationData;
      this.fnSetStyles();
    } else {
      this.fnSetDefaultStyles();
    }
  }


  fnSetStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`
    .upload-button5 {
      background-color: ${this.DATA.PrimaryColor} !important;
    }
  `);
  }


  fnSetDefaultStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`
    .upload-button5 {
      background-color: #1a476c !important;
    }
  `);
  }



  fnInitCamera = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } })
        .then(stream => {
          this.stream = stream;
          this.updateStream.emit(stream);
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          console.log(stream)
        })
        .catch(error => {
          console.error('Error accessing camera:', error);
        });
    } else {
      console.error('getUserMedia is not supported');
    }
  }

  capture() {
    const context = this.canvas.nativeElement.getContext("2d");
    context.drawImage(this.video.nativeElement, 0, 0, this.width, this.height);

    this.captureSource = this.canvas.nativeElement.toDataURL("image/png");

  }

  base64ToBlob(base64: string, contentType: string = '', sliceSize: number = 512): Blob {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  uploadImage() {
    this.isLoading = true;

    const blob = this.base64ToBlob(this.captureSource, 'image/png');
    const formData = new FormData();
    formData.append("file", blob, 'captured-image.png');

    const folder = "public/tina_chabot_files";
    formData.append("folders", folder);
    formData.append("bucket", "emruploads");

    if (!this.captureForEyeSkinDetection) {
      this.appService.uploadPatientProfile(formData).subscribe(
        (response) => {
          this.handleAddImageToChat.emit(response.url);
          this.startTyping.emit();

        },
        (error) => {
          console.error('Error uploading image:', error);
        }
      );

      this.canvas.nativeElement.toBlob((blob: Blob) => {
        var xhr = new XMLHttpRequest();
        xhr.addEventListener("readystatechange", () => {
          if (xhr.readyState === 4) {
            console.log(xhr.responseText);

            this.imageUploadedToServer.emit({ responseText: xhr.responseText, imageBlob: blob });
            this.isLoading = false;
          }
        });

        xhr.open("POST", "https://uyvp56ma6f.execute-api.us-east-1.amazonaws.com/prod/face-match");
        xhr.send(blob);
      });
    } else {
      const payload = {
        image: this.captureSource,
        sessionId: "userSession_" + Date.now()
      };

      fetch('https://e6oqoub23p3uln25gfrqouik4i0yxvln.lambda-url.us-east-1.on.aws', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then(response => response.json())
        .then(data => {
          const dataToPass = {
            base64Image: this.captureSource,
            skin_tone_result: data?.skin_tone_result,
            product_recommendations: data?.product_recommendations
          }
          this.captureForEyeSkinDetectionResponse.emit(dataToPass);
          this.isLoading = false;
        })
        .catch(error => {
          console.error('Error:', error);
          this.isLoading = false;
        });
    }

    // const payload = {
    //   image: this.captureSource,
    //   sessionId: "userSession_" + Date.now()
    // };

    // fetch('https://e6oqoub23p3uln25gfrqouik4i0yxvln.lambda-url.us-east-1.on.aws', {
    //   method: 'POST',
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(payload),
    // })
    // .then(response => response.json())
    // .then(data => console.log(data))
    // .catch(error => console.error('Error:', error));



    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 2000);
  }

}
