import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppServiceService } from 'src/app/services/app-service.service';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent {
  selectedRating:number = 0;
  hoveredRating:number = 0;

  showFeedBack:boolean = false;
  feedback:string = '';
  rating:number = 0;
  @Output() handleFeedBackSubmit = new EventEmitter();
  @Input() clientConfigurationId: string = '';
  @Input() sessionAttributes: any = {};

  constructor(
    private appService: AppServiceService,
  ) { }

  submitRating(value:number){
    console.log('Rating submitted', value);
    this.showFeedBack = true;
    this.rating = value;
  }

  handleSubmitFeedBack(){

    console.log("clientConfigurationId", this.clientConfigurationId); 
    console.log("sessionAttributes", this.sessionAttributes);

    console.log('Feedback submitted', this.feedback);
    console.log('Rating submitted', this.rating);
    let data = {
      client_id: this.clientConfigurationId,
      name: this.sessionAttributes?.UserName || "",
      email: this.sessionAttributes?.EmailId || "",
      mobile: this.sessionAttributes?.PhoneNumber || "",
      source: 'tina',
      rating: this.rating,
      feedback: this.feedback,
      timezone: "Asia/Calcutta",
    }
    this.handleFeedBackSubmit.emit(true);
    this.appService.addUserFeedback(data).subscribe(
      {
        next: (response) => {
          if (response) {
            console.log('Response:', response);
          } else {
            console.log('No response due to error.');
          }
        },
        error: (error) => {

          console.error('Error in subscribe:', error);
        },
        complete: () => {
          console.log('Feedback submission completed.');
        }
      }
    )
  }

}
