<div *ngFor="let item of messages;let i = index" >
   <div *ngIf="item.widgetType == 'intro_text' && i == 0">
    <div style="
              background: #ccf3f5;
              border-radius: 15px;
              margin: 18px;
              margin-top: 35px;
              padding: 0px 2px;
              border-top-left-radius: 0px;
              overflow: hidden;
            ">
    <div  style="display: flex">
        <div>
            <img src="../assets/images/botv2/tina-about.png" alt="" style="    height: 130px;
                  margin-top: 11px;" />
        </div>
        <div style="    margin: 12px 0px;
              padding-top: 4px;
              margin-right: 9px;
              margin-left: -2px;
              margin-top: 16px;">
            <div style="
                    font-family: Montserrat, sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #525252;
                  ">
               {{item.text}}
            </div>
           

            <div *ngIf="false"  class="video_content2">
                Click on the <a href="youtube.com">video</a> to know more
                about our company
            </div>
        </div>
    </div>

    <div *ngIf="false" id="video-container" style="width: 95%">
        <img src="../assets/images/botv2/into-image.png" alt="Video Thumbnail" id="thumbnail" (click)="playVideo()" />
        <div class="black-mask">

        </div>
        <button id="play-button" (click)="playVideo()">    <img src="../../../../assets/images/botv2/triangle.png" alt="play button" class="triangle_prop"/></button>
        <video controls #videoElement style="height: 110px; background-color: black;">
            <source src="../assets/videos/tina.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>
</div>
</div>
  <!-- shoe video crousel -->
<div *ngIf="item.widgetType == 'intro_videos'" style="margin: 15px 8px 10px">
    <div class="carousel-container1">
        <div class="carousel-wrapper1" id="carouselWrapper1">
            <div class="carousel-itemm" [ngClass]="{
            'un-blur-content': currentIndex === 0,
            'blur-content': currentIndex !== 0
          }">
                <div id="video-crousel-container">
                    <div style="position: relative">
                        <img src="../assets/images/botv2/into-image.png" alt="Video Thumbnail" id="thumbnail1"
                            class="thumbnail" (click)="playVideoCrousel(1)" />

                        <div class="black-shadow2"></div>
                    </div>
                    <button id="play-button1" class="play-button" (click)="playVideoCrousel(1)">
                   
                        <img src="../../../../assets/images/botv2/triangle.png" alt="play button" class="triangle_prop"/>
                    
                    </button>
                    <video controls #videoElement1 class="video-player">
                        <source src="../assets/videos/tina.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div class="crousel-video-content">
                    <b>Company Video</b> - Gravitas AI, Designing tomorrow with
                    conversational AI
                </div>
            </div>

            <div class="carousel-itemm" [ngClass]="{
            'un-blur-content': currentIndex === 1,
            'blur-content': currentIndex !== 1
          }">
                <div id="video-crousel-container">
                    <div style="position: relative">
                        <img src="../assets/images/botv2/into-image.png" alt="Video Thumbnail" id="thumbnail2"
                            class="thumbnail" (click)="playVideoCrousel(1)" />

                        <div class="black-shadow2"></div>
                    </div>
                    <button id="play-button2" class="play-button" (click)="playVideoCrousel(2)">
                        <img src="../../../../assets/images/botv2/triangle.png" alt="play button" class="triangle_prop"/>
                    </button>
                    <video controls #videoElement2 class="video-player">
                        <source src="../assets/videos/tina.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div class="crousel-video-content">
                    <b>Company Video</b> - Gravitas AI, Designing tomorrow with
                    conversational AI
                </div>
            </div>

            <div class="carousel-itemm" [ngClass]="{
            'un-blur-content': currentIndex === 2,
            'blur-content': currentIndex !== 2
          }">
                <div id="video-crousel-container">
                    <div style="position: relative">
                        <img src="../assets/images/botv2/into-image.png" alt="Video Thumbnail" id="thumbnail3"
                            class="thumbnail" (click)="playVideoCrousel(1)" />

                        <div class="black-shadow2"></div>
                    </div>
                    <button id="play-button3" class="play-button" (click)="playVideoCrousel(3)">
                            <img src="../../../../assets/images/botv2/triangle.png" alt="play button" class="triangle_prop"/>
                    </button>
                    <video controls #videoElement3 class="video-player">
                        <source src="../assets/videos/tina.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div class="crousel-video-content">
                    <b>Company Video</b> - Gravitas AI, Designing tomorrow with
                    conversational AI
                </div>
            </div>
            <div class="carousel-itemm" [ngClass]="{
            'un-blur-content': currentIndex === 3,
            'blur-content': currentIndex !== 3
          }">
                <div id="video-crousel-container">
                    <div style="position: relative">
                        <img src="../assets/images/botv2/into-image.png" alt="Video Thumbnail" id="thumbnail4"
                            class="thumbnail" (click)="playVideoCrousel(1)" />

                        <div class="black-shadow2"></div>
                    </div>
                    <button id="play-button4" class="play-button" (click)="playVideoCrousel(2)">
                            <img src="../../../../assets/images/botv2/triangle.png" alt="play button" class="triangle_prop"/>
                    </button>
                    <video controls #videoElement4 class="video-player">
                        <source src="../assets/videos/tina.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div class="crousel-video-content">
                    <b>Company Video</b> - Gravitas AI, Designing tomorrow with
                    conversational AI
                </div>
            </div>
        </div>
    </div>

    <div class="control-buttons">
        <button class="control-button" (click)="scrollToItem(1)"
            [ngClass]="{ activeButton: currentIndex === 0 }"></button>
        <button class="control-button" (click)="scrollToItem(2)"
            [ngClass]="{ activeButton: currentIndex === 1 }"></button>
        <button class="control-button" (click)="scrollToItem(3)"
            [ngClass]="{ activeButton: currentIndex === 2 }"></button>
        <button class="control-button" (click)="scrollToItem(4)"
            [ngClass]="{ activeButton: currentIndex === 3 }"></button>
    </div>
</div>

<div *ngIf="item.widgetType == 'intro_text' && i == 2" class="bot-message">
    {{item.text}}
</div>
</div>



<!-- ************ messageList ************ -->

<!-- <div *ngFor="let item of messages" style="display: block;" class="chat-buttons-action-container">
    

        <div class="chat-message-left-type2-container">
            <div *ngIf="item.sender == 'user'" class="chat-message-type2" >
                {{item.text}}
                </div>
        </div>
  
        <div 
        *ngIf="item.sender == 'bot'"
        class="chat-message-left-type2">
        {{item.text}}
        </div> 
</div> -->

<!-- <div class="chat-buttons-action-container">
    <div *ngIf="isTyping == true" style="font-size:small">
        Tina is typing
        <span class="typing-indicator-1">
          <span></span>
          <span></span>
          <span></span>
         
        </span>
      </div>
</div> -->

<!-- <div style="
              background: #ccf3f5;
              border-radius: 15px;
              margin: 18px;
              margin-top: 35px;
              padding: 0px 2px;
              border-top-left-radius: 0px;
              overflow: hidden;
            ">
    <div  style="display: flex">
        <div>
            <img src="../assets/images/botv2/tina-about.png" alt="" style="    height: 130px;
                  margin-top: 11px;" />
        </div>
        <div style="    margin: 12px 0px;
              padding-top: 4px;
              margin-right: 9px;
              margin-left: -2px;
              margin-top: 16px;">
            <div style="
                    font-family: Montserrat, sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #525252;
                  ">
                <b>Hello, I am TINA</b>
                <br />
                I am here to help you with information about Gravitas AI.
            </div>
            <div style="
                    font-family: Montserrat, sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15px;
                    letter-spacing: 0em;
                    text-align: left;
                    margin-top: 18px;
                    color: #525252;
                  ">
                I am <b>available 24/7</b> and have been trained on topics
                related to Gravitas AI.
            </div>

            <div *ngIf="false"  class="video_content2">
                Click on the <a href="youtube.com">video</a> to know more
                about our company
            </div>
        </div>
    </div>

    <div *ngIf="false" id="video-container" style="width: 95%">
        <img src="../assets/images/botv2/into-image.png" alt="Video Thumbnail" id="thumbnail" (click)="playVideo()" />
        <div class="black-mask">

        </div>
        <button id="play-button" (click)="playVideo()">    <img src="../../../../assets/images/botv2/triangle.png" alt="play button" class="triangle_prop"/></button>
        <video controls #videoElement style="height: 110px; background-color: black;">
            <source src="../assets/videos/tina.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>
</div> -->




<!-- **************show video pannel **************** -->
<div *ngIf="false" style="display: flex" class="main_video_container">
    <div class="video_content">
        Click on the <a href="youtube.com">video</a> to know more about
        our company
    </div>
    <div id="video-container">
        <div style="position: relative">
            <img src="../assets/images/botv2/into-image.png" alt="Video Thumbnail" id="thumbnail" (click)="playVideo()"
                style="margin-top: 5px;" />

            <div class="black-shadow"></div>
        </div>
        <button id="play-button" (click)="playVideo()">    <img src="../../../../assets/images/botv2/triangle.png" alt="play button" class="triangle_prop"/></button>
        <video controls #videoElement>
            <source src="../assets/videos/tina.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>
</div>






<!-- action buttons   -->
<!-- <div style="margin: 16px">
    <div>
      
        <button class="bot-v2-button" (click)="onButtonClickBot('aboutUs')" (mouseenter)="onButtonHoverBot('aboutUs')"
            (mouseleave)="onButtonLeaveBot('aboutUs')" [class.clicked]="activeButton === 'aboutUs'"
            [class.hovered]="hoveredButton === 'aboutUs'">
            About Us
        </button>

   
        <button class="bot-v2-button" (click)="onButtonClickBot('contactUs')"
            (mouseenter)="onButtonHoverBot('contactUs')" (mouseleave)="onButtonLeaveBot('contactUs')"
            [class.clicked]="activeButton === 'contactUs'" [class.hovered]="hoveredButton === 'contactUs'">
            Contact Us
        </button>

      
        <button class="bot-v2-button" (click)="onButtonClickBot('services')" (mouseenter)="onButtonHoverBot('services')"
            (mouseleave)="onButtonLeaveBot('services')" [class.clicked]="activeButton === 'services'"
            [class.hovered]="hoveredButton === 'services'">
            Services
        </button>


        <button class="bot-v2-button" (click)="onButtonClickBot('faq')" (mouseenter)="onButtonHoverBot('faq')"
            (mouseleave)="onButtonLeaveBot('faq')" [class.clicked]="activeButton === 'faq'"
            [class.hovered]="hoveredButton === 'faq'">
            Frequently Asked Questions
        </button>

    
        <button class="bot-v2-button" (click)="onButtonClickBot('packages')" (mouseenter)="onButtonHoverBot('packages')"
            (mouseleave)="onButtonLeaveBot('packages')" [class.clicked]="activeButton === 'packages'"
            [class.hovered]="hoveredButton === 'packages'">
            Packages
        </button>

    
        <button class="bot-v2-button" (click)="onButtonClickBot('demo')" (mouseenter)="onButtonHoverBot('demo')"
            (mouseleave)="onButtonLeaveBot('demo')" [class.clicked]="activeButton === 'demo'"
            [class.hovered]="hoveredButton === 'demo'">
            Book a Demo
        </button>

    
        <button class="bot-v2-button" (click)="onButtonClickBot('hello')" (mouseenter)="onButtonHoverBot('hello')"
            (mouseleave)="onButtonLeaveBot('hello')" [class.clicked]="activeButton === 'hello'"
            [class.hovered]="hoveredButton === 'hello'">
            Our Procedures
        </button>
    </div>

    <div>
        <div style="
          margin-top: 15px;
          display: flex;
          flex-direction: row-reverse;
        ">
            <div style="
            width: 100%;
            display: flex;
            flex-direction: column;
            float: right;
            flex-basis: fit-content;
            place-items: flex-end;
          ">
                <div class="chat-message">Our Procedures</div>

                <div class="chat-message">
                    Hello, I wanted to know about Dry Eyes, its treatment
                </div>
            </div>
        </div>
    </div>
</div> -->