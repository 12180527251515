<div class="rating">
    <input type="radio" id="star1" name="rating" value="5" [(ngModel)]="selectedRating">
    <label for="star1" [style.background-image]="selectedRating >= 5 ? 'url(../../assets/images/star/star-checked-5.png)' : 'url(../../assets/images/star/star-unchecked-5.png)'"
      
      [style.background-image]="(hoveredRating >= 5) ? 'url(../../assets/images/star/star-checked-5.png)' : (selectedRating > 4) ? 'url(../../assets/images/star/star-checked-5.png)' : 'url(../../assets/images/star/star-unchecked-5.png)'" (mouseover)="hoveredRating = 5" (mouseout)="hoveredRating = 0"

      (click)="submitRating(5)"
      
      ></label>
    <input type="radio" id="star2" name="rating" value="4" [(ngModel)]="selectedRating">
    <label for="star2" 
    [style.background-image]="selectedRating >= 4 ? 'url(../../assets/images/star/star-checked-4.png)' : 'url(../../assets/images/star/star-unchecked-4.png)'"

      [style.background-image]="hoveredRating >= 4 ? 'url(../../assets/images/star/star-checked-4.png)' : (selectedRating > 3) ? 'url(../../assets/images/star/star-checked-4.png)' : 'url(../../assets/images/star/star-unchecked-4.png)'" (mouseover)="hoveredRating = 4" (mouseout)="hoveredRating = 0"
      (click)="submitRating(4)"
      
      ></label>
    <input type="radio" id="star3" name="rating" value="3" [(ngModel)]="selectedRating">
    <label for="star3" 

    [style.background-image]="selectedRating >= 3 ? 'url(../../assets/images/star/star-checked-3.png)' : 'url(../../assets/images/star/star-unchecked-3.png)'"

      [style.background-image]="hoveredRating >= 3 ? 'url(../../assets/images/star/star-checked-3.png)' : (selectedRating > 2) ? 'url(../../assets/images/star/star-checked-3.png)' : 'url(../../assets/images/star/star-unchecked-3.png)'" (mouseover)="hoveredRating = 3" (mouseout)="hoveredRating = 0"

      (click)="submitRating(3)"

      ></label>
    <input type="radio" id="star4" name="rating" value="2" [(ngModel)]="selectedRating">
    <label for="star4"

    [style.background-image]="selectedRating >= 2 ? 'url(../../assets/images/star/star-checked-2.png)' : 'url(../../assets/images/star/star-unchecked.png)'"

      [style.background-image]="hoveredRating >= 2 ? 'url(../../assets/images/star/star-checked-2.png)' : (selectedRating > 1) ? 'url(../../assets/images/star/star-checked-2.png)' : 'url(../../assets/images/star/star-unchecked-2.png)'" (mouseover)="hoveredRating = 2" (mouseout)="hoveredRating = 0"
      (click)="submitRating(2)"
      
      ></label>
    <input type="radio" id="star5" name="rating" value="1" [(ngModel)]="selectedRating">
    <label for="star5"

    [style.background-image]="selectedRating >= 1 ? 'url(../../assets/images/star/star-checked-1.png)' : 'url(../../assets/images/star/star-unchecked-1.png)'"

      [style.background-image]="hoveredRating >= 1 ? 'url(../../assets/images/star/star-checked-1.png)' : (selectedRating > 0) ? 'url(../../assets/images/star/star-checked-1.png)' : 'url(../../assets/images/star/star-unchecked-1.png)'" (mouseover)="hoveredRating = 1" (mouseout)="hoveredRating = 0"

      (click)="submitRating(1)"

      
      ></label>
  </div>