<form class="{{theme}}">
    <div class="form-group">
      <fieldset class="form-group">
        <div class="custom-file fileInputProfileWrap">
          <input type="file" #fileInput (change)="fileChangeEvent($event)" class="fileInputProfile">
          <div class="img-space">
  
            <ng-container *ngIf="isImageSaved; else elseTemplate">
              <img style="max-width: 245px; max-height: 165px; object-fit: cover; border-radius: 10px;" [src]="cardImageBase64" height="165" width="245"
                class="img-responsive" />
            </ng-container>
            <ng-template #elseTemplate>
              <div>
                <img src="assets/images/plus.png" height="165" width="245" class="img-responsive plus-img">
              </div>
              <div>
                <button type="button" class="btn txt-to-display">{{textToDisplay}}</button>
              </div>
            </ng-template>
  
          </div>
  
        </div>
      </fieldset>
    </div>
    <div class="d-flex justify-content-center mt-3 bottm-button-sheet"

    >
      


      <a style="background-color: #949494;" class="btn btn-sm ml-2 cancel-button" (click)="removeImage()"
        *ngIf="isImageSaved">Change</a>
        <button
      [disabled]="isLoading"
      class="btn btn-sm uploaded-button" (click)="uploadImage()" *ngIf="isImageSaved">
    {{isLoading ? 'Uploading...' : 'Upload'}}
      </button>
    </div>
  </form>

  <div
  class="error_msg"
  >
      
    {{imageError}}
  </div>