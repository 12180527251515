<div class="chat-buttons-action-container">
  <div class="chat-message-type2">Our Team</div>
  <div class="chat-message-left-type2">
    Below are the details of <b>our team.</b>
  </div>

  <div class="chat-our-team-box" style="width: 100%; margin-top: 3px; display: flex; gap: 10px">
    <ngb-carousel [showNavigationIndicators]="false">
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <div class="chat-our-team-box" style="
                width: 90%;
                margin-top: 15px;
                display: flex;
                gap: 10px;
              ">
            <div class="team-boxes">
              <div class="team-box-top">
                <div class="team-box-top-heading">
                  Aarsh Srivastava
                </div>
                <div class="team-box-top-subheading">
                  <b>Co- Founder, COO</b>
                </div>
              </div>
              <hr style="margin: 0; padding: 0" />
              <div class="team-box-middle">
                <div style="background: #00000014; border-radius: 5px">
                  <img src="../assets/images/botv2/arsh_image.png" alt=""
                    style="width: 64px; height: 50px; z-index: 20" />
                </div>
                <div>
                  <div>
                    <img src="../assets/images/botv2/ri_linkedin-fill.png" alt="" style="width: 14px; height: 14px" />
                  </div>
                  <div>
                    <img src="../assets/images/botv2/twitter.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="team-box-bottom">
                He is a great AI & ML strategist. Leading to the
                delivery of world’s first healthcare chatbot. Aarsh is
                an ethical AI advocate
              </div>
            </div>
            <div class="team-boxes">
              <div class="team-box-top">
                <div class="team-box-top-heading">Ankita Sinha</div>
                <div class="team-box-top-subheading">
                  <b>Co- Founder, CTO</b>
                </div>
              </div>
              <hr style="margin: 0; padding: 0" />
              <div class="team-box-middle">
                <div style="background: #00000014; border-radius: 5px">
                  <img src="../assets/images/botv2/ankita_image.png" alt="" style="width: 64px; height: 50px" />
                </div>
                <div>
                  <div>
                    <img src="../assets/images/botv2/ri_linkedin-fill.png" alt="" style="width: 14px; height: 14px" />
                  </div>
                  <div>
                    <img src="../assets/images/botv2/twitter.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="team-box-bottom">
                Ankita is a AI, big data and analytics specialist.
                Awarded exceptional talent visa in technical field by
                Govt. of UK
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <div class="chat-our-team-box" style="
                width: 90%;
                margin-top: 15px;
                display: flex;
                gap: 10px;
              ">
            <div class="team-boxes">
              <div class="team-box-top">
                <div class="team-box-top-heading">Ankita Sinha</div>
                <div class="team-box-top-subheading">
                  <b>Co- Founder, CTO</b>
                </div>
              </div>
              <hr style="margin: 0; padding: 0" />
              <div class="team-box-middle">
                <div style="background: #00000014; border-radius: 5px">
                  <img src="../assets/images/botv2/ankita_image.png" alt="" style="width: 64px; height: 50px" />
                </div>
                <div>
                  <div>
                    <img src="../assets/images/botv2/ri_linkedin-fill.png" alt="" style="width: 14px; height: 14px" />
                  </div>
                  <div>
                    <img src="../assets/images/botv2/twitter.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="team-box-bottom">
                Ankita is a AI, big data and analytics specialist.
                Awarded exceptional talent visa in technical field by
                Govt. of UK
              </div>
            </div>

            <div class="team-boxes">
              <div class="team-box-top">
                <div class="team-box-top-heading">
                  Aarsh Srivastava
                </div>
                <div class="team-box-top-subheading">
                  <b>Co- Founder, COO</b>
                </div>
              </div>
              <hr style="margin: 0; padding: 0" />
              <div class="team-box-middle">
                <div style="background: #00000014; border-radius: 5px">
                  <img src="../assets/images/botv2/arsh_image.png" alt="" style="width: 64px; height: 50px" />
                </div>
                <div>
                  <div>
                    <img src="../assets/images/botv2/ri_linkedin-fill.png" alt="" style="width: 14px; height: 14px" />
                  </div>
                  <div>
                    <img src="../assets/images/botv2/twitter.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="team-box-bottom">
                He is a great AI & ML strategist. Leading to the
                delivery of world’s first healthcare chatbot. Aarsh is
                an ethical AI advocate
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>

  <div class="chat-message-left-type2" style="font-weight: 600">
    <b>To find out more, click on the below buttons</b>
  </div>

  <div style="margin: 16px 0">
    <div>
      <!-- "About Us" button -->
      <button class="bot-v2-button" (click)="onButtonClickBot('Main Menu')" (mouseenter)="onButtonHoverBot('Main Menu')"
        (mouseleave)="onButtonLeaveBot('Main Menu')" [class.clicked]="activeButton === 'Main Menu'"
        [class.hovered]="hoveredButton === 'Main Menu'">
        Main Menu
      </button>

      <!-- "Contact Us" button -->
      <button class="bot-v2-button" (click)="onButtonClickBot('Our History')"
        (mouseenter)="onButtonHoverBot('Our History')" (mouseleave)="onButtonLeaveBot('Our History')"
        [class.clicked]="activeButton === 'Our History'" [class.hovered]="hoveredButton === 'Our History'">
        Our History
      </button>

      <!-- "Services" button -->
      <button class="bot-v2-button" (click)="onButtonClickBot('Contact Us')"
        (mouseenter)="onButtonHoverBot('Contact Us')" (mouseleave)="onButtonLeaveBot('Contact Us')"
        [class.clicked]="activeButton === 'Contact Us'" [class.hovered]="hoveredButton === 'Contact Us'">
        Contact Us
      </button>

      <!-- "Frequently Asked Questions" button -->
      <button class="bot-v2-button" (click)="onButtonClickBot('Product')" (mouseenter)="onButtonHoverBot('Product')"
        (mouseleave)="onButtonLeaveBot('Product')" [class.clicked]="activeButton === 'Product'"
        [class.hovered]="hoveredButton === 'Product'">
        Product
      </button>

      <!-- "Packages" button -->
      <button class="bot-v2-button" (click)="onButtonClickBot('Solution')" (mouseenter)="onButtonHoverBot('Solution')"
        (mouseleave)="onButtonLeaveBot('Solution')" [class.clicked]="activeButton === 'Solution'"
        [class.hovered]="hoveredButton === 'Solution'">
        Solution
      </button>

      <!-- "Book a Demo" button -->
      <button class="bot-v2-button" (click)="onButtonClickBot('Schedule Demo')"
        (mouseenter)="onButtonHoverBot('Schedule Demo')" (mouseleave)="onButtonLeaveBot('Schedule Demo')"
        [class.clicked]="activeButton === 'Schedule Demo'" [class.hovered]="hoveredButton === 'Schedule Demo'">
        Schedule Demo
      </button>

      <!-- "Our Procedures" button -->
    </div>
  </div>
</div>





<!-- type 2 -->
<div class="chat-buttons-action-container">
  <div class="chat-message-type2">Our Team</div>
  <div class="chat-message-left-type2">
    Below are the details of <b>our team.</b>
  </div>

  <div
    class="chat-our-team-box"
    style="
      width: 90%;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 195px;
      transition: all 0.3s ease;
      overflow-y: scroll;
      padding-right: 10px;
    "
  >
    <div class="team-boxes" style="width: 100%">
      <div
        class="team-box-top"
        style="display: flex; justify-content: space-between"
      >
        <div>
          <div class="team-box-top-heading">Ankita Sinha</div>
          <div class="team-box-top-subheading">
            <b>Co- Founder, CTO</b>
          </div>
        </div>
        <div>
          <div style="display: flex; gap: 14px; margin-top: -5px">
            <div>
              <img
                src="../assets/images/botv2/ri_linkedin-fill.png"
                alt=""
                style="width: 14px; height: 14px"
              />
            </div>
            <div>
              <img src="../assets/images/botv2/twitter.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="team-box-bottom"
        style="width: 70%; position: relative"
      >
        <div
          style="
            background: #00000014;
            border-radius: 5px;
            position: absolute;
            right: -85px;
            top: -12px;
          "
        >
          <img
            src="../assets/images/botv2/ankita_image.png"
            alt=""
            style="width: 64px; height: 50px"
          />
        </div>
        <div style="position: absolute; left: -5px; top: 10px">
          <img
            src="../assets/images/botv2/about-vertical.png"
            alt=""
          />
        </div>
        Ankita is a AI, big data and analytics specialist. Awarded
        exceptional talent visa in technical field by Govt. of UK
      </div>
    </div>
    <div class="team-boxes" style="width: 100%">
      <div
        class="team-box-top"
        style="display: flex; justify-content: space-between"
      >
        <div>
          <div class="team-box-top-heading">Aarsh Srivastava</div>
          <div class="team-box-top-subheading">
            <b>Co- Founder, COO</b>
          </div>
        </div>
        <div>
          <div style="display: flex; gap: 14px; margin-top: -5px">
            <div>
              <img
                src="../assets/images/botv2/ri_linkedin-fill.png"
                alt=""
                style="width: 14px; height: 14px"
              />
            </div>
            <div>
              <img src="../assets/images/botv2/twitter.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="team-box-bottom"
        style="width: 70%; position: relative"
      >
        <div
          style="
            background: #00000014;
            border-radius: 5px;
            position: absolute;
            right: -85px;
            top: -12px;
          "
        >
          <img
            src="../assets/images/botv2/arsh_image.png"
            alt=""
            style="width: 64px; height: 50px"
          />
        </div>

        <div style="position: absolute; left: -5px; top: 10px">
          <img
            src="../assets/images/botv2/about-vertical.png"
            alt=""
          />
        </div>
        He is a great AI & ML strategist. Leading to the delivery of
        world’s first healthcare chatbot. Aarsh is an ethical AI
        advocate
      </div>
    </div>
    <div class="team-boxes" style="width: 100%">
      <div
        class="team-box-top"
        style="display: flex; justify-content: space-between"
      >
        <div>
          <div class="team-box-top-heading">Aarsh Srivastava</div>
          <div class="team-box-top-subheading">
            <b>Co- Founder, COO</b>
          </div>
        </div>
        <div>
          <div style="display: flex; gap: 14px; margin-top: -5px">
            <div>
              <img
                src="../assets/images/botv2/ri_linkedin-fill.png"
                alt=""
                style="width: 14px; height: 14px"
              />
            </div>
            <div>
              <img src="../assets/images/botv2/twitter.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="team-box-bottom"
        style="width: 70%; position: relative"
      >
        <div
          style="
            background: #00000014;
            border-radius: 5px;
            position: absolute;
            right: -85px;
            top: -12px;
          "
        >
          <img
            src="../assets/images/botv2/arsh_image.png"
            alt=""
            style="width: 64px; height: 50px"
          />
        </div>

        <div style="position: absolute; left: -5px; top: 10px">
          <img
            src="../assets/images/botv2/about-vertical.png"
            alt=""
          />
        </div>
        He is a great AI & ML strategist. Leading to the delivery of
        world’s first healthcare chatbot. Aarsh is an ethical AI
        advocate
      </div>
    </div>
    <div class="team-boxes" style="width: 100%">
      <div
        class="team-box-top"
        style="display: flex; justify-content: space-between"
      >
        <div>
          <div class="team-box-top-heading">Aarsh Srivastava</div>
          <div class="team-box-top-subheading">
            <b>Co- Founder, COO</b>
          </div>
        </div>
        <div>
          <div style="display: flex; gap: 14px; margin-top: -5px">
            <div>
              <img
                src="../assets/images/botv2/ri_linkedin-fill.png"
                alt=""
                style="width: 14px; height: 14px"
              />
            </div>
            <div>
              <img src="../assets/images/botv2/twitter.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="team-box-bottom"
        style="width: 70%; position: relative"
      >
        <div
          style="
            background: #00000014;
            border-radius: 5px;
            position: absolute;
            right: -85px;
            top: -12px;
          "
        >
          <img
            src="../assets/images/botv2/arsh_image.png"
            alt=""
            style="width: 64px; height: 50px"
          />
        </div>

        <div style="position: absolute; left: -5px; top: 10px">
          <img
            src="../assets/images/botv2/about-vertical.png"
            alt=""
          />
        </div>
        He is a great AI & ML strategist. Leading to the delivery of
        world’s first healthcare chatbot. Aarsh is an ethical AI
        advocate
      </div>
    </div>
  </div>

  <div class="chat-message-left-type2" style="font-weight: 600">
    <b>To find out more, click on the below buttons</b>
  </div>

  <div style="margin: 10px 0">
    <div>
      <!-- "About Us" button -->
      <button
        class="bot-v2-button"
        (click)="onButtonClickBot('Main Menu')"
        (mouseenter)="onButtonHoverBot('Main Menu')"
        (mouseleave)="onButtonLeaveBot('Main Menu')"
        [class.clicked]="activeButton === 'Main Menu'"
        [class.hovered]="hoveredButton === 'Main Menu'"
      >
        Main Menu
      </button>

      <!-- "Contact Us" button -->
      <button
        class="bot-v2-button"
        (click)="onButtonClickBot('Our History')"
        (mouseenter)="onButtonHoverBot('Our History')"
        (mouseleave)="onButtonLeaveBot('Our History')"
        [class.clicked]="activeButton === 'Our History'"
        [class.hovered]="hoveredButton === 'Our History'"
      >
        Our History
      </button>

      <!-- "Services" button -->
      <button
        class="bot-v2-button"
        (click)="onButtonClickBot('Contact Us')"
        (mouseenter)="onButtonHoverBot('Contact Us')"
        (mouseleave)="onButtonLeaveBot('Contact Us')"
        [class.clicked]="activeButton === 'Contact Us'"
        [class.hovered]="hoveredButton === 'Contact Us'"
      >
        Contact Us
      </button>

      <!-- "Frequently Asked Questions" button -->
      <button
        class="bot-v2-button"
        (click)="onButtonClickBot('Product')"
        (mouseenter)="onButtonHoverBot('Product')"
        (mouseleave)="onButtonLeaveBot('Product')"
        [class.clicked]="activeButton === 'Product'"
        [class.hovered]="hoveredButton === 'Product'"
      >
        Product
      </button>

      <!-- "Packages" button -->
      <button
        class="bot-v2-button"
        (click)="onButtonClickBot('Solution')"
        (mouseenter)="onButtonHoverBot('Solution')"
        (mouseleave)="onButtonLeaveBot('Solution')"
        [class.clicked]="activeButton === 'Solution'"
        [class.hovered]="hoveredButton === 'Solution'"
      >
        Solution
      </button>

      <!-- "Book a Demo" button -->
      <button
        class="bot-v2-button"
        (click)="onButtonClickBot('Schedule Demo')"
        (mouseenter)="onButtonHoverBot('Schedule Demo')"
        (mouseleave)="onButtonLeaveBot('Schedule Demo')"
        [class.clicked]="activeButton === 'Schedule Demo'"
        [class.hovered]="hoveredButton === 'Schedule Demo'"
      >
        Schedule Demo
      </button>

      <!-- "Our Procedures" button -->
    </div>
  </div>
</div>