import { Directive, ElementRef, EventEmitter, Output, Renderer2, HostListener, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective implements OnDestroy {
  @Output() clickOutside = new EventEmitter<void>();

  private unlisten: () => void;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.unlisten = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));
  }

  ngOnDestroy(): void {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  private onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
      this.clickOutside.emit();
    }
  }
}
