<div style="margin: 15px 0px 10px">
    <div class="carousel-container1">
        <div class="carousel-wrapper1" id="carouselWrapper1">
            <div class="carousel-itemm" *ngFor="let item of videos;let i = index" [ngClass]="{
            'un-blur-content': currentIndex === i,
            'blur-content': currentIndex !== i
          }">
                <div id="video-crousel-container">
                    <!-- <div style="position: relative">
                        <img src="../assets/images/botv2/into-image.png" alt="Video Thumbnail" id="{{ 'thumbnail' + i }}"
                            class="thumbnail" (click)="playVideoCrousel(i)" />

                        <div class="black-shadow2"></div>
                    </div>
                    <button id="{{ 'play-button1' + i }}" class="play-button" (click)="playVideoCrousel(i)">
                   
                        <img src="../../../../assets/images/botv2/triangle.png" alt="play button" class="triangle_prop"/>
                    
                    </button> -->
                    <!-- <video controls #videoElement{{i}} class="video-player">
                        <source [src]="item.videoLink" />
                        Your browser does not support the video tag.
                    </video> -->
                   
                    <div style="position: relative; display: inline-block;">
                        <iframe
                          class="video-player"
                          [src]="item?.videoLink | safe"
                          title="YouTube video player"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                        <div
                          *ngIf="currentIndex !== i"
                          style="    position: absolute;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          background: rgb(255 255 255 / 0%);
                          z-index: 1;">
                        </div>
                      </div>
                </div>
                <div class="crousel-video-content">
                   
                    <b>Company Video</b> - Gravitas AI, Designing tomorrow with
                    conversational AI
                </div>
            </div>

            
        </div>
    </div>

    <div class="control-buttons">
        <button class="control-button"  *ngFor="let item of videos;let i = index" (click)="scrollToItem(i)"
            [ngClass]="{ activeButton: currentIndex === i }"></button>
       
    </div>
</div>