import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-solution-screen',
  templateUrl: './solution-screen.component.html',
  styleUrls: ['./solution-screen.component.scss']
})
export class SolutionScreenComponent implements OnInit{
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;


  constructor() { }


  ngOnInit() {
  }
  ngAfterViewInit() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    const element = this.scrollContainer.nativeElement;
    element.scrollTop = element.scrollHeight;
  }


  activeButton: string = '';
  hoveredButton: string = '';
  activeMenuButton: string = '';
  hoveredMenuButton: string = '';
  show_menu_value: string = 'default';
  features_box_value:string= '';
  selectedDateTime: string='';

  acceptTerms: boolean = false;
  showContent: boolean = false;
  show_Content: boolean = false;
  show_about_tina: boolean= false;
  show_about_section: boolean= true;
  show_plus: boolean =true;
  show_plus2: boolean =true;

  isMenuVisible:boolean = false;

  toggleMenuButton() {
    this.isMenuVisible = !this.isMenuVisible;
  }
  togglePlusButton() {
    this.show_plus = !this.show_plus;
  }
  togglePlusButton2() {
    this.show_plus2 = !this.show_plus2;
  }

  onGetStartedClick() {
    console.log("hello",this.acceptTerms)
    if (this.acceptTerms) {
      this.showContent = true;
    }
  }

  onButtonClickBot(buttonName: string): void {
    this.activeButton = buttonName;

    if(buttonName == 'aboutUs'){
      this.show_menu_value = 'aboutUs';
    }
    if(buttonName == 'Solution'){
      this.show_menu_value = 'Solution';
    }
    if(buttonName == 'Our Team'){
      this.show_menu_value = 'Our Team';
    }
    if(buttonName == 'Main Menu'){
      this.showContent=false;
      this.show_Content=true;
      this.show_menu_value = 'Main Menu';
    }
  }

  onButtonHoverBot(buttonName: string): void {
    this.hoveredButton = buttonName;
  }

  onButtonLeaveBot(buttonName: string): void {
    this.hoveredButton = ''; // Clear the hover state when leaving the button
  }
  onButtonClickMenuBot(buttonName: string): void {
    this.activeMenuButton = buttonName;
  }

  onButtonHoverMenuBot(buttonName: string): void {
    this.hoveredMenuButton = buttonName;
  }

  onButtonLeaveMenuBot(buttonName: string): void {
    this.hoveredMenuButton = ''; // Clear the hover state when leaving the button
  }

  handleFeatureBox(stringValue:string):void{
    this.features_box_value=stringValue;
  }
}
