<div>
    <div class="bottom-bot-div">
        <div class="child1">
            <div class="left_bottom">
                <div (click)="toggleMenuButton()" style="cursor: pointer">
                    <img src="../assets/images/botv2/eva_menu-fill.png" alt="" />
                </div>
                <!-- <div>
                    <img src="../assets/images/botv2/Vector 13.png" alt="" />
                </div> -->
                <button
                class="emoji-button"
                [disabled]="messages.length > 0 && (messages[messages.length - 1].widgetType === 'Date' ||messages[messages.length - 1].widgetType === 'Time')"
                (click)="isEmojiPickerVisible = !isEmojiPickerVisible;">
                <i class="fas fa-laugh custom-icon-color"  style="width: 18px; height: 18px; align-self: center"></i>
     
            </button>
            </div>
            
    <emoji-mart
      class="emoji-mart"
      *ngIf="isEmojiPickerVisible"
      (emojiSelect)="addEmoji($event)"
      title="Choose your emoji"
      [style]="{ position: 'absolute', bottom: '60px', right: '20px' , backgroundColor: 'white', color:'black'}"
    ></emoji-mart>
           
        </div>
        <div class="child2">
            <input type="text"
            style="background-color: transparent;"
            [disabled]="
            isTyping ||
            messages.length > 0 && (messages[messages.length - 1].widgetType === 'Date' ||  messages[messages.length - 1].widgetType === 'Time')"
            (keyup.enter)="handleEventEnter()"
            [(ngModel)]="message" placeholder="Type your questions here!" class="input-element" />
        </div>
        <div
        (click)="handleEventEnter()"
        [style.cursor]="isTyping || messages.length > 0 && (messages[messages.length - 1].widgetType === 'Date' || messages[messages.length - 1].widgetType === 'Time') ? 'not-allowed' : 'pointer'"
    
        class="child3">
            <!-- <img src="../assets/images/botv2/mingcute_send-fill.png" alt="" /> -->
            <i class="fas fa-paper-plane"></i>
    
        </div>

        <div
        
    
        class="child4">
            <!-- <img src="../assets/images/botv2/mingcute_send-fill.png" alt="" /> -->
            <i 
            (click)="fnRecordVoice()"
            [ngClass]="(this.isListening ? 'mic_active fa fa-microphone' :'fa fa-microphone')" ></i>
    
        </div>
    
       
    </div>
<div class="powered-by">
    
<div class="powered-by-text">
    Powered By
</div>

<div
class="powered-by-logo"
>
<a href="https://www.gravitas.ai/" target="_blank">
    <img src="../assets/images/logo.png" alt="" 
    class="powered-by-logo-image"
    />
</a>
</div>

</div>    
</div>


<div *ngIf="isMenuVisible"

appClickOutside (clickOutside)="handleClickOutside()"
>
    <div class="menu-container">
        <div class="menu-style">
            <div  class="menu-style-gap">
                <div class="menu-box" (click)="handleClick('menu')">
                    <i class="fa-solid fa-border-all custom-icon-color"  style="width: 18px; height: 18px; align-self: center"></i>
                    <div class="menu-button custom-icon-color">Main Menu</div>

                    <div class="vertical_box">
                        <img src="../assets/images/botv2/vertical_line.png" alt="" style="display: none;" />
                    </div>
                </div>
                <div class="menu-box"
                (click)="handleOpenFileUplaodModel()"
                >

                        <i class="fa-solid fa-paperclip custom-icon-color"  style="width: 18px; height: 18px; align-self: center"></i>
                    <div class="menu-button custom-icon-color">Attach</div>
                    <div class="vertical_box">
                        <img src="../assets/images/botv2/vertical_line.png" alt="" style="display: none;" />
                    </div>
                </div>

                
                <div class="menu-box"
                (click)="generatePDF()"
                >
                  
                        <i class="fa-solid fa-download custom-icon-color"  style="width: 18px; height: 18px; align-self: center"></i>
                    <div class="menu-button custom-icon-color">Download</div>
                    <div class="vertical_box">
                        <img src="../assets/images/botv2/vertical_line.png" alt="" style="display: none;" />
                    </div>

                </div>

                <div class="menu-box"
                (click)="handleAddPayment()"
                >
                   
                    <i class="fas fa-wallet custom-icon-color"  style="width: 18px; height: 18px; align-self: center"></i>
                <div class="menu-button custom-icon-color">Payement</div>
                <div class="vertical_box">
                    <img src="../assets/images/botv2/vertical_line.png" alt="" style="display: none;" />
                </div>

            </div>
            </div>
            <div
            (click)="handleClickOnHelp()"
            >
                <div class="menu-box">
                    <i class="fa-solid fa-question custom-icon-color"  style="width: 18px; height: 18px;
                    display: flex; justify-content: center;
                    align-self: center"></i>

                    <div class="menu-button  custom-icon-color">Help</div>
                </div>
            </div>
        </div>
    </div>
</div>