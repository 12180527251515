<div *ngIf="showChatBotScreen" class="main-container">
  <div class="app-header">
    <app-header (ChangeBotLanguage)="handleBotLanguageChangeEvent($event)"
      [translationActive]="this.isGoogleTranslation" [BotDefaultLanguage]="this.BotDefaultLanguage"
      [botLanguages]="botLanguages" style="width: 100%;" [canChangeStyle]="canChangeStyle"
      [clientConfigurationData]="clientConfigurationData"
      (headerClose)="closeChat()"
      ></app-header>
      <!-- <button id="close-button" (click)="closeChat()">Close Chat</button> -->
  </div>
  <div class="bot-middle-scrren" #chatWindow id="conversationContent">

    <!-- download pdf progress bar -->
    <div *ngIf="downloadingPdf" class="progress-bar-container">

      <div>
        <i class="fa-solid fa-download custom-icon-color"
          style="font-size: 36; margin-bottom: 18px; align-self: center"></i>
      </div>

      <div class="progress-wrapper">
        <div class="progress-bar">

        </div>

      </div>
      <div class="progress-bar-text">
        Downloading the transcript

      </div>
      <div class="progress-bar-text-2">
        this may take a few seconds
      </div>
    </div>


    <!-- main chat screen -->
    <app-chats (handleSubmit)="onSubmit($event)" [messages]="messages" [canChangeStyle]="canChangeStyle"
      [clientConfigurationData]="clientConfigurationData" [razorPayEvent]="razorPayObj"
      [clientConfigurationId]="clientConfigurationId" [sessionAttributes]="sessionAttributes"
      *ngIf="!downloadingPdf"></app-chats>

    <!-- tina is typing -->
    <div class="chat-buttons-action-container">
      <div *ngIf="isTyping == true" style="font-size:small">
        {{title}} is typing
        <span class="typing-indicator-1">
          <span></span>
          <span></span>
          <span></span>

        </span>
      </div>
      <div *ngIf="isInitialisingLiveChat == true" style="font-size:small">
        {{title}} is transferring call
        <span class="typing-indicator-1">
          <span></span>
          <span></span>
          <span></span>
          <!-- <span></span>
          <span></span> -->
        </span>
      </div>
      <div *ngIf="isAgentTyping == true" style="font-size:small">
        Agent is typing
        <span class="typing-indicator-1">
          <span></span>
          <span></span>
          <span></span>
          <!-- <span></span>
          <span></span> -->
        </span>
      </div>
    </div>

  </div>
  <div class="app-user-input" *ngIf="!downloadingPdf">
    <app-user-input [messages]="messages" 
    [isTyping]="isTyping"
    (handleSubmit)="onSubmit($event)" (hadleFileUpload)="fnOpenFileUploadModel()"
      (changeScreenValue)="handleListenChangeEvent($event)" [canChangeStyle]="canChangeStyle"
      [clientConfigurationData]="clientConfigurationData" (generatePdf)="generatePdf()"></app-user-input>
  </div>
</div>


<!-- welcome screen -->
<div *ngIf="!showChatBotScreen">
  <app-front-screen (showChatBotScreenValueChange)="reciveChatBotScreenValue($event)"></app-front-screen>
</div>


<!-- submit feedback on user interactivity -->
<div class="sesssion-expired"
  *ngIf="isUserIdleTimeoutExpired && showChatBotScreen && !isFeedbackSubmitted && showChatBotScreen">
  <app-star-rating (handleFeedBackSubmit)="handleFeedBackSubmit($event)" [clientConfigurationId]="clientConfigurationId"
    [sessionAttributes]="sessionAttributes"></app-star-rating>
</div>



<!-- previous chat model -->
<ng-template #previousChatModal let-modal>
  <div class="modal-header" style="
  display: flex;
    gap: 25px;
    align-items: center;
    padding: 7px 15px;">
    <div>
      <img src="assets/images/botv2/tina_grp.png" style="height: 49px;
      width: 45px;
      object-fit: cover;" alt="previous chat" class="previous_chat_img" />
    </div>
    <div style="    font-family: Montserrat, sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 24.38px;
    text-align: left;">
      <span style="    font-weight: 600;">
        Welcome </span>
      <span style="    font-weight: 500;">Back !</span>
    </div>
  </div>
  <div class="modal-body">
    <p class="modal-body-text"> Would you like to continue the chat where you left last time or start a new chat?</p>

  </div>
  <div class="d-flex button_Group " style="margin-top: -10px;">
    <button type="button" (click)="fnContinuePreviousChat();fnClosePreviousChatModal()" style="    flex: 1;
      border-bottom-left-radius: 14px;
      border-top: 1px solid gray !important;
      border-bottom: none !important;
      border-left: none !important;
      outline: none;
      " class="fontColour continue_btn">Continue</button>
    <button type="button" style="flex: 1;
    border: none;
    border-bottom-right-radius: 15px;
    padding: 8px;
    outline: none;
    " (click)="fnStartNewChat();fnClosePreviousChatModal()" class="ontColour start_new strt_new_btnn">Start
      New</button>
  </div>
</ng-template>


<!-- data privacy accept dialog box -->
<ng-template #dataPrivacyModel let-modal>
  <div class="modal-body">
    <p class="modal-body-text data-privacy-heading">Bot will be collecting and storing your first name last name and
      email to create a better experince for you</p>
    <div>
      <div class="button-box">
        <a href="https://gravitas.ai/" target="_blank">Full Privacy Policy</a>
      </div>
      <div (click)="DataPrivacyPolicy(true)" class="button-box">
        I Accept
      </div>
      <div (click)="DataPrivacyPolicy(false)" class="button-box">
        I Refuse
      </div>
    </div>
  </div>
</ng-template>



<ng-template #customerInfoModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title live-chat-header" id="modal-basic-title">Customer Information</h4>
    <!-- <button type="button" class="close custCloseBtn" aria-label="Close" (click)="fnCloseCustomerInfoModal()">
      <span aria-hidden="true">&times;</span>
    </button> -->
    <div (click)="fnCloseCustomerInfoModal()" class="close-button">
      <div class="close-cross">
        x
      </div>
    </div>
  </div>
  <div class="modal-body">

    <form #customerForm="ngForm" novalidate>
      <div class="form-group">
        <input required type="text" class="live-chat-input" placeholder="Enter your name" name="customerName"
          [(ngModel)]="customerName">
      </div>

      <div style="text-align: center;"><button [disabled]="customerName == ''" type="button"
          (click)="fnInitLiveChat();fnCloseCustomerInfoModal()" class="custlivechatbtn">Start Live
          Chat</button></div>
    </form>
  </div>
</ng-template>


<!-- <div *ngIf="isLiveChat == true && isLiveChatEnabled == true"  (click)="fnInitLiveChat()" >
  close live chat
</div> -->


<ng-template #uploadFileModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title upload-capture-title" id="modal-basic-title">Upload and Capture</h4>
    <button style="border: none; background: transparent; outline: none;" (click)="fnCloseModal()">
      <img style="height: 22px; width: 22px; object-fit: cover;" src="assets/images/botv2/cross.png" alt="close" />
    </button>
  </div>
  <div class="modal-body upload-model-container">
    <div class="upload-button" (click)="fnOpenSmallModal(modalWebcamCapture)">
      <img src="assets/images/botv2/camera.png" alt="camera" class="button_img" />
      Capture Image
    </div>
    <div class="upload-button" (click)="fnOpenSmallModal(modalFileUpload)">
      <img src="assets/images/botv2/image.png" alt="upload" class="button_img" />
      Upload Photos & Videos
    </div>
    <div class="upload-button" (click)="fnOpenSmallModal(modalFileUploadPDF)">
      <img src="assets/images/botv2/file.png" alt="document" class="button_img" />
      Upload Document
    </div>
  </div>
</ng-template>



<!-- open webcapture model -->
<ng-template #modalWebcamCapture let-modal>
  <div class="modal-header">
    <h4 class="modal-title upload-capture-title" id="modal-basic-title">Capture Image</h4>
    <button style="border: none; background: transparent; outline: none;" (click)="fnCloseModal()">
      <img style="height: 22px; width: 22px; object-fit: cover;" src="assets/images/botv2/cross.png" alt="close" />
    </button>
  </div>
  <div class="modal-body">
    <app-webcam-capture (imageUploadedToServer)="fnImageUploadedToServer($event)"
      (updateStream)="fnUpdateStream($event)" [canChangeStyle]="canChangeStyle"
      [clientConfigurationData]="clientConfigurationData" (handleAddImageToChat)="handleAddImageToChat($event)"
      [isTyping]="isTyping" (startTyping)="startTyping()"
      [captureForEyeSkinDetection]="captureForEyeSkinDetection"
      (captureForEyeSkinDetectionResponse)="captureForEyeSkinDetectionResponse($event)"
      ></app-webcam-capture>
  </div>
</ng-template>


<ng-template #modalFileUpload let-modal>
  <div class="modal-header">
    <h4 class="modal-title upload-capture-title" id="modal-basic-title">Upload Document</h4>
    <button style="border: none; background: transparent; outline: none;" (click)="fnCloseModal()">
      <img style="height: 22px; width: 22px; object-fit: cover;" src="assets/images/botv2/cross.png" alt="close" />
    </button>
  </div>
  <div class="modal-body">
    <app-fileupload [theme]="theme" [bodyText]="uploadImageModalText"
      [isUploadUsingFeatureAPI]="isSendImageToFeaturesAPI" (onUploadSuccess)="fnModalFileUploadSuccess($event)"
      (onFinished)="fnCloseModal()" (handleAddImageToChat)="handleAddImageToChat($event)"></app-fileupload>
  </div>
</ng-template>



<ng-template #modalFileUploadPDF let-modal>
  <div class="modal-header">
    <h4 class="modal-title upload-capture-title" id="modal-basic-title">Upload Document</h4>
    <button style="border: none; background: transparent; outline: none;" (click)="fnCloseModal()">
      <img style="height: 22px; width: 22px; object-fit: cover;" src="assets/images/botv2/cross.png" alt="close" />
    </button>
  </div>
  <div class="upload-box" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)"
    (click)="fileInput.click()" *ngIf="!fileName">
    <p>Drag and drop a PDF file here or click to select a file</p>
    <input type="file" (change)="onFileSelected($event)" hidden #fileInput accept=".pdf" />
  </div>
  <div *ngIf="fileName" class="file-info">
    <img src="../../assets/images/pdf.png" alt="PDF Icon" class="pdf-icon" />
    <span
    class="font-bold"
    >{{ fileName }}</span>
  </div>
  <!-- <button *ngIf="fileName" (click)="submitPDF()">Submit PDF</button> -->
  <div class="d-flex justify-content-center mt-3 bottm-button-sheet"
  *ngIf="fileName"
  >



    <a style="background-color: #949494;" class="btn btn-sm ml-2 cancel-button" *ngIf="fileName"
      (click)="removePDF()">Change</a>
    <button [disabled]="isPdfLoading" class="btn btn-sm uploaded-button" (click)="submitPDF()" *ngIf="fileName">
      {{isPdfLoading ? 'Uploading...' : 'Upload'}}
    </button>
  </div>
</ng-template>