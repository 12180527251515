import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
@Component({
  selector: 'app-video-carousel',
  templateUrl: './video-carousel.component.html',
  styleUrls: ['./video-carousel.component.scss']
})
export class VideoCarouselComponent implements OnInit{
  @ViewChild('videoElement') videoElement: any;
  @Input() videos: any[] = [];
  @Input() isTyping: boolean = false;
  @Input() canChangeStyle: any;
  @Input() clientConfigurationData: any;
  styleSheet: CSSStyleSheet | undefined;
  DATA: any;

  currentIndex = 0;
  activeButton: string = '';
  show_menu_value: string = '';
  hoveredButton: string = '';
  showContent: boolean = false;
  show_Content: boolean = false;

  @Output() changeScreenValue = new EventEmitter<string>();

  constructor(private el: ElementRef, private renderer: Renderer2, private sanitizer: DomSanitizer) { 

    console.log("text here", this.videos)

  }

  ngOnInit(): void {
    if(this.canChangeStyle){
      this.DATA = this.clientConfigurationData;
      this.fnSetStyles();
    }else{
      this.fnSetDefaultStyles();
    }
  }

  sanitizeUrl(url: string): any {
   
      const returnUrl=this.sanitizer.bypassSecurityTrustResourceUrl(
        url
      )
   
      return returnUrl;
  }

  playVideo() {
    const thumbnail = document.getElementById('thumbnail') as HTMLImageElement;
    const playButton = document.getElementById('play-button') as HTMLButtonElement;
    const blackMask = document.getElementsByClassName('black-mask')[0] as HTMLElement;

    if (this.videoElement) {
      thumbnail.style.display = 'none';
      playButton.style.display = 'none';
      blackMask.style.display = 'none';
      this.videoElement.nativeElement.classList.add('show');
      this.videoElement.nativeElement.play();
    }
  }


  @ViewChild('videoElement0') videoElement0: any;
  @ViewChild('videoElement1') videoElement1: any;
  @ViewChild('videoElement2') videoElement2: any;
  @ViewChild('videoElement3') videoElement3: any;
  @ViewChild('videoElement4') videoElement4: any;
  playVideoCrousel(index: number) {
    console.log("index", index)
    if (index === 0) {
      const thumbnail = document.getElementById('thumbnail0') as HTMLImageElement;
      const playButton = document.getElementById('play-button0') as HTMLButtonElement;
      const blackMask = document.getElementsByClassName('black-shadow2')[0] as HTMLElement;

      if (this.videoElement0) {
        console.log("this.videoElement0", this.videoElement0)
        thumbnail.style.display = 'none';
        playButton.style.display = 'none';
        blackMask.style.display = 'none';

        this.videoElement0.nativeElement.classList.add('show');
        this.videoElement0.nativeElement.play();
      }
    }
    else
      if (index === 2) {
        const thumbnail = document.getElementById('thumbnail2') as HTMLImageElement;
        const playButton = document.getElementById('play-button2') as HTMLButtonElement;
        const blackMask = document.getElementsByClassName('black-shadow2')[1] as HTMLElement;

        if (this.videoElement2) {
          thumbnail.style.display = 'none';
          playButton.style.display = 'none';
          blackMask.style.display = 'none';
          this.videoElement2.nativeElement.classList.add('show');
          this.videoElement2.nativeElement.play();
        }
      }
      else
        if (index === 3) {
          const thumbnail = document.getElementById('thumbnail3') as HTMLImageElement;
          const playButton = document.getElementById('play-button3') as HTMLButtonElement;
          const blackMask = document.getElementsByClassName('black-shadow2')[2] as HTMLElement;

          if (this.videoElement1) {
            thumbnail.style.display = 'none';
            playButton.style.display = 'none';
            blackMask.style.display = 'none';
            this.videoElement3.nativeElement.classList.add('show');
            this.videoElement3.nativeElement.play();
          }
        }
        else
          if (index === 4) {
            const thumbnail = document.getElementById('thumbnail4') as HTMLImageElement;
            const playButton = document.getElementById('play-button4') as HTMLButtonElement;
            const blackMask = document.getElementsByClassName('black-shadow2')[3] as HTMLElement;

            if (this.videoElement1) {
              thumbnail.style.display = 'none';
              playButton.style.display = 'none';
              blackMask.style.display = 'none';
              this.videoElement4.nativeElement.classList.add('show');
              this.videoElement4.nativeElement.play();
            }
          }



    // replicatie same for others
  }


  scrollToItem(index: number) {
    console.log(this.videos)
    console.log("numbefr", index)
    this.currentIndex = index;
    const wrapper = this.el.nativeElement.querySelector('.carousel-wrapper1');
    const itemWidth = 200 + 10 * 2; // item width + margin
    const newPosition = this.currentIndex * -itemWidth;
    this.renderer.setStyle(wrapper, 'transform', `translateX(${newPosition}px)`);
  }

  onButtonClickBot(buttonName: string): void {
    this.activeButton = buttonName;

    if (buttonName == 'aboutUs') {
      this.show_menu_value = 'aboutUs';
      this.changeScreenValue.emit('aboutUs');
    }
    if (buttonName == 'Solution') {
      this.show_menu_value = 'Solution';
    }
    if (buttonName == 'Our Team') {
      this.show_menu_value = 'Our Team';
    }
    if (buttonName == 'Main Menu') {
      this.showContent = false;
      this.show_Content = true;
      this.show_menu_value = 'Main Menu';
    }
  }


  onButtonHoverBot(buttonName: string): void {
    this.hoveredButton = buttonName;
  }

  onButtonLeaveBot(buttonName: string): void {
    this.hoveredButton = ''; // Clear the hover state when leaving the button
  }


    
  fnSetStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`.activeButton {
      background: `+ this.DATA.PrimaryColor + ` !important;	
    }`);
  }

  fnSetDefaultStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;
    this.styleSheet.insertRule(`.activeButton {
      background: `+ '#003a5e' + ` !important;	
    }`);
  }
}

