import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AppServiceService {

  constructor(
    private http: HttpClient
  ) { }


  fnGetClientConfigurationById(clientConfigurationId: string) {
    console.log("request :2");
    let params = { ClientConfigurationId: clientConfigurationId };
    let request = this.http.post("https://1fizhm3xy8.execute-api.eu-west-2.amazonaws.com/production/getClientConfig", params).toPromise().then(function (response: any) {
      return response;
    }, function (error) {
      console.log("Error :" + error);
    });
    return request;
  }


  uploadPatientProfile(data:any): Observable<any> {
    return this.http.post("https://8x1pz40uv9.execute-api.us-east-1.amazonaws.com/dev/uploadfile/",data);
  }

  uploadImageForFaceMatch(data:any): Observable<any> {
    return this.http.post("https://e6oqoub23p3uln25gfrqouik4i0yxvln.lambda-url.us-east-1.on.aws/",data);
  }

  addUserFeedback(data: any): Observable<any> {
    return this.http.post("https://6qfwjb7she.execute-api.us-east-1.amazonaws.com/dev/addUserFeedback", data);
  }

}
