import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-doctors-widget',
  templateUrl: './doctors-widget.component.html',
  styleUrls: ['./doctors-widget.component.scss']
})
export class DoctorsWidgetComponent implements OnInit{
  @Input() doctors: { text: string, value: number }[] = [];
  @Output() handleSubmitResponse = new EventEmitter();
  @Input() canChangeStyle: any;
  @Input() clientConfigurationData: any;
  styleSheet: CSSStyleSheet | undefined;
  DATA: any;
  @Input() clickedValue: any;

  selectedDoctor: string = '';

  doctorLength = Math.ceil(this.doctors.length / 2);

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ' '], // Custom navigation text or icons
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
    nav: true
  }

  onDoctorClick(data:any){
    this.selectedDoctor = data.text;
    this.handleSubmitResponse.emit(data);
  }

  ngOnInit(): void {

    console.log("doctors",this.doctors);

    if(this.clickedValue){
      this.selectedDoctor = this.clickedValue;
    }

    if(this.canChangeStyle){
      this.DATA = this.clientConfigurationData;
      this.fnSetStyles();
    }else{
      this.fnSetDefaultStyles();
    }
  }

  
    
  fnSetStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`.doctortext {
      color: `+ this.DATA.primaryColor + ` !important;	
    }`);  
    this.styleSheet.insertRule(`.active-box {
      border: `+ '2px solid' + this.DATA.PrimaryColor + ` !important;	
    }`);
  }

  fnSetDefaultStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;
    this.styleSheet.insertRule(`.doctortext {
      color: `+ '#156087' + ` !important;	
    }`);

    this.styleSheet.insertRule(`.active-box {
      border: `+ '2px solid' + '#156087' + ` !important;	
    }`);
  }
}
