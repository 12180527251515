import { Component } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {

  activeButton: string = '';
  hoveredButton: string = '';
  show_menu_value: string = '';
  showContent: boolean = false;
  show_Content: boolean = true;

  onButtonClickBot(buttonName: string): void {
    this.activeButton = buttonName;

    if (buttonName == 'aboutUs') {
      this.show_menu_value = 'aboutUs';
    }
    if (buttonName == 'Solution') {
      this.show_menu_value = 'Solution';
    }
    if (buttonName == 'Our Team') {
      this.show_menu_value = 'Our Team';
    }
    if (buttonName == 'Main Menu') {
      this.showContent = false;
      this.show_Content = true;
      this.show_menu_value = 'Main Menu';
    }
  }

  onButtonHoverBot(buttonName: string): void {
    this.hoveredButton = buttonName;
  }

  onButtonLeaveBot(buttonName: string): void {
    this.hoveredButton = ''; // Clear the hover state when leaving the button
  }

}
