 
const BASE_URL = "https://mse6sdf79i.execute-api.us-east-1.amazonaws.com/dev/"
 
 
 
export const environment = {
    //Auth
    getSetMessages:"https://jv1ie3n7cg.execute-api.us-east-1.amazonaws.com/dev/feature-api-extended",
    featureAPI:"https://6s95xt7kqg.execute-api.us-east-1.amazonaws.com/prod/feature-api",
    getClientConfig:"https://1fizhm3xy8.execute-api.eu-west-2.amazonaws.com/production/getClientConfig",
  };
 