<div ngbDropdown class="dropdown" placement="top">
    <button
      class="btn  dropdown-toggle date-dropdown-button"
      ngbDropdownToggle
      id="dropdownMenuButton"
      aria-expanded="false"
    >
    <img src="assets/images/calander.png" alt="calendar" style="width: 20px; height: 20px;">
    Select a Date
    </button>
    <!-- <ul ngbDropdownMenu aria-labelledby="dropdownMenuButton" class="dropdown-menu">
        <li *ngFor="let date of dates" class="dropdown-item dropdown-list" (click)="onDateSelect(date.value)">
           
            
            {{date.text}}
        </li>
    </ul> -->
    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton" class="dropdown-menu">
      <div *ngFor="let date of dates" class="dropdown-item dropdown-list">
        
        <label class="label-color">{{ date.text }}</label>
        <input type="radio" [value]="date.value" (click)="onDateSelect(date.value)">
      </div>
    </div>
  </div>
 