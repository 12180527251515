import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
declare var MediaRecorder: any;
@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.scss']
})


export class UserInputComponent implements OnInit {

  @Output() changeScreenValue = new EventEmitter<string>();
  @Output() handleSubmit = new EventEmitter<any>();
  @Output() hadleFileUpload = new EventEmitter<any>();
  @Input() messages: any[] = [];
  @Input() canChangeStyle: any;
  @Output() generatePdf = new EventEmitter<any>();
  @Input() clientConfigurationData: any;
  @Input() isTyping: any;
  styleSheet: CSSStyleSheet | undefined;
  DATA: any;
  audioFlag:boolean = false
  isListening:boolean = false
  mediaRecorder:any;
  audioChunks:any = [];
  stream:any;
  isClicked:boolean = false;
  ngOnInit() {
    if(this.canChangeStyle){
      this.DATA = this.clientConfigurationData;
      this.fnSetStyles();
    }else{
      this.fnSetDefaultStyles();
    }
  }

  isMenuVisible:boolean = false;
  isEmojiPickerVisible: boolean= false;
  message:string = '';
  toggleMenuButton() {

    if(this.isMenuVisible){
      this.isMenuVisible = false;
      return;
    }
    
    setTimeout(() => {
      this.isMenuVisible = !this.isMenuVisible;
    }, 100);
    
  }

  handleClick(value:string){
    if(value === "menu"){
      const data = {
        value:"go back to main menu",
        text:"main menu"
      }
      this.isMenuVisible = !this.isMenuVisible;
      this.handleSubmit.emit(data);
    }
  }

  handleEventEnter(){

    if(this.isTyping){
      return;
    }

    if(this.message.trim() === "") return;

    console.log("this.message",this.message)
    this.handleSubmit.emit(this.message);
    this.message = ""
  }

  handleOpenFileUplaodModel(){
    this.isMenuVisible = !this.isMenuVisible;
    this.hadleFileUpload.emit();
  }


  public addEmoji(event:any) {
    this.message = `${this.message}${event.emoji.native}`;
  }


  handleAddPayment(){
    this.isMenuVisible = !this.isMenuVisible;
    this.changeScreenValue.emit("payment");
  }

  fnSetStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`
    .bottom-bot-div {
      border-top: 1px solid ${this.DATA.PrimaryColor} !important;
    }
  `);
  
  this.styleSheet.insertRule(`
    .custom-icon-color {
      color: ${this.DATA.PrimaryColor} !important;
    }
  `);

  }

  
  fnSetDefaultStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;
   

    this.styleSheet.insertRule(`
    .bottom-bot-div {
      border-top: 1px solid #44444462; !important;
    }
  `);

  this.styleSheet.insertRule(`
    .custom-icon-color {
      color: #386278; !important;
    }
  `);

  }


  handleClickOnHelp(){

    this.isMenuVisible = !this.isMenuVisible;

    // redirec to gravitas.ai
    window.open('https://gravitas.ai', '_blank');
  }



  generatePDF() {
    this.generatePdf.emit();
  }

  async fnRecordVoice() {
   let context = this;
    this.audioFlag = true;
    this.isListening = true;

    try{
     
        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
    }catch(e){
      this.isListening = false;
      this.audioFlag = false;
      console.error('Could not get audio stream');
      console.error(e)
      return;
    }

    // if use denied
    if (!this.stream) {
      this.isListening = false;
      this.audioFlag = false;
      console.error('Could not get audio stream');
      return;
    }

    this.mediaRecorder = new MediaRecorder(this.stream);
    this.mediaRecorder.ondataavailable = (event: { data: { size: number; } | null; }) => {
      if (event.data && event.data.size > 0) {
        this.audioChunks.push(event.data);
      }
    };
  this.mediaRecorder.onstop = () => {
    const audioBlob = new Blob( this.audioChunks, { type: 'audio/wav' });
    const reader:any = new FileReader();

    reader.readAsDataURL(audioBlob);
    reader.onloadend = function() {
      let audioBase64 = reader.result.split(',')[1];
      //console.log(audioBase64)
      const data = {
        audio:audioBase64,

      }
     
      context.handleSubmit.emit(data);

    };
  };

  this.mediaRecorder.start();
  setTimeout(() => {
    if (this.audioFlag) {
      this.audioFlag = false;
      this.audioChunks = [];
      this.isListening = false;
      this.stopRecording();
    }
  }, 3 * 1000);
  }

   stopRecording() {
    if (this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
      this.stream.getTracks().forEach((track: { stop: () => any; }) => track.stop());
    }
  }

  handleClickOutside() {
   
    if(this.isMenuVisible){
      this.isMenuVisible = false;
    }

  }

}
