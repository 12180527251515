  <div class="rating-container" >
      <div *ngIf="!showFeedBack">
        <div class="rating-title">Submit Feedback</div>
      <h2>How will you rate this conversation ?</h2>
      <div class="rating">
        <input type="radio" id="star1" name="rating" value="5" [(ngModel)]="selectedRating">
        <label for="star1" [style.background-image]="selectedRating >= 5 ? 'url(../../assets/images/star-checked-5.png)' : 'url(../../assets/images/star-unchecked.png)'"
          
          [style.background-image]="hoveredRating >= 5 ? 'url(../../assets/images/star-checked-5.png)' : 'url(../../assets/images/star-unchecked.png)'" (mouseover)="hoveredRating = 5" (mouseout)="hoveredRating = 0"

          (click)="submitRating(5)"
          
          ></label>
        <input type="radio" id="star2" name="rating" value="4" [(ngModel)]="selectedRating">
        <label for="star2" 
        [style.background-image]="selectedRating >= 4 ? 'url(../../assets/images/star-checked-4.png)' : 'url(../../assets/images/star-unchecked.png)'"

          [style.background-image]="hoveredRating >= 4 ? 'url(../../assets/images/star-checked-4.png)' : 'url(../../assets/images/star-unchecked.png)'" (mouseover)="hoveredRating = 4" (mouseout)="hoveredRating = 0"
          (click)="submitRating(4)"
          
          ></label>
        <input type="radio" id="star3" name="rating" value="3" [(ngModel)]="selectedRating">
        <label for="star3" 

        [style.background-image]="selectedRating >= 3 ? 'url(../../assets/images/star-checked-3.png)' : 'url(../../assets/images/star-unchecked.png)'"

          [style.background-image]="hoveredRating >= 3 ? 'url(../../assets/images/star-checked-3.png)' : 'url(../../assets/images/star-unchecked.png)'" (mouseover)="hoveredRating = 3" (mouseout)="hoveredRating = 0"

          (click)="submitRating(3)"

          ></label>
        <input type="radio" id="star4" name="rating" value="2" [(ngModel)]="selectedRating">
        <label for="star4"

        [style.background-image]="selectedRating >= 2 ? 'url(../../assets/images/star-checked-2.png)' : 'url(../../assets/images/star-unchecked.png)'"

          [style.background-image]="hoveredRating >= 2 ? 'url(../../assets/images/star-checked-2.png)' : 'url(../../assets/images/star-unchecked.png)'" (mouseover)="hoveredRating = 2" (mouseout)="hoveredRating = 0"
          (click)="submitRating(2)"
          
          ></label>
        <input type="radio" id="star5" name="rating" value="1" [(ngModel)]="selectedRating">
        <label for="star5"

        [style.background-image]="selectedRating >= 1 ? 'url(../../assets/images/star-checked-1.png)' : 'url(../../assets/images/star-unchecked.png)'"

          [style.background-image]="hoveredRating >= 1 ? 'url(../../assets/images/star-checked-1.png)' : 'url(../../assets/images/star-unchecked.png)'" (mouseover)="hoveredRating = 1" (mouseout)="hoveredRating = 0"

          (click)="submitRating(1)"

          
          ></label>
      </div>
      </div>

      <div *ngIf="showFeedBack" class="feedback">
        <div class="rating-title">Submit Feedback</div>

        <h2>"Your feedback matters.
            <br>
            Please review."</h2>
            <div class="input_box">
                <textarea type="text" placeholder="Type your Message here"
                (keyup.enter)="handleSubmitFeedBack()"
                [(ngModel)]="feedback"
                ></textarea>
            </div>
            

      </div>
    </div>