import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-date-widget',
  templateUrl: './date-widget.component.html',
  styleUrls: ['./date-widget.component.scss']
})
export class DateWidgetComponent implements OnInit{

  @Input() dates: { text: string, value: number }[] = [];
  @Output() handleSubmitResponse = new EventEmitter();
  @Input() canChangeStyle: any;
  @Input() clientConfigurationData: any;
  styleSheet: CSSStyleSheet | undefined;
  DATA: any;


  ngOnInit(): void {
    if(this.canChangeStyle){
      this.DATA = this.clientConfigurationData;
      this.fnSetStyles();
    }else{
      this.fnSetDefaultStyles();
    }
  }

  onDateSelect(data:any){
    this.handleSubmitResponse.emit(data);
  }

  fnSetStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`.date-dropdown-button {
      background: `+ this.DATA.PrimaryColor + ` !important;	
    }`); 
    
    this.styleSheet.insertRule(`.label-color {
      color: `+ this.DATA.PrimaryColor + ` !important;	
    }`); 
    
    this.styleSheet.insertRule(`.dropdown-list input[type=radio] {
      accent-color: `+ this.DATA.PrimaryColor + ` !important;	
    }`);  
  }

  fnSetDefaultStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;
    this.styleSheet.insertRule(`.date-dropdown-button {
      background: `+ '#156087' + ` !important;	
    }`);  

    this.styleSheet.insertRule(`.label-color {
      color: `+ '#156087' + ` !important;	
    }`);
    
    this.styleSheet.insertRule(`.dropdown-list input[type=radio] {
      accent-color: `+ '#156087' + ` !important;	
    }`);
  }
}
