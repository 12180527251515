import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppServiceService } from 'src/app/services/app-service.service';

@Component({
  selector: 'app-rating-feedback-widget',
  templateUrl: './rating-feedback-widget.component.html',
  styleUrls: ['./rating-feedback-widget.component.scss']
})
export class RatingFeedbackWidgetComponent {
  selectedRating:number = 0;
  hoveredRating:number = 0;

  showFeedBack:boolean = false;

  @Output() handleSubmitResponse = new EventEmitter<any>();
  @Input() clientConfigurationId: string = '';
  @Input() sessionAttributes: any = {};


  constructor(
    private appService: AppServiceService
  ) { }

  submitRating(value:number){
    console.log('Rating submitted123', value);
    this.selectedRating = value;
    this.hoveredRating = value;

    // this.handleSubmitResponse.emit(JSON.stringify(value));
    this.handleSubmitFeedBack();
  }

  handleSubmitFeedBack(){
    this.handleSubmitResponse.emit('go back to main menu');

    let data = {
      client_id: this.clientConfigurationId,
      name: this.sessionAttributes?.UserName || "",
      email: this.sessionAttributes?.EmailId || "",
      mobile: this.sessionAttributes?.PhoneNumber || "",
      source: 'tina',
      rating: this.selectedRating,
      feedback: '',
      timezone: "Asia/Calcutta",
    }
    // this.handleFeedBackSubmit.emit(true);
    this.appService.addUserFeedback(data).subscribe(
      {
        next: (response) => {
          if (response) {
            console.log('Response:', response);
          } else {
            console.log('No response due to error.');
          }
        },
        error: (error) => {

          console.error('Error in subscribe:', error);
        },
        complete: () => {
          console.log('Feedback submission completed.');
        }
      }
    )
  }
}
