import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit{

  @Input() botLanguages: any;
  @Input() translationActive: any;
  @Input() BotDefaultLanguage: any;
  @Output() ChangeBotLanguage = new EventEmitter<any>();
  @Input() canChangeStyle: any;
  @Input() clientConfigurationData: any;
  @Output() headerClose = new EventEmitter<any>();
  styleSheet: CSSStyleSheet | undefined;
  DATA: any;
 dropdownOpen = false;
  selectedLanguage: any;
  
  constructor()
  {
    console.log("botlang",this.botLanguages);
  }
  ngOnInit(): void {
    console.log("botlang",this.botLanguages);
    console.log(this.BotDefaultLanguage);

    if(this.BotDefaultLanguage == undefined){
      this.BotDefaultLanguage = "en";
      this.botLanguages = [
        {
          name: "English",
          value: "en"
        }
      ]
      this.selectedLanguage = this.botLanguages[0];
    }

    if(this.canChangeStyle){
      this.DATA = this.clientConfigurationData;
      this.fnSetStyles();
    }else{
      this.fnSetDefaultStyles();
    }

    this.selectedLanguage = this.botLanguages.find((lang:any) => lang.value === this.BotDefaultLanguage);
  }

  selectLanguage(language:any) {
    this.BotDefaultLanguage = language.value;
    this.selectedLanguage = language;
    this.dropdownOpen = false;
    this.handleChange();
  }

  handleChange() {
    console.log(this.BotDefaultLanguage);
    this.ChangeBotLanguage.emit(this.BotDefaultLanguage);
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  handleClickOutside() {
    if (this.dropdownOpen) {
      this.dropdownOpen = false;
    }
  }
  closeFrame() {
    this.headerClose.emit();
  }

  
  fnSetStyles() {
    console.log("fnSetStyles***********",this.DATA.HeaderBackgroundColor);
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`.header-container {
      background: `+ this.DATA.HeaderBackgroundColor + `!important;
      color: `+this.DATA.HeaderTextColor + ` !important;
    }`);

  }


  fnSetDefaultStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`.header-container {
      background: linear-gradient(180deg, #1a476c 0%, #043656 100%);
      color: #FFF!important;
    }`);

  }
}
