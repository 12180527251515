import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-intro-widget',
  templateUrl: './intro-widget.component.html',
  styleUrls: ['./intro-widget.component.scss']
})
export class IntroWidgetComponent implements OnInit{

  @Input() message :any;
  @ViewChild('videoElement') videoElement: any;
  @Input() canChangeStyle: any;
  @Input() clientConfigurationData: any;
  styleSheet: CSSStyleSheet | undefined;
  DATA: any;
  

  ngOnInit(): void {
    if(this.canChangeStyle){
      this.DATA = this.clientConfigurationData;
      this.fnSetStyles();
    }else{
      this.fnSetDefaultStyles();
    }
  }
  
  playVideo() {
    const thumbnail = document.getElementById('thumbnail') as HTMLImageElement;
    const playButton = document.getElementById('play-button') as HTMLButtonElement;
    const blackMask = document.getElementsByClassName('black-mask')[0] as HTMLElement;

    if (this.videoElement) {
      thumbnail.style.display = 'none';
      playButton.style.display = 'none';
      blackMask.style.display = 'none';
      this.videoElement.nativeElement.classList.add('show');
      this.videoElement.nativeElement.play();
    }
  }

  
  fnSetStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`.intro_msg {
      background: `+ this.DATA.BotDialogBackgroundColor + ` !important;	
      color: `+  this.DATA.BotDialogTextColor  + ` !important;
    }`);

  }

  
  fnSetDefaultStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`.intro_msg {
      background: `+ '#ccf3f5' + ` !important;	
      color: `+ '#525252' + ` !important;
    }`);

  }
}
