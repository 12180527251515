<div style="width: 82%; margin-bottom: 20px; margin-left: 10px; margin-top: 10px;" >
    <owl-carousel-o [options]="customOptions" >
  
       <ng-template carouselSlide *ngFor="let item of items" style="width: 130px; padding-bottom: 10px;"
      
       >
        <div class="picsum-img-wrapper" style="width: 120px;">
          <div class="chat-our-team-box"
          (click)="redirectTOProduct(item.product_link)"
          >
            <div class="team-boxes"
            [class.active-box]="selectedDoctor == item.text"
            
            >
  
           
             <div class="doctortext">
              {{item.product_name}}
             </div>
              <div class="nopersonimagecontianer">
              <!-- <i class="fa fa-user"></i> -->
               <img [src]="item.image_link"alt="" class="product_image" />
            </div>
             <p class="profession">
              {{item.price}}
             </p>
             <div class="buy_now_Btn"
             (click)="redirectTOProduct(item.product_link)"
             >
                Buy Now
             </div>
            </div>
  
          
          </div>
        </div>
      </ng-template>  
  
    </owl-carousel-o>
  </div>
  
  <!-- <div>
    <div class="team-box-top">
      <div class="team-box-top-heading">{{item.text}}</div>
      
    </div>
    <hr style="margin: 0; padding: 0" />
    <div class="team-box-middle">
      <div style="background: #00000014; border-radius: 5px">
        <img src="../assets/images/botv2/ankita_image.png" alt="" style="width: 64px; height: 50px" />
      </div>
      <div>
        <div>
          <img src="../assets/images/botv2/ri_linkedin-fill.png" alt="" style="width: 14px; height: 14px" />
        </div>
        <div>
          <img src="../assets/images/botv2/twitter.png" alt="" />
        </div>
      </div>
    </div>
    <div class="team-box-bottom">
      Ankita is a AI, big data and analytics specialist.
      Awarded exceptional talent visa in technical field by
      Govt. of UK
    </div>
  </div> -->