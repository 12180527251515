import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AppServiceService } from 'src/app/services/app-service.service';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FileuploadComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) private elFileInput!: ElementRef;
  @Output() onUploadSuccess: EventEmitter<any> = new EventEmitter();
  @Output() onFinished: EventEmitter<any> = new EventEmitter();
  @Output() handleAddImageToChat: EventEmitter<any> = new EventEmitter();
  @Input() theme!: string;
  @Input() isUploadUsingFeatureAPI!: boolean;
  cardImageBase64!: string;
  @Input() bodyText!: string;
  isImageSaved = false;



  imageError: string | undefined;
  textToDisplay: string = "Document size should be less than 10 mb and in .Png, .Jpg format";
  isLoading = false;
  file: File | undefined;

  constructor(private http: HttpClient, private imageCompress: NgxImageCompressService, private appService: AppServiceService) { }

  ngOnInit() {
    if (this.bodyText) {
      this.textToDisplay = this.bodyText;
    }
  }

  fileChangeEvent(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 10240; // 10MB
      const allowed_types = ['image/png', 'image/jpeg'];

      const selectedFile = fileInput.target.files[0];

      if (Math.round(selectedFile.size / 1024) > max_size) {
        this.imageError = 'Maximum size allowed is 10 MB';
        return;
      }

      if (!allowed_types.includes(selectedFile.type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = async (rs: any) => {

          const imgBase64Path = e.target.result;
          this.cardImageBase64 = imgBase64Path;
          this.isImageSaved = true

        }
      };

      reader.readAsDataURL(fileInput.target.files[0]);

      this.imageError = '';
      this.file = fileInput.target.files[0];


    }
  }

  uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  uploadImage(): void {
    if (!this.file) {
      this.isImageSaved = false
      return
    };


    // this.fnZipImage(uuid, base64Img);

    this.isLoading = true;
    const uuid = this.uuidv4();
    // Compress the image before upload

    const formData = new FormData();

    console.log(this.file);
    formData.append("file", this.file, uuid);
    let folder = "public/tina_chabot_files"
    formData.append("folders", folder);
    formData.append("bucket", "emruploads");

    this.appService.uploadPatientProfile(formData).subscribe({
      next: (res) => {
        console.log(res);
        if (res) {
          if (this.isUploadUsingFeatureAPI == true){
            let base64Img = this.cardImageBase64.replace('data:image/png;base64,', '');
            base64Img = base64Img.replace('data:image/jpeg;base64,', '');
            console.log(base64Img);
            this.onUploadSuccess.emit([base64Img, this.cardImageBase64]);
            this.onFinished.emit();
            this.handleAddImageToChat.emit(res.url);
          }
        }
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  removeImage(): void {
    this.elFileInput.nativeElement.value = '';
    this.file = undefined;
    this.cardImageBase64 = '';
    this.isImageSaved = false;
  }
}
