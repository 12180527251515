<div class="chat-buttons-action-container">
    <div class="chat-message-type2">About Us</div>
    <div class="chat-message-left-type2">
      <B>Gravitas AI</B> was founded with a vision to make business
      activities increasingly automated and interactive. This approach
      benefits both the internal teams in the organizations as well as
      the customers.
      <b
        >our clients rely on us to deliver unparalleled intelligence,
        security, and scalability to reduce costs, increase lifetime
        value</b
      >, and create meaningful connections with consumers.
    </div>
    <div class="chat-message-left-type2">
      <b>To find out more, click on the below buttons</b>
    </div>

    <div style="margin: 16px 0">
      <div>
        <!-- "About Us" button -->
        <button
          class="bot-v2-button"
          (click)="onButtonClickBot('Our Team')"
          (mouseenter)="onButtonHoverBot('Our Team')"
          (mouseleave)="onButtonLeaveBot('Our Team')"
          [class.clicked]="activeButton === 'Our Team'"
          [class.hovered]="hoveredButton === 'Our Team'"
        >
          Our Team
        </button>

        <!-- "Contact Us" button -->
        <button
          class="bot-v2-button"
          (click)="onButtonClickBot('Our History')"
          (mouseenter)="onButtonHoverBot('Our History')"
          (mouseleave)="onButtonLeaveBot('Our History')"
          [class.clicked]="activeButton === 'Our History'"
          [class.hovered]="hoveredButton === 'Our History'"
        >
          Our History
        </button>

        <!-- "Services" button -->
        <button
          class="bot-v2-button"
          (click)="onButtonClickBot('Contact Us')"
          (mouseenter)="onButtonHoverBot('Contact Us')"
          (mouseleave)="onButtonLeaveBot('Contact Us')"
          [class.clicked]="activeButton === 'Contact Us'"
          [class.hovered]="hoveredButton === 'Contact Us'"
        >
          Contact Us
        </button>

        <!-- "Frequently Asked Questions" button -->
        <button
          class="bot-v2-button"
          (click)="onButtonClickBot('Product')"
          (mouseenter)="onButtonHoverBot('Product')"
          (mouseleave)="onButtonLeaveBot('Product')"
          [class.clicked]="activeButton === 'Product'"
          [class.hovered]="hoveredButton === 'Product'"
        >
          Product
        </button>

        <!-- "Packages" button -->
        <button
          class="bot-v2-button"
          (click)="onButtonClickBot('Solution')"
          (mouseenter)="onButtonHoverBot('Solution')"
          (mouseleave)="onButtonLeaveBot('Solution')"
          [class.clicked]="activeButton === 'Solution'"
          [class.hovered]="hoveredButton === 'Solution'"
        >
          Solution
        </button>

        <!-- "Book a Demo" button -->
        <button
          class="bot-v2-button"
          (click)="onButtonClickBot('Schedule Demo')"
          (mouseenter)="onButtonHoverBot('Schedule Demo')"
          (mouseleave)="onButtonLeaveBot('Schedule Demo')"
          [class.clicked]="activeButton === 'Schedule Demo'"
          [class.hovered]="hoveredButton === 'Schedule Demo'"
        >
          Schedule Demo
        </button>

        <!-- "Our Procedures" button -->
      </div>
    </div>

    <!-- if user click on "Our Team" button -->
  </div>
  <!-- <app-our-team></app-our-team> -->