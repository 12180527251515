import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
declare var Razorpay: any;
@Component({
  selector: 'app-button-widget',
  templateUrl: './button-widget.component.html',
  styleUrls: ['./button-widget.component.scss']
})
export class ButtonWidgetComponent implements OnInit{

  @Input() buttons: any;
  @Output() handleSubmitResponse = new EventEmitter();
  activeButton: string = '';
  hoveredButton: string = '';
  @Input() canChangeStyle: any;
  @Input() clientConfigurationData: any;
  @Input() clickedValue: any;
  styleSheet: CSSStyleSheet | undefined;
  DATA: any;



  ngOnInit() {

    if(this.clickedValue){
      this.activeButton = this.clickedValue;
    }

    if(this.canChangeStyle){
      this.DATA = this.clientConfigurationData;
      this.fnSetStyles();
    }else{
      this.fnSetDefaultStyles();
    }

  }

  onButtonClickBot(button: {
    text: string;
    value: string;
  }): void {
    console.log(button);
    this.activeButton = button.text;
    this.handleSubmitResponse.emit(button);
  }

  onButtonHoverBot(buttonName: string): void {
    this.hoveredButton = buttonName;
  }

  onButtonLeaveBot(buttonName: string): void {
    this.hoveredButton = ''; // Clear the hover state when leaving the button
  }





  fnSetStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`
    .bot-v2-button {
      border: 1px solid ${this.DATA.PrimaryColor} !important;
      color: ${this.DATA.PrimaryColor};
      box-shadow: inset 0 1px 3px 1px ${this.DATA.gradientColor2};
      transition: background-color 0.3s ease;
      background: ${this.DATA.ButtonBackgroundColor};
    }
  `);

  this.styleSheet.insertRule(`
  .bot-v2-button:hover {
    border: 1px solid #fff !important;
    color: #fff !important; ;
    background:  ${this.DATA.PrimaryColor} !important;
  }
`);

this.styleSheet.insertRule(`
.clicked {
  border: 1px solid #fff !important;
  color: #fff !important;
  background:  ${this.DATA.PrimaryColor} !important;
}
`);
  }

  fnSetDefaultStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;
    // this.styleSheet.insertRule(`.bot-v2-button {
    
    //   border: `+ '1px solid' + '#08364E' ;	
    // }`);

    this.styleSheet.insertRule(`.bot-v2-button:hover {
      border: `+ '1px solid' + '#ffffff' + `!important;	
      background:`+'#08364E'+ `!important;;	
      color: '#ffffff' !important;
    }`);

    this.styleSheet.insertRule(`
.clicked {
  border: 1px solid #fff !important;
  color: #fff !important;
  background:  #124164 !important;
}
`);
  }

}
