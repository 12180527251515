<div class="button-wrapper">
    <div *ngFor="let button of buttons" >
        <!-- "About Us" button -->
        <button
      class="bot-v2-button"
      (click)="onButtonClickBot(button)"
      (mouseenter)="onButtonHoverBot(button.text)"
      (mouseleave)="onButtonLeaveBot(button.text)"
      [class.clicked]="activeButton === button.text"
      [class.hovered]="hoveredButton === button.text"
    >
      {{ button.text }}
    </button>

        <!-- "Our Procedures" button -->
      </div>
</div>
