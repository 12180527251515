import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';

declare var Razorpay: any;
@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(1000, style({ opacity: 0 }))
      ])
    ]),
  ]
})

export class ChatsComponent implements OnInit{
  @Input() messages :any;
  @ViewChild('chatBodyContainer') chatBodyContainer: any;
  @Output() handleSubmit = new EventEmitter<{name:string,value:string}>();
  @Input() canChangeStyle: any;
  @Input() clientConfigurationData: any;
  @Input() clientConfigurationId: any;
  @Input() sessionAttributes: any;
  styleSheet: CSSStyleSheet | undefined;
  DATA: any;

  razorpay: any;
  failedPaymentHistoryCheck: boolean = false;
  capturePaymentErrorParam: any;
  @Input() razorPayEvent: any;
  @Output() eventPaymentProcessed = new EventEmitter<any>();
  constructor(private http: HttpClient,private ngZone: NgZone) {
    
   }



  // @ViewChild('cardElement') cardElement!: ElementRef;

  stripe: any;
  cardElement: any;
  handler:any = null;

  async ngOnInit(): Promise<void> {

    if(this.canChangeStyle){
          this.DATA = this.clientConfigurationData;
          this.fnSetStyles();
        }else{
          this.fnSetDefaultStyles();
        }

        this.loadStripe();
  }

  pay(amount: any) {    
 
    var handler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_51HxRkiCumzEESdU2Z1FzfCVAJyiVHyHifo0GeCMAyzHPFme6v6ahYeYbQPpD9BvXbAacO2yFQ8ETlKjo4pkHSHSh00qKzqUVK9',
      locale: 'auto',
      token: function (token: any) {
        console.log(token)
        alert('Token Created!!');
      }
    });
 
    handler.open({
      name: 'Gravitas AI',
      description: 'Payment for Appointment',
      amount: amount * 100
    });
 
  }


  loadStripe() {
     
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_51HxRkiCumzEESdU2Z1FzfCVAJyiVHyHifo0GeCMAyzHPFme6v6ahYeYbQPpD9BvXbAacO2yFQ8ETlKjo4pkHSHSh00qKzqUVK9',
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            console.log(token)
            alert('Payment Success!!');
          }
        });
      }
       
      window.document.body.appendChild(s);
    }
  }


  onSubmit(event: any) {
    console.log(event);
    this.handleSubmit.emit(event);
  }

  fnSetStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    let dialogColors = this.DATA.UserDialogBackgroundColor.split(";");
    let dialogPrimaryColor = dialogColors[0];
    let dialogGradientColor1 = dialogColors[1];
    let dialogGradientColor2 = dialogColors[2];

    this.styleSheet.insertRule(`.user-message {
      background: `+ dialogPrimaryColor + ` !important;	
      color: `+ this.DATA.UserDialogTextColor + ` !important;
    }`);



    this.styleSheet.insertRule(`.bot-message {
      background: `+ this.DATA.BotDialogBackgroundColor + ` !important;	
      color: `+ this.DATA.BotDialogTextColor + ` !important;
    }`);

  }

  fnSetDefaultStyles() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;

    this.styleSheet.insertRule(`.user-message {
      background: `+ '#156087' + ` !important;	
      color: `+ '#FFFFFF' + ` !important;
    }`);

    this.styleSheet.insertRule(`.bot-message {
      background: `+ '#ffffff' + ` !important;	
      color: `+ '#156087' + ` !important;
    }`);

  }



  initiatePayment() {
    console.log("Initiating payment...");
    const options = {
      key: 'rzp_test_yXSy4v6XGiTsdg', // Replace with your actual Razorpay Key ID
      amount: 50000, // Amount is in the smallest currency unit. Hence, 500 INR = 50000 paise
      currency: 'INR',
      name: 'Gravitas AI',
      description: 'Payment for Appointment',
      image: '../../../assets/images/logo.png',
      prefill: {
        name: 'Gravitas AI',
        email: 'gravitas@ai',
        contact: '9999999999' // Replace with a valid contact number if needed
      },
      theme: {
        color: this.DATA?.primaryColor || "#156087" // Replace with your desired theme color
      },
      handler: (res: any) => {
        console.log('Payment successful:', res);
        this.eventPaymentProcessed.emit({ isSuccess: true, paymentId: res.razorpay_payment_id });
      },
      modal: {
        ondismiss: () => {
          console.log('Payment cancelled');
          this.eventPaymentProcessed.emit({ isSuccess: false });
        }
      }
    };

    const rzp = new Razorpay(options);

    rzp.on('payment.failed', (resp: any) => {
      this.ngZone.run(() => {
        console.error('Payment failed:', resp);
        this.eventPaymentProcessed.emit({ isSuccess: false, error: resp.error });
      });
    });

    rzp.open();
  }
}
