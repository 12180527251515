<div class="chat-container " #chatBodyContainer >
  <div *ngFor="let message of messages; let i = index" [@fadeInOut]  >

    <app-intro-widget [message]="message.text " *ngIf="message.widgetType == 'intro_text' && i == 0 "
    [canChangeStyle]="canChangeStyle"
    [clientConfigurationData]="clientConfigurationData"    
    ></app-intro-widget>
    <div  class="bot-message" *ngIf="message.widgetType == 'intro_text' && i == 2 ">
      <div>
        <div [innerHTML]="message.text"></div>
      </div>
      <div [class.timestampForUser]="message.isUser"
      [class.timestampForBot]="message.isUser == false"
      >
        {{message.timestamp | date:'H:mm'}}

      </div>
    
    </div>
    <app-video-carousel [videos]="message.videoAttributes"  *ngIf="message.widgetType == 'intro_videos' "
    [canChangeStyle]="canChangeStyle"
    [clientConfigurationData]="clientConfigurationData" 
    ></app-video-carousel>


    <div style="position: relative;">

      <div [class.user-message]="message.isUser" 
      [class.bot-message]="!message.isUser" *ngIf="message.widgetType == 'input_message' &&
      message.text.trim() != ''
      && message.text != null && message.text != undefined" >
      <div [class.isUserBox]="message.isUser">
        <div>
          <div [innerHTML]="message.text | safeHTML"></div>
        </div>
        <div [class.timestampForUser]="message.isUser"
        [class.timestampForBot]="message.isUser == false"
        >
          {{message.timestamp | date:'H:mm'}}

        </div>
      </div>
</div>
   
    <!-- <div  [class.user-message]="message.isUser" 
    [class.bot-message]="!message.isUser"
    *ngIf="message.widgetType == 'input_message'" [innerHtml]="message.text" >
    
  </div> -->

  </div>
  
    <app-button-widget (handleSubmitResponse)="onSubmit($event)" [buttons]="message.buttons" *ngIf="message.widgetType == 'button' "
    [canChangeStyle]="canChangeStyle"
    [clientConfigurationData]="clientConfigurationData"   
    [clickedValue]="message.clickedValue" 

    ></app-button-widget>

    <div *ngIf="message.widgetType == 'google_map'" style="margin-left: 20px;" [innerHTML]="message.text | safeHTML">
    </div>

    <div *ngIf="message.widgetType == 'Date'">
     <app-date-widget
     [dates]="message.buttons"
     (handleSubmitResponse)="onSubmit($event)"
     [canChangeStyle]="canChangeStyle"
     [clientConfigurationData]="clientConfigurationData" 
     ></app-date-widget>
    </div>

    <div *ngIf="message.widgetType == 'Time'">
      <app-time-widget
      [dates]="message.buttons"
      (handleSubmitResponse)="onSubmit($event)"
      ></app-time-widget>
     </div>

     <div *ngIf="message.widgetType == 'Doctor'"
     >
      <app-doctors-widget
      [doctors]="message.buttons"
      (handleSubmitResponse)="onSubmit($event)"
      [canChangeStyle]="canChangeStyle"
      [clientConfigurationData]="clientConfigurationData" 
      [clickedValue]="message.clickedValue" 
      ></app-doctors-widget>
     </div>

     <div *ngIf="message.widgetType == 'Item_Crousel'"
     >
      <app-item-crousel-widget
      [items]="message.products"
      (handleSubmitResponse)="onSubmit($event)"
      [canChangeStyle]="canChangeStyle"
      [clientConfigurationData]="clientConfigurationData" 
      [clickedValue]="message.clickedValue" 
      ></app-item-crousel-widget>
     </div>


     <div *ngIf="message.widgetType == 'payment'"
     style="display: flex; gap: 10px; margin-left: 6px; height: 40px;"
     >
     <button class="payment_button" (click)="initiatePayment()">
<img src="assets/images/razorpay.png" alt="visa" class="razorpay"/>
     </button>
     <button  class="payment_button" (click)="pay(500)">
      <img src="assets/images/stripe.png" alt="visa" class="stripe"/>
     </button>

     <div>
      
     </div>
     </div>


     <div
     *ngIf="message.widgetType == 'image_message'"
     class="image_paper"
     [class.user-message]="message.isUser" 
     >
     <img class="upload_image"
     
     [src]="message.text" height="165" width="245" />

     <div [class.timestampForUser]="message.isUser"
        [class.timestampForBot]="message.isUser == false"
        style="margin-bottom: -13px; margin-top: 0; margin-right: 0px;"
        > 
          {{message.timestamp | date:'H:mm'}}

        </div>
    </div>


     <div *ngIf="message.widgetType == 'input_message_feedback'">
      <app-rating-feedback-widget
      
      (handleSubmitResponse)="onSubmit($event)"
      [clientConfigurationId]="clientConfigurationId"
      [sessionAttributes]="sessionAttributes"
      
      ></app-rating-feedback-widget>
     </div>

  
  </div>

  
  
</div>


