import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-main-menu-screen',
  templateUrl: './main-menu-screen.component.html',
  styleUrls: ['./main-menu-screen.component.scss']
})
export class MainMenuScreenComponent {

  activeMenuButton: string = '';
  hoveredMenuButton: string = '';

  @Output() changeScreenValue = new EventEmitter<string>();
  

  onButtonClickMenuBot(buttonName: string): void {
    console.log('Button clicked: ' + buttonName);
    this.activeMenuButton = buttonName;
    console.log('Active button: ' + this.activeMenuButton);

    if(buttonName === 'calendar'){
      this.changeScreenValue.emit('intro');
    }
  }
  onButtonHoverMenuBot(buttonName: string): void {
    this.hoveredMenuButton = buttonName;
  }

  onButtonLeaveMenuBot(buttonName: string): void {
    this.hoveredMenuButton = ''; // Clear the hover state when leaving the button
  }
}
